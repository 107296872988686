import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getUsersList =
  (offset, limit = 10) =>
  async (dispatch) => {
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/admin/users?offset=${offset}&limit=${limit}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const searchUser = (offset, searchKeyword) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/users/by/search?offset=${offset}&search=${searchKeyword}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getUserDetail = (userId) => async (dispatch) => {
  try {
    const userData = localStorage.getItem("adminUserData")
      ? JSON.parse(localStorage.getItem("adminUserData"))
      : null;
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/users/${userData?.userId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const updateProfile = (data) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    let formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }

    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/admin/users/${data.id}/updateProfile`,
      data: formData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    return error;
  }
};

export const setUserInfo = (userInfo) => async (dispatch) => {
  dispatch({
    type: "SETUSERINFO",
    payload: userInfo,
  });
};

export const subscribeNewsLetter = (email) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/users/newsLetterSubscription`,
      data: { email },
    });
    dispatch({ type: "LOADING_COMPLETED" });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const changePassword =
  (email, oldPassword, newPassword, confirmNewPassword) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/admin/change-password`,
        data: { email, oldPassword, newPassword, confirmNewPassword },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch({ type: "LOADING_COMPLETED" });

      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };
