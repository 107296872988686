import React, { Component } from "react";

import Select from "react-select";
import { Modal } from "react-bootstrap";
import ReactCrop from "react-image-crop";
import TimePicker from "react-time-picker";
import { confirmAlert } from "react-confirm-alert";
import ImageUploading from "react-images-uploading";

import axios from "axios";
import moment from "moment";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import DollarIcon from "../../assets/images-2/dollar-icon.png";
import ProfileImage from "../../assets/images-2/dummyUser.png";

import Calendar from "./Calendar";
import AlertModal from "../../Components/AlertModal/AlertModal";
import withNavigate from "../../Components/customHooks/navigate";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";

import {
  getVendorProductDetail,
  updateVendorProductDetail,
  getAttributeList,
  updateProductFiles,
  removeProductFiles,
  getActiveSubscriptionCustomerListByProudctId,
  sendInvitationRequest,
  updateGroupInviteMeeting,
} from "../../Redux/Actions/vendorAction";

import {
  getProductListCountForApproval,
  denyProductApproval,
  getGroupAndOneOnOneMeetingDetails,
} from "../../Redux/Actions/productAction";

import { getMeetingsByProductIdAndSellerId } from "../../Redux/Actions/meetingActions";

import {
  addFiles,
  addFilesError,
  addMoreFiles,
  uploadFiles,
  updateAttibute,
  uploadAttibute,
} from "../../Redux/Actions/fileUploadAction";
import {
  getChildCategories,
  hierarchyList,
} from "../../Redux/Actions/searchCategoryAction";

import "react-image-crop/dist/ReactCrop.css";
import "react-confirm-alert/src/react-confirm-alert.css";
class Vendor_Product_Edit extends Component {
  parentCategoryOptions = [
    { label: "Access", value: 3 },
    { label: "Replenish", value: 101 },
    {
      label: "Customize",
      value: 119,
    },
  ];

  slug = "";
  vendorId = this.props.param.vendorId;

  productFields = {
    specificAttributeGlobalPrice: [],
    specificAttributeGlobalImage: [],
    isPrimary: false,
    primaryIndex: null,
    attibuteGlobalValue: "",
    attibuteGlobalIndex: null,
    isImageVisible: false,
    attributeGlobalImage: "",
    attributeGlobalPrice: null,
    productDetail: {},
    productId: null,
    productModelNo: "",
    productTitle: "",
    productPrice: "",
    dobaSellingPrice: "",
    productImage: "",
    galleryImages: [],
    base64GalleryImages: [],
    removedGalleryImages: [],
    attributeImage: [],
    productTimeFrame: "",
    productHeight: 0,
    productWidth: 0,
    productLength: 0,
    productWeight: 0,
    isActive: 1,
    isFeatured: 0,
    isApprove: "",
    productDescription: "",
    previewProductImage: "",
    parentCategory: 3,
    bottomErrorMessage: "",
    parentCategoryFlag: false,
    productParentCategoryList: [],
    productCategoryList: [],
    isVariantChange: "",
    isFirstVariantAdded: false,
    attributeChange: "",
    selectedProductCategoryId: [],
    selectedParentProductCategoryId: [],
    defaultSelectedOptions: [],
    defaultParentSelectedOption: [],
    courierList: ["Fedex", "USPS", "UPS", "Other"],
    selectedCourierList: [],
    defaultSelectedCourierOptions: [],
    isEdit: false,
    successMessage: "",
    errorMessage: "",
    errorData: {},
    showLoader: false,
    src: null,
    crop: {
      unit: "%",
      width: 64,
      height: 36,
      x: 25,
      y: 25,
      aspect: 16 / 9,
    },
    withAttribute: 0, //0 = false | 1 = true
    attributesList: [],
    attributesDefaultSelectOptions: [],
    attributesSelectOptions: [],
    selectedAttributeVariants: [],
    variantCombination: [],
    stripe_product_id: null,
    is_shipping_free: false,
    is_sale: 0,
    order_limit: "",
    minimumOrderQuantityLimit: 0,
    last_price_updated_time: null,
    diffMonths: false,
    sale_price: 0,
    o_o_o_meeting_price: 0,
    price_type: 1,
    is_registered_on_store: 0,
    type: null,
    galleryVideos: [],
    removedGalleryVideos: [],
    acsCatDescription: "",
    acsCatSubcategories: [
      {
        acsCatTitle: "",
        acsCatVideoGallery: [],
        acsCatVideoGalleryTitles: [],
        removedAcsCatVideoGallery: [],
        acsCatVideos: [],
        acsCatVideosTitles: [],
        acsCatAudioGallery: [],
        acsCatAudioGalleryTitles: [],
        removedAcsCatAudioGallery: [],
        acsCatAudios: [],
        acsCatAudiosTitles: [],
        acsCatImageGallery: [],
        acsCatImageGalleryTitles: [],
        removedAcsCatImageGallery: [],
        acsCatImages: [],
        acsCatImagesTitles: [],
        acsCatDocGallery: [],
        acsCatDocGalleryTitles: [],
        removedAcsCatDocGallery: [],
        acsCatDocuments: [],
        acsCatDocumentsTitles: [],
      },
    ],
    videos: [],
    refreshComponent: "",
    setCategoryAddedMessage: "",
    productSearchCategory: "",
    searchCategoryParents: [],
    searchCategoryId: 0,
    searchCategoryChildOptions: [],
    searchCategoryValue: "",
    isDobaProduct: false,
    showDenyLoader: false,
  };

  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
    this.state = {
      ...this.productFields,
      isAccessCategoryInCategories: false,
      currentFileFormat: [],
      currentFileType: "",
      currentFileContentType: "",
      currentUploadSubCategoryIndex: null,
      showVideos: true,
      downloadProcessMap: {},
      meetingDate: moment().format("YYYY-MM-DD"),
      meetingStartTime: "",
      meetingEndTime: "",
      activeSubscriptionList: this.props.activeSubscriptionMemberList,
      hasMore: true,
      offset: 0,
      prevOffset: 0,
      uploadAttributeIndex: -1,
      isDisabledAttributesForWoocommerceShopify: false,
      isCameFromApproveProductPage: this.props.location.state
        ?.isCameFromApproveProductPage
        ? this.props.location.state.isCameFromApproveProductPage
        : false,

      isSeeAllChecked: false,
      isDigitalChecked: false,
      isCropImage: false,
      showCropper: true,
      isOneOnOneMeetingPriceChecked: false,
      isGroupMeetingChecked: false,
      isCustomizeCategory: false,
      updateForApprove:
        this.props.location?.search.includes("approve") ||
        this.props.location?.pathname.includes("approve"),
      denyProductSlug: "",
      oneOnOneMeetingSlots: {},
      sessionHour: "00",
      sessionMinute: "00",
      trialCost: 0,
      subscriptionCost: 0,
      oneOnOneId: null,
      selectedFrequency: {
        All: false,
        "One Time": false,
        Weekly: false,
        "Bi weekly": false,
        Monthly: false,
      },
      customizedProductTime: 0,
      isWeekDaySelected: {
        Sun: false,
        Mon: false,
        Tue: false,
        Wed: false,
        Thu: false,
        Fri: false,
        Sat: false,
      },
      groupMeetingDetails: [
        {
          id: null,
          meetingName: "",
          subscriptionCost: 0,
          sessionHour: "00",
          sessionMinute: "00",
          meetingMonthlyStartTime: "",
          meetingMonthlyEndTime: "",
          meetingMonthlyWeekDay: "",
          meetingMonthlyWeekOfMonth: "",
          groupMeetingFrequency: "",
          isMonthlyGroupFrequencyChecked: false,
          isWeekDaySelected: {
            Sun: false,
            Mon: false,
            Tue: false,
            Wed: false,
            Thu: false,
            Fri: false,
            Sat: false,
          },
          groupMeetingSlots: {},
        },
      ],
      isOneOnOneMeetingChecked: false,
      isOneOnOneMeetingDetailsEdited: false,
      isGroupMeetingDetailsEdited: false,
      deletedGroupMeetingsIdArray: [],
      customizeQuestions: [],
    };

    this.selectOneOnOneFrequency = [
      "All",
      "One Time",
      "Weekly",
      "Bi weekly",
      "Monthly",
    ];

    this.selectGroupFrequency = ["Weekly", "Bi weekly", "Monthly"];

    this.weekOfMonth = ["First", "Second", "Third"];
    this.weekDays = {
      Sun: "Sunday",
      Mon: "Monday",
      Tue: "Tuesday",
      Wed: "Wednesday",
      Thu: "Thursday",
      Fri: "Friday",
      Sat: "Saturday",
    };
    this.sessionHours = [
      "00",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];
    this.sessionMinutes = ["00", "15", "30", "45"];

    this.fileUpload = React.createRef();
    this.fileUploadAttribute = React.createRef();

    this.slug = this.props.param.slug;
    this.vendorId = this.props.param.vendorId;

    this.fileUploadVideo = React.createRef();
    this.fileUploadAudio = React.createRef();
    this.fileUploadImage = React.createRef();
    this.fileUploadDoc = React.createRef();
    this.uploadGlobalImage = React.createRef();
  }

  fileFormats = {
    video: ["mp4", "webm", "ogg", "ogv", "mpeg"],
    audio: ["wav", "ogg", "mp3"],
    image: ["jpeg", "png", "jpg", "gif"],
    doc: [
      "pdf",
      "txt",
      "plain",
      "xls",
      "vnd.ms-excel",
      "xlsx",
      "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "doc",
      "msword",
      "docx",
      "vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
  };

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({
          src: reader.result,
          croppedImageUrl: reader.result,
          previewProductImage: reader.result,
          productImage: reader.result,
          isCropImage: false,
          showCropper: true,
        })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (e) => {
    this.imageRef = e.target;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageURL = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({
        croppedImageUrl: croppedImageURL,
        productImage: croppedImageURL,
      });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      var dataURL = canvas.toDataURL();
      resolve(dataURL);
    });
  }

  async componentDidMount() {
    await this.fetchProductDetail();
    if (this.state.defaultParentSelectedOption.length > 0) {
      this.fetchSearchCategoryList();
    }
    await this.fetchSearchHierarchyList();
    this.setState({
      isOneOnOneMeetingPriceChecked: !!this.state.o_o_o_meeting_price,
    });
    await this.getSearchCategories();
  }

  getSearchCategories = async () => {
    if (this.state.searchCategoryId.length > 0) {
      let searchCategoryChildOptions = [
        ...this.state.searchCategoryChildOptions,
      ];
      this.state.searchCategoryParents.map(async (item, index) => {
        const responseCats = await this.props.getChildCategories(
          item[item.length - 1].id,
          this.state.isSeeAllChecked,
          this.state.selectedParentProductCategoryId
        );
        if (responseCats && !responseCats.isError) {
          searchCategoryChildOptions[index] = responseCats.data;
        }
        await this.setState({
          searchCategoryChildOptions,
        });
      });
    }
  };

  organizeAccessSubcategoryData = (productDetail) => {
    if (productDetail.access_cat_titles === null) {
      return [];
    }

    const titles = productDetail.access_cat_titles
      ? JSON.parse(productDetail.access_cat_titles)
      : "";
    const videos = JSON.parse(productDetail.access_cat_videos);
    const audios = JSON.parse(productDetail.access_cat_audios);
    const images = JSON.parse(productDetail.access_cat_images);
    const documents = JSON.parse(productDetail.access_cat_documents);
    const length = videos.length;
    let organizedData = [];
    for (let i = 0; i < length; i++) {
      const acsCatVideoGallery =
        videos && videos.length > 0 && videos[i][i].length > 0
          ? videos[i][i].split(",")
          : [];
      const acsCatAudioGallery =
        audios && audios.length > 0 && audios[i][i].length > 0
          ? audios[i][i].split(",")
          : [];
      const acsCatImageGallery =
        images && images.length > 0 && images[i][i].length > 0
          ? images[i][i].split(",")
          : [];
      const acsCatDocGallery =
        documents && documents.length > 0 && documents[i][i].length > 0
          ? documents[i][i].split(",")
          : [];

      organizedData.push({
        acsCatTitle:
          titles && titles?.length > 0 && titles[i][i]?.length > 0
            ? titles[i][i]
            : "",
        acsCatVideoGallery,
        acsCatAudioGallery,
        acsCatImageGallery,
        acsCatDocGallery,
        acsCatVideos: [],
        acsCatAudios: [],
        acsCatImages: [],
        acsCatDocuments: [],
        acsCatVideosTitles: [],
        acsCatAudiosTitles: [],
        acsCatImagesTitles: [],
        acsCatDocumentsTitles: [],
        removedAcsCatVideoGallery: [],
        removedAcsCatAudioGallery: [],
        removedAcsCatImageGallery: [],
        removedAcsCatDocGallery: [],
        acsCatVideoGalleryTitles: acsCatVideoGallery.map(
          (v) => this.getFileNameFromUrl(v).split(".")[0]
        ),
        acsCatAudioGalleryTitles: acsCatAudioGallery.map(
          (v) => this.getFileNameFromUrl(v).split(".")[0]
        ),
        acsCatImageGalleryTitles: acsCatImageGallery.map(
          (v) => this.getFileNameFromUrl(v).split(".")[0]
        ),
        acsCatDocGalleryTitles: acsCatDocGallery.map(
          (v) => this.getFileNameFromUrl(v).split(".")[0]
        ),
      });
    }

    return organizedData;
  };

  fetchProductDetail = async () => {
    let productDetail = await this.props.getVendorProductDetail(
      this.vendorId,
      this.slug
    );
    if (
      productDetail?.data?.data[0].type === "woocommerce" ||
      productDetail?.data?.data[0].type === "shopify"
    ) {
      this.setState({
        isDisabledAttributesForWoocommerceShopify: true,
      });
    } else {
      this.setState({
        isDisabledAttributesForWoocommerceShopify: false,
      });
    }

    if (productDetail?.data?.data?.length > 0) {
      // product found
      productDetail = productDetail.data.data[0];

      let selectedCategories = [];
      let selectedParentCategories = [];
      if (
        productDetail.selectedCategories &&
        productDetail.selectedCategories.length > 0
      ) {
        productDetail.selectedCategories.forEach((category) => {
          if (category.parent_id === 0) {
            selectedParentCategories.push(category.productCategoryId);
          } else {
            selectedCategories.push(category.productCategoryId);
          }
        });
      }

      const res = this.createProductCategorySelectOptions(
        productDetail.categoriesList,
        selectedCategories,
        selectedParentCategories
      );

      let selectedShopifyCategory = [];
      if (
        productDetail.shopify_category &&
        productDetail.shopify_category !== "undefined"
      ) {
        selectedShopifyCategory = JSON.parse(productDetail.shopify_category);
      }
      let shopifyRes = null;
      if (productDetail.is_registered_on_store) {
        shopifyRes = this.createShopifyCategorySelectOptions(
          productDetail.shopifyCategoryList,
          selectedShopifyCategory
        );
      }

      let selectedCouriers = [];

      if (productDetail.couriers_option) {
        selectedCouriers = Array.isArray(productDetail.couriers_option)
          ? productDetail.couriers_option
          : typeof productDetail.couriers_option === "string"
          ? (() => {
              try {
                const parsedOption = JSON.parse(productDetail.couriers_option);
                return Array.isArray(parsedOption)
                  ? parsedOption
                  : productDetail.couriers_option;
              } catch {
                return productDetail.couriers_option;
              }
            })()
          : productDetail.couriers_option;
      }

      const courierRes = this.createAvailableCourierSelectOptions(
        productDetail.spu_id
          ? productDetail.allCourierOptions
          : ["Fedex", "USPS", "UPS"],
        selectedCouriers
      );

      const attributesSelectOptions = this.createAttributeSelectOptions(
        productDetail.attributes,
        productDetail.selectedAttributes
      );

      const defaultCombination = this.createDefaultVariantCombination(
        productDetail.productAttributeVariantDetail
      );

      const defaultGalleryImages = this.createDefaultGalleryImagePattern(
        productDetail.galleryImages
          ? JSON.parse(productDetail.galleryImages)
          : []
      );

      let accessCategoryId = res.selectParentOptions?.find(
        (i) => i.label.toLocaleLowerCase() === "access"
      )?.value;
      let customizeCategory = res?.defaultParentSelectedOptionsList?.find(
        (i) => i.label.toLocaleLowerCase() === "customize"
      )?.label;
      let isCategoryIncludesAccessCategory = selectedParentCategories.includes(
        +accessCategoryId
      );

      const accessSubcategoryList = await this.organizeAccessSubcategoryData(
        productDetail
      );
      const searchCategoryId = productDetail.searchCategoryId;
      let isDobaProduct =
        productDetail.spu_id && productDetail.spu_id?.length !== 0;

      const seminarDetails =
        productDetail.free_seminar_details &&
        productDetail.free_seminar_details?.length
          ? JSON.parse(productDetail.free_seminar_details)
          : null;
      await this.getGroupAndOneOnOneMeetingDetails(productDetail.id);
      this.setState(
        {
          productDetail: productDetail,
          productId: productDetail.id,
          // productModelNo: "",
          productTitle: productDetail.name,
          productPrice: productDetail?.price?.toString(),
          dobaSellingPrice: productDetail.doba_selling_price,
          productImage: productDetail.imageName,
          productTimeFrame: productDetail.timeFrame
            ? productDetail.timeFrame.split(",")[1]
            : null,
          productHeight: productDetail.height,
          productWidth: productDetail.width,
          productLength: productDetail.length,
          productWeight: productDetail.weight,
          isActive: productDetail.isActive,
          isFeatured: productDetail.is_featured,
          isApprove: productDetail.status,
          primaryIndex: productDetail.attributePrimaryIndex,
          price_type: productDetail.price_type,
          type: productDetail.type,
          sale_price:
            productDetail.price_type === 2 && productDetail.sale_price
              ? Math.round(
                  100 * (1 - productDetail.sale_price / productDetail.price)
                )
              : productDetail.sale_price,
          o_o_o_meeting_price: productDetail.one_on_one_meeting_price,
          isDigitalChecked: !!productDetail.is_digital,
          customizedProductTime: productDetail.customize_delivery_time
            ? productDetail.customize_delivery_time
            : 0,
          productDescription: productDetail.description,
          previewProductImage: productDetail.imageName,
          galleryImages: defaultGalleryImages,
          selectedProductCategoryId: selectedCategories,
          selectedParentProductCategoryId: selectedParentCategories,
          productCategoryList: res.selectOptions ? res.selectOptions : [], // child category
          productParentCategoryList: res.selectParentOptions
            ? isDobaProduct
              ? res.selectParentOptions.filter(
                  (item) => item.label !== "Access"
                )
              : res.selectParentOptions
            : [], // Parent category
          defaultSelectedOptions: res.defaultSelectedOptions
            ? res.defaultSelectedOptions
            : [], // child category
          defaultParentSelectedOption: res.defaultParentSelectedOptionsList
            ? res.defaultParentSelectedOptionsList
            : [], // Parent category
          shopifyCategoryList: shopifyRes
            ? shopifyRes.selectOptions
              ? shopifyRes.selectOptions
              : []
            : null,
          courierList: courierRes.selectOptions ? courierRes.selectOptions : [],
          selectedCourierList: selectedCouriers,
          defaultSelectedCourierOptions: courierRes.defaultSelectedOptions
            ? courierRes.defaultSelectedOptions
            : [],
          isEdit: true,
          withAttribute:
            attributesSelectOptions.defaultSelectedOptions.length > 0 ? 1 : 0,
          attributesList: productDetail.attributes
            ? productDetail.attributes
            : [],
          attributesDefaultSelectOptions:
            attributesSelectOptions.defaultSelectedOptions,
          attributesSelectOptions: attributesSelectOptions.selectOptions,
          variantCombination: defaultCombination,
          stripe_product_id: productDetail.stripe_product_id,
          is_shipping_free: productDetail.is_shipping_free,
          is_sale: productDetail.is_sale,
          order_limit: productDetail.order_limit,
          minimumOrderQuantityLimit: productDetail.totalsubscription,
          last_price_updated_time: productDetail.last_price_updated_time,
          isAccessCategoryInCategories: isCategoryIncludesAccessCategory,
          seller_store_product_id: productDetail.seller_store_product_id,
          is_registered_on_store: productDetail.is_registered_on_store,
          galleryVideos: productDetail.gallery_videos
            ? productDetail.gallery_videos.split(",")
            : [],
          acsCatSubcategories: accessSubcategoryList,
          acsCatDescription: productDetail.access_cat_description,
          videos: [],
          searchCategoryId,
          isCustomizeCategory:
            customizeCategory?.toLocaleLowerCase() === "customize",
          isDobaProduct,
          dobaMainImage: productDetail.doba_main_image,
          formData: {
            start: seminarDetails && seminarDetails.start,
            end: seminarDetails && seminarDetails.end,
            date: seminarDetails && seminarDetails.date,
            link: seminarDetails && seminarDetails.host_link,
            name: seminarDetails && seminarDetails.name,
            note: seminarDetails && seminarDetails.note,
            price: seminarDetails && seminarDetails.price,
          },
          isFreeSeminarCreated: seminarDetails ? true : false,
          customizeQuestions:
            productDetail.customize_questions &&
            productDetail.customize_questions !== "undefined"
              ? JSON.parse(productDetail.customize_questions)
              : [],
        },
        () => {
          this.getSelectedAttributeVariants(
            productDetail.productAttributeVariantDetail
          );
          this.fetchSearchHierarchyList();
        }
      );

      if (this.state.last_price_updated_time) {
        this.dateDiff(this.state.last_price_updated_time);
      }
    } else {
      this.props.navigate(`/vendors/product/${this.vendorId}`);
    }
  };

  createProductCategorySelectOptions = (
    categoryList,
    selectedProductCategory = [],
    selectedParentCategories = []
  ) => {
    let defaultSelectedOptions = [];
    let defaultParentSelectedOptionsList = [];
    let filterParentCategoryList,
      filterCategoryList,
      filterByParentcategoryId = [];

    filterParentCategoryList = categoryList.filter((key) => key.parentId === 0);

    filterCategoryList = categoryList.filter((key) => key.parentId !== 0);
    selectedParentCategories.forEach((element) => {
      if (filterByParentcategoryId.length > 0) {
        let tempArr = filterByParentcategoryId;
        filterByParentcategoryId = filterCategoryList.filter(
          (ele) => ele.parentId === element
        );
        filterByParentcategoryId = filterByParentcategoryId.concat(tempArr);
      } else {
        filterByParentcategoryId = filterCategoryList.filter(
          (ele) => ele.parentId === element
        );
      }
    });

    let selectOptions = filterByParentcategoryId.map((item) => {
      if (selectedProductCategory.includes(item.id)) {
        defaultSelectedOptions.push({
          value: `${item.id}`,
          label: `${item.name}`,
          parentId: `${item.parentId}`,
        });
      }
      return {
        value: `${item.id}`,
        label: `${item.name}`,
        parentId: `${item.parentId}`,
      };
    });
    let selectParentOptions = filterParentCategoryList.map((item) => {
      if (selectedParentCategories.includes(item.id)) {
        defaultParentSelectedOptionsList.push({
          value: `${item.id}`,
          label: `${item.name}`,
          parentId: `${item.parentId}`,
        });
      }
      return {
        value: `${item.id}`,
        label: `${item.name}`,
        parentId: `${item.parentId}`,
      };
    });

    return {
      defaultSelectedOptions,
      selectOptions,
      selectParentOptions,
      defaultParentSelectedOptionsList,
    };
  };

  createShopifyCategorySelectOptions = (
    categoryList,
    selectedProductCategory = []
  ) => {
    let defaultSelectedOptions = [];

    let selectOptions = categoryList.map((item) => {
      if (selectedProductCategory.includes(item.id)) {
        defaultSelectedOptions.push({
          value: `${item.id}`,
          label: `${item.name}`,
        });
      }
      return { value: `${item.id}`, label: `${item.name}` };
    });

    return { defaultSelectedOptions, selectOptions };
  };

  createAvailableCourierSelectOptions = (
    courierList,
    selectedCourierList = []
  ) => {
    let defaultSelectedOptions = [];
    let selectOptions = courierList.map((item) => {
      if (selectedCourierList.includes(item)) {
        defaultSelectedOptions.push({ value: `${item}`, label: `${item}` });
      }
      return { value: `${item}`, label: `${item}` };
    });

    return { defaultSelectedOptions, selectOptions };
  };

  createDefaultGalleryImagePattern = (images) => {
    let defaultImages = images.map((imgPath) => {
      return {
        data_url: imgPath,
      };
    });

    return defaultImages;
  };

  createAttributeSelectOptions = (attributesList, selectedAttributes = []) => {
    let defaultSelectedOptions = [];

    let selectedAttributesNameArray = [];

    if (selectedAttributes.length > 0) {
      selectedAttributes.forEach((attribute) => {
        selectedAttributesNameArray.push(attribute.name.toLowerCase());
      });
    }

    let selectOptions = attributesList.map((attribute) => {
      let attributeName = attribute.name;
      if (selectedAttributesNameArray.includes(attributeName.toLowerCase())) {
        defaultSelectedOptions[
          selectedAttributesNameArray.indexOf(attributeName.toLowerCase())
        ] = {
          value: attributeName,
          label: attributeName.replace("_", " "),
        };
      }

      return { value: attributeName, label: attributeName.replace("_", " ") };
    });

    return { selectOptions, defaultSelectedOptions };
  };

  createDefaultVariantCombination = (productAttributeVariantDetail = []) => {
    let defaultCombination = [];
    let tempAttributeImage = [];

    productAttributeVariantDetail.forEach((pair) => {
      defaultCombination.push([
        JSON.parse(pair.variant_value),
        {
          price: pair.price,
          one_on_one_meeting_price: pair.one_on_one_meeting_price,
          image: pair.image ? pair.image : "",
        },
      ]);
      tempAttributeImage.push(pair.image ? pair.image : "");
      this.setState({
        attributeImage: tempAttributeImage,
      });
    });

    return defaultCombination;
  };

  handleRequestMeeting = () => {
    this.setState({
      showHideDenyModal: true,
      adminMessage: "",
    });
  };

  handleDenyProductModalShowHide = () => {
    this.setState({
      showHideDenyModal: false,
      adminMessage: "",
    });
  };

  handleAlertForDenyApproval = (slug) => {
    this.setState({
      showHideDenyModal: true,
      denyProductSlug: slug,
    });
  };

  denyModal = () => (
    <Modal
      show={this.state.showHideDenyModal}
      onHide={this.handleDenyProductModalShowHide}
      className="calendar-modal"
      centered
    >
      <Modal.Header className="calendar-header">
        <span onClick={this.handleDenyProductModalShowHide}>
          <i className="fas fa-times"></i>
        </span>
        <h5 className="text-center w-100 m-0">Deny product approval</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <p className="text-center">Are you sure to deny this product ?</p>
              <label>
                <b>Message</b>
              </label>
              <textarea
                className="form-control"
                placeholder="Enter text message for vendor"
                onChange={(e) =>
                  this.setState({ adminMessage: e.target.value })
                }
              />
            </div>
          </div>
        </div>
        <div className="button-center">
          <button
            onClick={() => this.handleDenyApproval(this.state.denyProductSlug)}
            className="orange-btn"
          >
            yes
            {(() => {
              if (this.state?.showDenyLoader) {
                return (
                  <span class="spinner-border spinner-border-sm ml-1"></span>
                );
              }
            })()}
          </button>
          <button
            onClick={() => this.handleDenyProductModalShowHide()}
            className="orange-btn"
          >
            no
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );

  handleAlertResponse = (type, id, data) => {
    if (id === 1) {
      if (type === "positive") {
        this.handleChange(data, "isActive");
      }
    } else if (id === 2) {
      if (type === "positive") {
        this.setState((prevState) => ({
          ...prevState,
          productTimeFrame: "monthly",
        }));
        this.handleParentProductCategoryChange(data);
      }
    }

    this.closeAlertModal();
  };
  handleChange = (e, key, index, titleIndex) => {
    if (
      this.state.errorData &&
      this.state.errorData[key] &&
      this.state.errorData[key][0]
    ) {
      this.state.errorData[key][0] = null;
    }

    let { value } = e.target;

    if (key === "acsCatTitle") {
      let subCatList = [...this.state.acsCatSubcategories];
      subCatList[index][key] = value;
      this.setState({
        acsCatSubcategories: [...subCatList],
      });
    } else if (key.includes("Titles")) {
      let subCatList = [...this.state.acsCatSubcategories];
      subCatList[index][key][titleIndex] = value;
      this.setState({
        acsCatSubcategories: [...subCatList],
      });
    }
    if (key === "sale_price") {
      this.setState((prevState) => ({
        ...prevState,
        [key]: parseInt(value),
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
    if (key === "o_o_o_meeting_price") {
      this.setState((prevState) => ({
        ...prevState,
        [key]: parseInt(value),
      }));
    }
    if (key === "customizeQuestions") {
      let tempCustomizeQuesArray = [...this.state.customizeQuestions];
      tempCustomizeQuesArray[index] = value;
      this.setState({
        [key]: [...tempCustomizeQuesArray],
      });
    }
  };

  confirmProductInactiveAlert(event, key) {
    event.persist();
    confirmAlert({
      title: "Confirm change?",
      message:
        "Inactive this product will cancel all the customer's subscription associated with this product.",
      buttons: [
        {
          label: "Continue",
          onClick: () => {
            this.handleChange(event, key);
          },
        },
        {
          label: "Cancel",
          onClick: () => {},
        },
      ],
    });
  }

  confirmProductIsFeatureAlert(event, key) {
    event.persist();
    confirmAlert({
      title: "Confirm change?",
      message: "Are you sure to set this product on feature list?",
      buttons: [
        {
          label: "Continue",
          onClick: () => {
            this.handleChange(event, key);
          },
        },
        {
          label: "Cancel",
          onClick: () => {},
        },
      ],
    });
  }

  handleGalleryImages = async (images) => {
    if (images?.length > 0) {
      images = images.map((img) => {
        if (img.file) {
          img["fileName"] = img.file.name;
          return img;
        }
        return img;
      });
    }
    this.setState({
      galleryImages: images,
    });
  };

  handleParentProductCategoryChange = async (event) => {
    this.setState({
      customizedProductTime: 0,
    });
    if (
      this.state.errorData &&
      this.state.errorData["selectedParentProductCategoryId"] &&
      this.state.errorData["selectedParentProductCategoryId"][0]
    ) {
      this.state.errorData["selectedParentProductCategoryId"][0] = null;
    }

    let selectedParentCategories = [];
    let isAccessCategory = false;
    if (event) {
      isAccessCategory = event.label.toLocaleLowerCase() === "access";
      this.setState({
        isCustomizeCategory: event.label.toLocaleLowerCase() === "customize",
      });
      if (event.label.toLocaleLowerCase() !== "customize") {
        this.setState({
          isDigitalChecked: false,
        });
      }
      selectedParentCategories.push(+event.value);
    }
    let defaultParentSelectedOption = [event];
    const res = await this.createProductCategorySelectOptions(
      this.state.productDetail.categoriesList
        ? this.state.productDetail.categoriesList
        : this.state.firstLoadCategoryList,
      selectedParentCategories
    );

    this.setState({
      productCategoryList: res.selectOptions ? res.selectOptions : [], // child category
      productParentCategoryList: res.selectParentOptions
        ? res.selectParentOptions
        : [], // Parent category
      defaultSelectedOptions: res.defaultSelectedOptions
        ? res.defaultSelectedOptions
        : [], // child category
      defaultParentSelectedOption: res.defaultParentSelectedOptionsList
        ? res.defaultParentSelectedOptionsList
        : [], // Parent category
    });

    if (isAccessCategory || !event) {
      const productDeatils = this.state;

      productDeatils["productHeight"] = 0;
      productDeatils["productWidth"] = 0;
      productDeatils["productLength"] = 0;
      productDeatils["productWeight"] = 0;
      productDeatils["selectedCourierList"] = [];

      this.setState({
        ...productDeatils,
        selectedParentProductCategoryId: selectedParentCategories,
        defaultParentSelectedOption,
        isAccessCategoryInCategories: true,
        is_shipping_free: true,
      });
    } else {
      this.setState({
        selectedParentProductCategoryId: selectedParentCategories,
        defaultParentSelectedOption,
        isAccessCategoryInCategories: false,
      });
    }
    this.fetchSearchCategoryList();
  };

  handleProductCategoryChange = (event) => {
    if (
      this.state.errorData &&
      this.state.errorData["selectedProductCategoryId"] &&
      this.state.errorData["selectedProductCategoryId"][0]
    ) {
      this.state.errorData["selectedProductCategoryId"][0] = null;
    }

    let selectedCategories = [];
    let isAccessCategory = false;
    if (event && event.length > 0) {
      isAccessCategory = event.some(
        (cate) => cate.label.toLocaleLowerCase() === "access"
      );
      event.forEach((category) => {
        selectedCategories.push(category.value);
      });
    }
    let defaultSelectedOptions = event;
    if (isAccessCategory || !event) {
      const productDeatils = this.state;
      productDeatils["productHeight"] = 0;
      productDeatils["productWidth"] = 0;
      productDeatils["productLength"] = 0;
      productDeatils["productWeight"] = 0;
      productDeatils["selectedCourierList"] = [];

      this.setState({
        ...productDeatils,
        selectedProductCategoryId: selectedCategories,
        defaultSelectedOptions,
        is_shipping_free: true,
      });
    } else {
      this.setState(
        {
          selectedProductCategoryId: selectedCategories,
          defaultSelectedOptions,
        },
        () => {}
      );
    }
  };

  handleCourierChange = (event) => {
    if (
      this.state.errorData &&
      this.state.errorData["selectedCourierList"] &&
      this.state.errorData["selectedCourierList"][0]
    ) {
      this.state.errorData["selectedCourierList"][0] = null;
    }

    let selectedCourier = [];

    if (event && Object.keys(event).length > 0) {
      selectedCourier.push(event.value);
    }

    let defaultSelectedCourierOptions = event;
    this.setState({
      selectedCourierList: selectedCourier,
      defaultSelectedCourierOptions,
    });
  };

  handleDenyApproval = async (slug) => {
    this.setState({
      showDenyLoader: true,
    });
    const res = await this.props.denyProductApproval(
      slug,
      this.state.adminMessage
    );
    if (res?.data?.success) {
      this.props.navigate("/product-approval");
    }
    this.setState({
      showDenyLoader: false,
      showHideDenyModal: false,
    });
  };

  closeAlertModal = () => {
    this.setState({
      alertModalData: {
        open: false,
        message: "",
        singleButton: true,
      },
    });
  };
  convertImageToBase64(file) {
    //Create an instance of FileReader
    const fileReader = new FileReader();
    //Implement onLoad function
    fileReader.onloadend = (event) =>
      this.setState((prevState) => {
        return {
          base64GalleryImages: [
            ...prevState.base64GalleryImages,
            fileReader.result,
          ],
        };
      });

    //Start reading the file
    fileReader.readAsDataURL(file);
  }

  readFile(file) {
    //Create an instance of FileReader
    const fileReader = new FileReader();
    //Implement onLoad function
    fileReader.onloadend = (event) =>
      this.setState({
        previewProductImage: [fileReader.result],
      });
    //Start reading the file
    fileReader.readAsDataURL(file);
  }

  triggerClick = () => {
    this.fileUpload.current.click();
  };

  validateProductInfo = () => {
    let isFormValid = true;
    this.state.errorData = {
      productTitle: [],
      productPrice: [],
      productImage: [],
      productTimeFrame: [],
      productHeight: [],
      productWidth: [],
      productLength: [],
      productWeight: [],
      productDescription: [],
      selectedProductCategoryId: [],
      selectedCourierList: [],
      order_limit: [],
      acsCatTitle: [],
      variantCombination: [],
      acsCatDescription: [],
      getVarientCombination: [],
      acsCatSubcategories: this.state.acsCatSubcategories.map((a) => ({
        acsCatVideos: [],
        acsCatAudios: [],
        acsCatImages: [],
        acsCatDocuments: [],
      })),
    };

    if (
      this.state.productTitle &&
      this.state.productTitle.trim().length === 0
    ) {
      isFormValid = false;
      this.state.errorData.productTitle = [
        this.state.isAccessCategoryInCategories
          ? `Company name field cannot be empty.`
          : `Product title field cannot be empty.`,
      ];
    }

    if (!this.state.isAccessCategoryInCategories) {
      if (this.state.productPrice?.trim()?.length === 0) {
        isFormValid = false;
        this.state.errorData.productPrice = ["Price field cannot be empty."];
      }

      if (
        this.state.is_sale &&
        this.state.price_type == 1 &&
        +this.state.productPrice <= +this.state.sale_price
      ) {
        isFormValid = false;
        this.state.errorData.salePrice = [
          "sale price cannot be greater than base price ",
        ];
      }
    }

    if (
      this.state.isAccessCategoryInCategories &&
      this.state.isOneOnOneMeetingPriceChecked
    ) {
      if (!!!this.state.o_o_o_meeting_price) {
        isFormValid = false;
        this.state.errorData.o_o_o_meeting_price = ["Please enter valid price"];
      }
    }

    if (!this.state.productImage || this.state.productImage == "") {
      isFormValid = false;
      this.state.errorData.productImage = [
        "Product Image field cannot be empty.",
      ];
    }
    if (
      this.state.selectedParentProductCategoryId &&
      this.state.selectedParentProductCategoryId.includes(3) &&
      !this.state.productTimeFrame?.length
    ) {
      isFormValid = false;
      this.state.errorData.productTimeFrame = [
        "Product time-frame field cannot be empty.",
      ];
    }

    if (
      !this.state.isAccessCategoryInCategories &&
      !this.state.isDigitalChecked &&
      this.state.productWeight === 0
    ) {
      isFormValid = false;
      this.state.errorData.productWeight = ["Product weight required."];
    } else if (
      !this.state.isAccessCategoryInCategories &&
      !this.state.isDigitalChecked &&
      this.state.productWeight > 0 &&
      isNaN(+this.state.productWeight) &&
      !this.state.isDobaProduct
    ) {
      isFormValid = false;
      this.state.errorData.productWeight = ["Enter a valid product weight."];
    }

    if (
      !this.state.isAccessCategoryInCategories &&
      !this.state.isDigitalChecked &&
      this.state.productLength === 0 &&
      !this.state.isDobaProduct
    ) {
      isFormValid = false;
      this.state.errorData.productLength = ["Product length required."];
    } else if (
      !this.state.isAccessCategoryInCategories &&
      !this.state.isDigitalChecked &&
      this.state.productLength > 0 &&
      isNaN(+this.state.productLength) &&
      !this.state.isDobaProduct
    ) {
      isFormValid = false;
      this.state.errorData.productLength = ["Enter a valid product length."];
    }

    if (this.state.isVariantChange && this.state.withAttribute) {
      isFormValid = false;
      this.state.errorData.getVarientCombination = [
        "Please select the ( CREATE VARIENT COMBINATION ) to create varient products.",
      ];
    }

    if (this.state.withAttribute && !this.state.variantCombination.length) {
      isFormValid = false;
      this.state.errorData.variantCombination = ["Please Create Combination"];
    }

    if (this.state.withAttribute && this.state.variantCombination.length > 0) {
      for (let combination of this.state.variantCombination) {
        if (!combination[1]) {
          isFormValid = false;
          this.state.errorData.getVarientCombination = [
            "Please enter variation detail",
          ];
          break;
        } else if (!combination[1].price || combination[1].price === 0) {
          isFormValid = false;
          this.state.errorData.getVarientCombination = [
            "Please enter variation price",
          ];
          break;
        }
        if (
          this.state.isAccessCategoryInCategories &&
          this.state.isOneOnOneMeetingPriceChecked
        ) {
          if (
            !combination[1]?.one_on_one_meeting_price ||
            combination[1]?.one_on_one_meeting_price === 0
          ) {
            isFormValid = false;
            this.state.errorData.getVarientCombination = [
              "Please enter one on one meeting price for variants",
            ];
            break;
          }
        }
      }
    }

    if (
      !this.state.isAccessCategoryInCategories &&
      !this.state.isDigitalChecked &&
      this.state.productWidth === 0 &&
      !this.state.isDobaProduct
    ) {
      isFormValid = false;
      this.state.errorData.productWidth = ["Product width required."];
    } else if (
      !this.state.isAccessCategoryInCategories &&
      !this.state.isDigitalChecked &&
      this.state.productWidth > 0 &&
      isNaN(+this.state.productWidth) &&
      !this.state.isDobaProduct
    ) {
      isFormValid = false;
      this.state.errorData.productWidth = ["Enter a valid product weight."];
    }

    if (
      !this.state.isAccessCategoryInCategories &&
      !this.state.isDigitalChecked &&
      this.state.productHeight === 0 &&
      !this.state.isDobaProduct
    ) {
      isFormValid = false;
      this.state.errorData.productHeight = ["Product height required."];
    } else if (
      this.state.productHeight > 0 &&
      isNaN(+this.state.productHeight) &&
      !this.state.isDobaProduct
    ) {
      isFormValid = false;
      this.state.errorData.productHeight = ["Enter a valid product height."];
    }
    if (!this.state.isAccessCategoryInCategories) {
      if (this.state.order_limit === 0 && !this.state.isDobaProduct) {
        isFormValid = false;
        this.state.errorData.order_limit = ["Order limit required."];
      } else if (
        this.state.order_limit < this.state.minimumOrderQuantityLimit
      ) {
        isFormValid = false;
        this.state.errorData.order_limit = [
          "Order limit quantity must be great than active subscription.",
        ];
      }
    }

    if (
      this.state.is_sale &&
      this.state.price_type == 2 &&
      this.state.sale_price > 100
    ) {
      isFormValid = false;
      this.state.errorData.salePrice = [
        "sale price cannot be greater than 100% ",
      ];
    }
    if (!this.state.isAccessCategoryInCategories) {
      if (this.state.is_sale && !this.state.sale_price) {
        isFormValid = false;
        this.state.errorData.salePrice = ["Please enter a sale price.."];
      }
      if (this.state.is_sale) {
        if (
          !this.state.sale_price ||
          this.state.sale_price.toString().search("^[0-9.]+$")
        ) {
          isFormValid = false;
          this.state.errorData.salePrice = ["Please enter valid sale price.."];
        }
      }
    }
    if (
      this.state.productDescription &&
      this.state.productDescription?.trim().length === 0
    ) {
      isFormValid = false;
      this.state.errorData.productDescription = [
        "Product Description field cannot be empty.",
      ];
    }

    if (this.state.selectedParentProductCategoryId.length === 0) {
      isFormValid = false;
      this.state.errorData.selectedParentProductCategoryId = [
        "Select alteast one parent category.",
      ];
    }

    if (
      !this.state.isAccessCategoryInCategories &&
      !this.state.isDigitalChecked &&
      this.state.selectedCourierList.length === 0 &&
      this.state.courierList.length
    ) {
      isFormValid = false;
      this.state.errorData.selectedCourierList = [
        "Select alteast one courier service.",
      ];
    }

    if (this.state.isAccessCategoryInCategories) {
      this.state.acsCatSubcategories.map((subCategory, index) => {
        // if (subCategory.acsCatTitle?.trim()?.length > 0) {
        //   this.state.errorData.acsCatTitle.push("");
        // } else {
        //   this.state.errorData.acsCatTitle.push("Title cannot be empty!");
        //   isFormValid = false;
        // }

        subCategory.acsCatVideosTitles.map((vTitle, vIndex) => {
          vTitle =
            vTitle + "." + subCategory.acsCatVideos[vIndex].type.split("/")[1];
          let oldNames = subCategory.acsCatVideoGallery.map((i) =>
            this.getFileNameFromUrl(i)
          );
          if (vTitle === "") {
            isFormValid = false;
            this.state.errorData.acsCatSubcategories[index].acsCatSubcategories[
              index
            ].acsCatVideos.push(`Video name cannot be empty`);
          } else if (
            oldNames.includes(vTitle) ||
            subCategory.acsCatVideosTitles.filter((t) => t === vTitle).length >
              1
          ) {
            isFormValid = false;
            this.state.errorData.acsCatSubcategories[index].acsCatVideos.push(
              `Video name already in use!`
            );
          }
        });

        subCategory.acsCatAudiosTitles.map((vTitle, vIndex) => {
          vTitle =
            vTitle + "." + subCategory.acsCatAudios[vIndex].type.split("/")[1];
          let oldNames = subCategory.acsCatAudioGallery.map((i) =>
            this.getFileNameFromUrl(i)
          );
          if (vTitle === "") {
            isFormValid = false;
            this.state.errorData.acsCatSubcategories[index].acsCatSubcategories[
              index
            ].acsCatAudios.push(`Image name cannot be empty`);
          } else if (
            oldNames.includes(vTitle) ||
            subCategory.acsCatAudiosTitles.filter((t) => t === vTitle).length >
              1
          ) {
            isFormValid = false;
            this.state.errorData.acsCatSubcategories[index].acsCatAudios.push(
              `Image name already in use!`
            );
          }
        });

        subCategory.acsCatImagesTitles.map((vTitle, vIndex) => {
          vTitle =
            vTitle + "." + subCategory.acsCatImages[vIndex]?.type.split("/")[1];
          let oldNames = subCategory.acsCatImageGallery.map((i) =>
            this.getFileNameFromUrl(i)
          );
          if (vTitle === "") {
            isFormValid = false;
            this.state.errorData.acsCatSubcategories[index].acsCatSubcategories[
              index
            ].acsCatImages.push(`Image name cannot be empty`);
          } else if (
            oldNames.includes(vTitle) ||
            subCategory.acsCatImagesTitles.filter((t) => t === vTitle).length >
              1
          ) {
            isFormValid = false;
            this.state.errorData.acsCatSubcategories[index].acsCatImages.push(
              `Image name already in use!`
            );
          }
        });

        subCategory.acsCatDocumentsTitles.map((vTitle, vIndex) => {
          vTitle =
            vTitle +
            "." +
            subCategory.acsCatDocuments[vIndex].type.split("/")[1];
          let oldNames = subCategory.acsCatDocGallery.map((i) =>
            this.getFileNameFromUrl(i)
          );
          if (vTitle === "") {
            isFormValid = false;
            this.state.errorData.acsCatSubcategories[index].acsCatSubcategories[
              index
            ].acsCatDocuments.push(`Image name cannot be empty`);
          } else if (
            oldNames.includes(vTitle) ||
            subCategory.acsCatDocumentsTitles.filter((t) => t === vTitle)
              .length > 1
          ) {
            isFormValid = false;
            this.state.errorData.acsCatSubcategories[
              index
            ].acsCatDocuments.push(`Image name already in use!`);
          }
        });
      });
    }

    let tempState = this.state;
    this.setState({
      ...tempState,
    });

    return isFormValid;
  };

  handleSubmit = async () => {
    try {
      if (this.vendorId) {
        await this.setState({
          successMessage: "",
          errorMessage: "",
          bottomErrorMessage: "",
          showLoader: true,
        });

        if (this.validateProductInfo()) {
          let res;

          if (this.state.videos && this.state.videos.length > 0) {
            for (const file of this.state.videos) {
              file["status"] = "Waiting";
            }
            this.setState({ videos: this.state.videos });
          }
          let acsSubcategoryList = this.state.acsCatSubcategories;
          for (let i = 0; i < this.state.acsCatSubcategories; i++) {
            if (
              this.state.acsCatSubcategories[i].acsCatVideos &&
              this.state.acsCatSubcategories[i].acsCatVideos.length > 0
            ) {
              for (const file of this.state.acsCatSubcategories[i]
                .acsCatVideos) {
                file["status"] = "Waiting";
              }
              acsSubcategoryList[i].acsCatVideos =
                this.state.acsCatSubcategories[i].acsCatVideos;
            }
            if (
              this.state.acsCatSubcategories[i].acsCatAudios &&
              this.state.acsCatSubcategories[i].acsCatAudios.length > 0
            ) {
              for (const file of this.state.acsCatSubcategories[i]
                .acsCatAudios) {
                file["status"] = "Waiting";
              }
              acsSubcategoryList[i].acsCatAudios =
                this.state.acsCatSubcategories[i].acsCatAudios;
            }
            if (
              this.state.acsCatSubcategories[i].acsCatImages &&
              this.state.acsCatSubcategories[i].acsCatImages.length > 0
            ) {
              for (const file of this.state.acsCatSubcategories[i]
                .acsCatImages) {
                file["status"] = "Waiting";
              }
              acsSubcategoryList[i].acsCatImages =
                this.state.acsCatSubcategories[i].acsCatImages;
            }
            if (
              this.state.acsCatSubcategories[i].acsCatDocuments &&
              this.state.acsCatSubcategories[i].acsCatDocuments.length > 0
            ) {
              for (const file of this.state.acsCatSubcategories[i]
                .acsCatDocuments) {
                file["status"] = "Waiting";
              }
              acsSubcategoryList[i].acsCatDocuments =
                this.state.acsCatSubcategories[i].acsCatDocuments;
            }
          }
          this.setState({
            acsCatSubcategories: [...acsSubcategoryList],
          });

          const {
            productModelNo,
            type,
            is_registered_on_store,
            seller_store_product_id,
            productTitle,
            productPrice,
            productImage,
            galleryImages,
            productTimeFrame,
            selectedCourierList,
            productHeight,
            productWidth,
            productLength,
            productWeight,
            isActive,
            isFeatured,
            isApprove,
            productDescription,
            selectedProductCategoryId,
            selectedParentProductCategoryId,
            withAttribute,
            selectedAttributeVariants,
            variantCombination,
            stripe_product_id,
            is_shipping_free,
            is_sale,
            order_limit,
            price_type,
            sale_price,
            isDobaProduct,
            o_o_o_meeting_price,
            isCustomizeCategory,
            isDigitalChecked,
            customizedProductTime,
            customizeQuestions,
            primaryIndex,
          } = this.state;

          if (this.state.isEdit) {
            res = await this.props.updateVendorProductDetail(
              {
                productModelNo,
                productTitle,
                productPrice: this.state.isAccessCategoryInCategories
                  ? 0
                  : productPrice
                  ? productPrice
                  : 0,
                productImage,
                galleryImages: JSON.stringify(galleryImages),
                productTimeFrame,
                selectedCourierList:
                  isDigitalChecked && isCustomizeCategory
                    ? "[]"
                    : JSON.stringify(selectedCourierList),
                productHeight:
                  isDigitalChecked && isCustomizeCategory ? 0 : productHeight,
                productWidth:
                  isDigitalChecked && isCustomizeCategory ? 0 : productWidth,
                productLength:
                  isDigitalChecked && isCustomizeCategory ? 0 : productLength,
                productWeight:
                  isDigitalChecked && isCustomizeCategory ? 0 : productWeight,
                stripe_product_id,
                is_shipping_free,
                price_type,
                sale_price,
                primaryIndex,
                type,
                is_sale,
                order_limit: this.state.isAccessCategoryInCategories
                  ? 0
                  : order_limit,
                isActive,
                isFeatured,
                isApprove,
                is_registered_on_store,
                seller_store_product_id,
                productDescription,
                selectedProductCategoryId: JSON.stringify(
                  selectedProductCategoryId.concat(
                    selectedParentProductCategoryId
                  )
                ),
                accessCategoryDescription: this.state.acsCatDescription,
                withAttribute,
                selectedAttributeVariants: JSON.stringify(
                  selectedAttributeVariants
                ),
                variantCombination: JSON.stringify(variantCombination),
                searchCategoryId: this.state.searchCategoryId.length
                  ? this.state.searchCategoryId
                  : null,
                isDobaProduct,
                o_o_o_meeting_price: this.state.isOneOnOneMeetingPriceChecked
                  ? this.state.o_o_o_meeting_price
                  : null,
                isDigitalChecked:
                  isDigitalChecked && isCustomizeCategory ? 1 : 0,
                updateStatusWhenDenied:
                  this.state.productDetail.status == "denied" ? 1 : 0,
                customizedProductTime: this.state.customizedProductTime,
                customizeQuestions: customizeQuestions?.length
                  ? JSON.stringify(
                      customizeQuestions
                        ?.filter((i) => i?.trim() !== "")
                        ?.map((i) => i?.trim())
                    )
                  : JSON.stringify(customizeQuestions),
              },
              this.vendorId,
              this.state.productId
            );
          }

          if (res && res.data) {
            if (res.data.isSuccess) {
              this.props.navigate(`/product-approval`);
              const newVideos = this.state.videos;
              const attributeImg = this.state.attributeImage;
              const newAcsCatVideos = this.state.acsCatSubcategories.map(
                (item) => item.acsCatVideos
              );
              const newAcsCatAudios = this.state.acsCatSubcategories.map(
                (item) => item.acsCatAudios
              );
              const newAcsCatImages = this.state.acsCatSubcategories.map(
                (item) => item.acsCatImages
              );
              const newAcsCatDocuments = this.state.acsCatSubcategories.map(
                (item) => item.acsCatDocuments
              );
              if (
                (attributeImg && attributeImg?.length > 0) ||
                (newVideos && newVideos.length > 0) ||
                (newAcsCatVideos && newAcsCatVideos.length > 0) ||
                (newAcsCatAudios && newAcsCatAudios.length > 0) ||
                (newAcsCatImages && newAcsCatImages.length > 0) ||
                (newAcsCatDocuments && newAcsCatDocuments.length > 0)
              ) {
                await this.uploadAllSelectedFile();
              }

              if (
                (newVideos && newVideos.length > 0) ||
                (newAcsCatVideos && newAcsCatVideos.length > 0) ||
                (newAcsCatAudios && newAcsCatAudios.length > 0) ||
                (newAcsCatImages && newAcsCatImages.length > 0) ||
                (newAcsCatDocuments && newAcsCatDocuments.length > 0) ||
                (this.state.removedGalleryVideos &&
                  this.state.removedGalleryVideos.length > 0) ||
                (this.state.removedAcsCatVideoGallery &&
                  this.state.removedAcsCatVideoGallery.length > 0) ||
                (this.state.removedAcsCatAudioGallery &&
                  this.state.removedAcsCatAudioGallery.length > 0) ||
                (this.state.removedAcsCatImageGallery &&
                  this.state.removedAcsCatImageGallery.length > 0) ||
                (this.state.removedAcsCatDocGallery &&
                  this.state.removedAcsCatDocGallery.length > 0)
              ) {
                await this.props.updateProductFiles(
                  {
                    productId: this.state.productId
                      ? this.state.productId
                      : res.data.productId,
                    galleryVideosUrl: this.state.galleryVideos.join(", "),
                    accessCategorySubcategoryTitle: JSON.stringify(
                      this.state.acsCatSubcategories.map((item, index) => ({
                        [index]: item.acsCatTitle,
                      }))
                    ),
                    accessCategoryVideoGalleryUrl: JSON.stringify(
                      this.state.acsCatSubcategories.map((item, index) => ({
                        [index]: item.acsCatVideoGallery.join(", "),
                      }))
                    ),
                    accessCategoryAudioGalleryUrl: JSON.stringify(
                      this.state.acsCatSubcategories.map((item, index) => ({
                        [index]: item.acsCatAudioGallery.join(", "),
                      }))
                    ),
                    accessCategoryImageGalleryUrl: JSON.stringify(
                      this.state.acsCatSubcategories.map((item, index) => ({
                        [index]: item.acsCatImageGallery.join(", "),
                      }))
                    ),
                    accessCategoryDocGalleryUrl: JSON.stringify(
                      this.state.acsCatSubcategories.map((item, index) => ({
                        [index]: item.acsCatDocGallery.join(", "),
                      }))
                    ),
                  },

                  this.userId
                );
              }
              if (
                this.state.removedGalleryVideos &&
                this.state.removedGalleryVideos.length > 0
              ) {
                this.props.removeProductFiles({
                  productId: this.state.productId,
                  filesUrl: this.state.removedGalleryVideos,
                  fileUploadPathEndPoint: "/video_gallery",
                });
              }
              if (
                this.state.removedAcsCatVideoGallery &&
                this.state.removedAcsCatVideoGallery.length > 0
              ) {
                this.props.removeProductFiles({
                  productId: this.state.productId,
                  filesUrl: this.state.removedAcsCatVideoGallery,
                  fileUploadPathEndPoint: "/access/video",
                });
              }
              if (
                this.state.removedAcsCatAudioGallery &&
                this.state.removedAcsCatAudioGallery.length > 0
              ) {
                this.props.removeProductFiles({
                  productId: this.state.productId,
                  filesUrl: this.state.removedAcsCatAudioGallery,
                  fileUploadPathEndPoint: "/access/audio",
                });
              }
              if (
                this.state.removedAcsCatImageGallery &&
                this.state.removedAcsCatImageGallery.length > 0
              ) {
                this.props.removeProductFiles({
                  productId: this.state.productId,
                  filesUrl: this.state.removedAcsCatImageGallery,
                  fileUploadPathEndPoint: "/access/image",
                });
              }
              if (
                this.state.removedAcsCatDocGallery &&
                this.state.removedAcsCatDocGallery.length > 0
              ) {
                this.props.removeProductFiles({
                  productId: this.state.productId,
                  filesUrl: this.state.removedAcsCatDocGallery,
                  fileUploadPathEndPoint: "/access/doc",
                });
              }

              // success case
              this.setState({
                successMessage: res.data.message,
                showLoader: false,
                src: null,
              });
              window.scrollTo(0, 0);
              this.fetchProductDetail();

              setTimeout(() => {
                this.setState({
                  successMessage: "",
                });
              }, 4000);

              await this.props.getProductListCountForApproval();
              this.state.isCameFromApproveProductPage
                ? this.props.navigate(`/product-approval`)
                : this.props.navigate(`/vendors/product/${this.vendorId}`);
            } else {
              // response with failed case
              this.setState({
                errorMessage: res.data.message,
                showLoader: false,
              });
            }
          } else {
            // error to get respone
            this.setState({
              errorMessage: "Something went wrong. Please try again later.",
              showLoader: false,
            });
          }
        } else {
          this.setState({
            showLoader: false,
          });
          this.setState({
            bottomErrorMessage: "Please Fill All the (*) Details",
          });
        }
      } else {
        this.props.navigate("/login");
      }
      this.setState({
        showLoader: false,
      });
      if (this.state.successMessage || this.state.errorMessage) {
        window.scrollTo(0, 0);
      }
    } catch (error) {
      console.log(error, "error >>>>>>>>>>>>");
    }
  };

  handleWithAttributeChange = async (event) => {
    const value = +event.target.value;
    let attributesInfo = null;
    let state = {};
    if (value === 1) {
      attributesInfo = await this.fetchAttributesList();
      await this.setState({
        withAttribute: value,
        price_type: 2,
        isFirstVariantAdded: true,
      });

      state = {
        ...this.state,
        attributesList: attributesInfo?.attributesList,
        attributesDefaultSelectOptions: [],
        attributesSelectOptions: attributesInfo?.attributesSelectOptions,
        selectedAttributeVariants: [],
        variantCombination: [],
      };
    } else {
      state = {
        ...this.state,
        price_type: 1,
        withAttribute: 0, //0 = false | 1 = true
        attributesList: [],
        attributesDefaultSelectOptions: [],
        attributesSelectOptions: [],
        selectedAttributeVariants: [],
        variantCombination: [],
      };
    }

    this.setState({
      ...state,
    });
  };

  async fetchAttributesList() {
    const attributesList = await this.props.getAttributeList(this.vendorId, 0);

    if (attributesList?.data?.data?.length > 0) {
      const attributesSelectOptions = attributesList.data.data.map(
        (attribute) => {
          return {
            value: attribute.name,
            label: attribute.name.replace("_", " "),
          };
        }
      );

      return {
        attributesList: attributesList.data.data,
        attributesSelectOptions,
      };
    }
  }

  alertModal = (data) => {
    this.setState({
      alertModalData: {
        open: true,
        message: data.message,
        singleButton: data.singleButton,
        positiveButton: data.positiveButton,
        negativeButton: data.negativeButton,
        id: data.id,
        data: data.data,
        from: data.from ? data.from : false,
      },
    });
  };
  attributeStateChange = () => {
    this.setState({
      attributeChange: true,
    });
  };

  alertVariant = async () => {
    this.alertModal({
      message:
        "If you want to do change in variant then you have to create combination again.",
      singleButton: true,
    });
  };
  handleProductTime = (e) => {
    this.setState({
      customizedProductTime: +e.target.value ? +e.target.value : 0,
    });
  };

  handleProductAttributeChange = async (event) => {
    this.setState(
      {
        attributesDefaultSelectOptions: event ? event : [],
      },
      await this.attributeStateChange()
    );
    if (this.state.attributeChange) {
      this.setState({
        selectedAttributeVariants: "",
        variantCombination: "",
        primaryIndex: null,
      });
    }
  };

  getSelectedAttributeVariants = async (productAttributeVariantDetail = []) => {
    let selectedAttributeVariants = [...this.state.selectedAttributeVariants];
    let attributeVariantObj = {};

    if (productAttributeVariantDetail.length > 0) {
      const allKeys = Object.keys(
        JSON.parse(productAttributeVariantDetail[0].variant_value)
      );

      productAttributeVariantDetail.forEach((variant) => {
        variant = JSON.parse(variant.variant_value);
        allKeys.forEach((key) => {
          if (!attributeVariantObj[key]) {
            attributeVariantObj[key] = [variant[key]];
          } else {
            if (!attributeVariantObj[key].includes(variant[key])) {
              attributeVariantObj[key].push(variant[key]);
            }
          }
        });
      });
    }

    await this.state.attributesDefaultSelectOptions.forEach(
      async (variant, index) => {
        const createOptions = await this.createVariantsSelectOption(
          variant.value,
          attributeVariantObj[variant.value]
        );
        const tempArray = this.state.attributesDefaultSelectOptions.map(
          (item) => {
            return item.value;
          }
        );
        if (
          !tempArray[index]?.includes(
            this.state.selectedAttributeVariants[index]?.attributeName
          )
        ) {
          selectedAttributeVariants.push(createOptions);
        }
      }
    );
    this.setState({
      selectedAttributeVariants: selectedAttributeVariants,
    });
  };

  createVariantsSelectOption(attributeName, selectedVariantValue = []) {
    let attributeInfo = {};
    this.state.attributesList.forEach((variant) => {
      if (variant.name === attributeName) {
        attributeInfo = variant;
      }
    });

    const variationSelectOptions = this.variationValuesSplitter(
      attributeInfo.variation_values,
      selectedVariantValue
    );

    return {
      attributeName,
      attributeSelectOptions: variationSelectOptions.selectOptions,
      attributeDefaultSelectedOptions:
        variationSelectOptions.defaultSelectedOptions,
    };
  }

  variationValuesSplitter = (variation_values, selected_variant_values) => {
    variation_values = variation_values.split(",");

    let defaultSelectedOptions = [];

    let selectOptions = variation_values.map((variation) => {
      if (selected_variant_values.includes(variation)) {
        defaultSelectedOptions.push({
          value: `${variation}`,
          label: `${variation}`,
        });
      }
      return { value: `${variation}`, label: `${variation}` };
    });

    return { selectOptions, defaultSelectedOptions };
  };

  handleVariantChange = async (event, index) => {
    if (!this.state.isFirstVariantAdded) {
      await this.alertVariant();
    }
    let selectedAttributeVariants = this.state.selectedAttributeVariants;
    selectedAttributeVariants[index].attributeDefaultSelectedOptions = event
      ? event
      : [];

    this.setState({
      selectedAttributeVariants,
      isVariantChange: true,
    });
  };

  makePrimary = async (e, index) => {
    if (e.target.checked) {
      this.setState({
        isPrimary: true,
        primaryIndex: index,
      });
    } else {
      this.setState({
        isPrimary: false,
        primaryIndex: null,
      });
    }
  };

  errorMessage = (file) => {
    if (file?.size > 10000000) {
      this.setState({
        errorData: {
          ...this.state.errorData,
          attributeFileError:
            "You can not upload images more than 10mb file size",
        },
      });
      return true;
    }
  };

  uploadGlobalAttributes = async (e, key, value, index) => {
    e.preventDefault();
    if (value && key === "globalPrice") {
      let price = e?.target?.value;
      const tempObj = {
        [value]: price,
      };
      this.setState((prevState) => {
        const updatedArray = prevState.specificAttributeGlobalPrice.map(
          (obj, i) => {
            if (obj.hasOwnProperty(value)) {
              return { ...obj, [value]: price };
            }
            return obj;
          }
        );
        if (!updatedArray.some((obj) => obj.hasOwnProperty(value))) {
          updatedArray.push(tempObj);
        }
        return {
          specificAttributeGlobalPrice: updatedArray,
        };
      });
    } else if (!value && key === "globalPrice") {
      this.setState({
        attributeGlobalPrice: e.target.value,
      });
    } else if (!value && key === "globalImage") {
      const file = e.target.files[0];
      const fileReader = new FileReader();
      const z = this.errorMessage(file);
      if (!z) {
        fileReader.onloadend = () => {
          this.setState({
            attributeGlobalImage: fileReader.result,
          });
        };
      }
      fileReader.readAsDataURL(file);
    } else if (value && key === "globalImage") {
      const file = e.target.files[0];
      const fileReader = new FileReader();
      const z = this.errorMessage(file);
      if (!z) {
        fileReader.onloadend = () => {
          const tempObj = {
            [value]: fileReader.result,
          };
          this.setState((prevState) => {
            const updatedArray = prevState.specificAttributeGlobalImage.map(
              (obj, i) => {
                if (obj.hasOwnProperty(value)) {
                  return { ...obj, [value]: fileReader.result };
                }
                return obj;
              }
            );
            if (!updatedArray.some((obj) => obj.hasOwnProperty(value))) {
              updatedArray[index] = tempObj;
            }
            return {
              specificAttributeGlobalImage: updatedArray,
            };
          });
        };
        fileReader.readAsDataURL(file);
      }
    }
  };

  applyGlobalAttribute = async (globalPrice, value, globalIndex) => {
    let variantCombination = this.state.variantCombination;
    if (value) {
      if (
        !this.state.specificAttributeGlobalImage[globalIndex] ||
        !this.state.specificAttributeGlobalPrice[globalIndex]
      ) {
        this.setState({
          errorData: {
            ...this.state.errorData,
            specificAttributeGlobalImageError: "Please Check Your Details",
          },
        });
        return;
      }
      if (
        Object.values(
          this.state.specificAttributeGlobalPrice[globalIndex]
        )[0] <= 0
      ) {
        this.setState({
          errorData: {
            ...this.state.errorData,
            specificAttributeGlobalImageError:
              "You can not enter either 0 or negative value.",
          },
        });
        return;
      }
      if (
        this.state.specificAttributeGlobalImage &&
        this.state.variantCombination?.length
      ) {
        const temp = [...this.state.attributeImage];
        variantCombination &&
          variantCombination.forEach((items, index) => {
            Object.values(items[0]).forEach((item) => {
              if (item === value) {
                temp[index] =
                  this.state.specificAttributeGlobalImage[globalIndex][item];
              }
            });
          });
        this.setState({
          attributeImage: temp,
        });
      }
      if (
        this.state.specificAttributeGlobalPrice &&
        this.state.variantCombination?.length
      ) {
        const temp = [...this.state.variantCombination];
        variantCombination &&
          variantCombination.map((items, index) => {
            Object.values(items[0]).map((item) => {
              if (item === value) {
                temp[index][1] = {
                  price:
                    this.state.specificAttributeGlobalPrice[globalIndex][item],
                };
              }
            });
          });
        this.setState({
          variantCombination: temp,
        });
      }
    }
    if (!value) {
      if (globalPrice == null || !this.state.attributeGlobalImage) {
        this.setState({
          errorData: {
            ...this.state.errorData,
            specificAttributeGlobalImageError: "Please Check Your Details.",
          },
        });
        return;
      }
      if (globalPrice <= 0) {
        this.setState({
          errorData: {
            ...this.state.errorData,
            specificAttributeGlobalImageError:
              "You can not enter either 0 or negative value.",
          },
        });
        return;
      }
      if (
        this.state.attributeGlobalImage &&
        this.state.variantCombination?.length
      ) {
        const temp = [].concat(
          Array(this.state.variantCombination.length).fill(
            this.state.attributeGlobalImage
          )
        );
        this.setState({
          attributeImage: temp,
        });
      }
      variantCombination &&
        variantCombination.map((item) => {
          item[1] = { price: globalPrice };
        });
      this.setState({
        variantCombination,
      });
    }
  };
  createVariantCombination = async () => {
    const selectedAttributeVariants = this.state.selectedAttributeVariants;
    const combinations = this.cartesian(selectedAttributeVariants);
    this.setState({
      variantCombination: combinations,
      isVariantChange: false,
    });
  };

  cartesian(args) {
    var r = [],
      max = args.length - 1;

    function helper(arr, i) {
      for (
        var j = 0, l = args[i].attributeDefaultSelectedOptions.length;
        j < l;
        j++
      ) {
        var a = JSON.parse(JSON.stringify(arr)); // clone arr

        Object.assign(a, {
          [args[i].attributeName]:
            args[i].attributeDefaultSelectedOptions[j].value,
        });

        if (i === max) {
          r.push([a]);
        } else {
          helper(a, i + 1);
        }
      }
    }

    helper({}, 0);
    return r;
  }

  onRemoveSpecificCombination = (index) => {
    const newCombinationArray = this.state.variantCombination.splice(index, 1);
    const newAttributeImageArray = this.state.attributeImage.splice(index, 1);
    this.setState({
      newCombinationArray,
      newAttributeImageArray,
    });
  };

  handleVariantCombinationPriceChange = (event, index) => {
    const variantCombination = this.state.variantCombination;
    variantCombination[index][1] = {
      ...variantCombination[index][1],
      price: event.target.value,
    };
    let errorData = this.state.errorData;
    errorData["getVarientCombination"] = [];
    this.setState({
      variantCombination,
      errorData,
    });
  };

  handleVariantCombinationOneOnOneMeetingPriceChange = (event, index) => {
    const variantCombination = this.state.variantCombination;
    if (this.state.isOneOnOneMeetingPriceChecked) {
      variantCombination[index][1] = {
        ...variantCombination[index][1],
        one_on_one_meeting_price: event.target.value ? event.target.value : 0,
      };
    } else {
      variantCombination[index][1] = {
        ...variantCombination[index][1],
        one_on_one_meeting_price: null,
      };
    }
    let errorData = this.state.errorData;
    errorData["getVarientCombination"] = [];
    this.setState({
      variantCombination,
      errorData,
    });
  };

  handleChangeChk = (e, key, weekDay, frequency) => {
    this.state.errorData.selectedOneOnOneFrequency = [];
    this.setState({
      isOneOnOneMeetingDetailsEdited: true,
    });
    if (key === "isWeekDaySelected") {
      this.state.errorData.selectedWeekDay = [];
      let tempArray = { ...this.state.isWeekDaySelected };
      let tempOneOnOneMeetingTimeSlotArray = {
        ...this.state.oneOnOneMeetingSlots,
      };
      tempArray[weekDay] = e.target.checked;
      if (e.target.checked) {
        tempOneOnOneMeetingTimeSlotArray[weekDay] = [[0, 0]];
      } else {
        delete tempOneOnOneMeetingTimeSlotArray[weekDay];
      }
      this.setState({
        isWeekDaySelected: { ...tempArray },
        oneOnOneMeetingSlots: { ...tempOneOnOneMeetingTimeSlotArray },
      });
      return;
    } else if (key === "selectedFrequency") {
      let tempFrequencyArray = { ...this.state.selectedFrequency };
      tempFrequencyArray[`${frequency}`] = e.target.checked;

      let isAllFrequencySelected = 0;
      Object.entries(tempFrequencyArray)
        .slice(1, 5)
        .map(([bool]) => {
          if (bool) {
            isAllFrequencySelected += 1;
          }
        });

      if (isAllFrequencySelected === 4) {
        tempFrequencyArray["All"] = true;
      } else {
        tempFrequencyArray["All"] = false;
      }
      this.setState({
        selectedFrequency: { ...tempFrequencyArray },
      });
      return;
    } else if (key === "allFrequencySelected") {
      let tempFrequencyArray = { ...this.state.selectedFrequency };
      this.selectOneOnOneFrequency.map((item) => {
        tempFrequencyArray[item] = e.target.checked;
      });
      this.setState({
        selectedFrequency: { ...tempFrequencyArray },
      });
    }
    this.setState({
      [key]: this.state[key] === 0 ? 1 : 0,
    });
  };

  handleAlertParentAccessCategory = async (event, value) => {
    if (this.state.defaultParentSelectedOption.length) {
      this.setState({
        is_sale: false,
      });
    }
    if (this.state?.defaultParentSelectedOption[0]?.label === event.label) {
      return null;
    }
    this.setState({
      searchCategoryParents: [],
      searchCategoryValue: "",
      searchCategoryId: 0,
      searchCategoryChildOptions: [],
    });
    let category = event.label;

    if (category && category.toLocaleLowerCase() !== "access") {
      return this.handleParentProductCategoryChange(event);
    }
    if (value.action === "clear") {
      return this.handleParentProductCategoryChange(event);
    }
    confirmAlert({
      title: "",
      message: `Are you sure want to Add Access category? `,
      buttons: [
        {
          label: "Yes",
          onClick: () => this.handleParentProductCategoryChange(event),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
    this.setState({
      productTimeFrame: "",
      sale_price: 0,
    });
  };

  handleAlertAccessCategory = async (event, value) => {
    let category = null;
    if (value.action === "remove-value") {
      category = value?.removedValue?.label;
    } else {
      category = value?.option?.label;
    }
    if (category && category.toLocaleLowerCase() !== "access") {
      return this.handleProductCategoryChange(event);
    }
    if (value.action === "clear") {
      return this.handleProductCategoryChange(event);
    }
  };

  dateDiff(startingDate, endingDate) {
    var startDate = new Date(
      new Date(startingDate).toISOString().substr(0, 10)
    );
    if (!endingDate) {
      endingDate = new Date().toISOString().substr(0, 10); // need date in YYYY-MM-DD format
    }
    var endDate = new Date(endingDate);
    if (startDate > endDate) {
      var swap = startDate;
      startDate = endDate;
      endDate = swap;
    }
    var startYear = startDate.getFullYear();
    var february =
      (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
        ? 29
        : 28;
    var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    var yearDiff = endDate.getFullYear() - startYear;
    var monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }
    var dayDiff = endDate.getDate() - startDate.getDate();
    if (dayDiff < 0) {
      if (monthDiff > 0) {
        monthDiff--;
      } else {
        yearDiff--;
        monthDiff = 11;
      }
      dayDiff += daysInMonth[startDate.getMonth()];
    }
    if (yearDiff === 0 && monthDiff < 3) {
      return this.setState({
        diffMonths: true,
      });
    } else {
      return this.setState({
        diffMonths: false,
      });
    }
  }

  // function to trigger our input file click
  triggerFileUploadClick = (e, type, index) => {
    e.preventDefault();
    switch (type) {
      case "videos":
        this.setState((prevState) => ({
          ...prevState,
          currentFileFormat: this.fileFormats.video,
          currentFileType: type,
          currentFileContentType: "video",
          currentUploadSubCategoryIndex: 0,
        }));
        this.fileUploadVideo.current.click();
        break;
      case "images":
        break;
      case "documents":
        break;
      case "acsCatVideos":
        this.setState((prevState) => ({
          ...prevState,
          currentFileFormat: this.fileFormats.video,
          currentFileType: type,
          currentFileContentType: "video",
          currentUploadSubCategoryIndex: index,
        }));
        this.fileUploadVideo.current.click();
        break;
      case "acsCatAudios":
        this.setState((prevState) => ({
          ...prevState,
          currentFileFormat: this.fileFormats.audio,
          currentFileType: type,
          currentFileContentType: "audio",
          currentUploadSubCategoryIndex: index,
        }));
        this.fileUploadAudio.current.click();
        break;
      case "acsCatImages":
        this.setState((prevState) => ({
          ...prevState,
          currentFileFormat: this.fileFormats.image,
          currentFileType: type,
          currentFileContentType: "image",
          currentUploadSubCategoryIndex: index,
        }));
        this.fileUploadImage.current.click();
        break;
      case "acsCatDocuments":
        this.setState((prevState) => ({
          ...prevState,
          currentFileFormat: this.fileFormats.doc,
          currentFileType: type,
          currentFileContentType: "application",
          currentUploadSubCategoryIndex: index,
        }));
        this.fileUploadDoc.current.click();
        break;
      default:
        return;
    }
  };

  fileUploadHandler = (fileList) => {
    this.setState({
      errorData: {
        ...this.state.errorData,
        largeFileError: "",
      },
    });
    const state = this;
    let errors = [];

    for (const file of fileList) {
      if (file.size > 19000000) {
        this.setState({
          errorData: {
            ...this.state.errorData,
            largeFileError: "You can not upload more than 20mb file size",
          },
        });
        return;
      }
      //read file stream and set in state
      let type =
        state.state.currentFileType === "videos"
          ? "videos"
          : "acsCatSubcategories";
      let mediaList = [...state.state[type]];

      function readFile(file) {
        const fileReader = new FileReader();
        fileReader.onload = function (event) {
          const fileArray = {
            name: file.name,
            type: file.type,
            size: file.size,
            file: file,
            stream: event.currentTarget.result,
          };
          if (type === "videos") {
            mediaList.push(fileArray);
          } else {
            mediaList[state.state.currentUploadSubCategoryIndex][
              state.state.currentFileType
            ].push({ ...fileArray });
            mediaList[state.state.currentUploadSubCategoryIndex][
              state.state.currentFileType + "Titles"
            ].push("");
          }
        };
        fileReader.readAsDataURL(file);
        state.setState({
          [type]: mediaList,
        });
      }

      let imgType = file?.type?.split("/");
      let fileContentTypes = [imgType[0]];
      if (fileContentTypes[0] === "text") {
        fileContentTypes.push("application");
      }
      if (
        this.state.currentFileFormat.includes(imgType[1]) &&
        fileContentTypes.includes(this.state.currentFileContentType)
      ) {
        if (
          (type === "videos" &&
            !mediaList?.find(
              (item) => item.name === file.name && item.type === file.type
            )) ||
          (type !== "videos" &&
            !mediaList[this.state.currentUploadSubCategoryIndex][
              this.state.currentFileType
            ]?.find(
              (item) => item.name === file.name && item.type === file.type
            ))
        ) {
          readFile(file);
        }
      } else {
        errors.push(
          "File [" +
            file.name +
            "] format is not valid, Please upload a valid format."
        );
      }
    }

    if (errors && errors.length > 0) {
      errors.push(
        `Valid formats are ${this.state.currentFileFormat.join(", ")}.`
      );
    }
    this.props.addFilesError(this.state.currentFileType, errors);
  };

  fileSelectHandler = async (e) => {
    e.preventDefault();
    await this.fileUploadHandler(e.target.files);
    setTimeout(() => {
      this.setState({
        refreshComponent: "yes",
      });
    }, [500]);
  };

  deleteSelectedFile = (e, type, index, catIndex) => {
    e.preventDefault();
    if (["videos", "acsCatVideos", "acsCatAudios"].includes(type)) {
      this.setState((prevState) => {
        return {
          ...prevState,
          showVideos: false,
        };
      });
    }

    if (type === "videos") {
      let videos = this.state.videos;
      videos.splice(index, 1);
      this.setState({
        videos,
      });
    } else {
      let acsSubcategoryList = this.state.acsCatSubcategories;
      acsSubcategoryList[catIndex][type].splice(index, 1);
      acsSubcategoryList[catIndex][type + "Titles"].splice(index, 1);
      this.setState({
        acsCatSubcategories: acsSubcategoryList,
      });
    }

    if (["videos", "acsCatVideos", "acsCatAudios"].includes(type)) {
      setTimeout(() => {
        this.setState((prevState) => {
          return {
            ...prevState,
            showVideos: true,
          };
        });
      }, 100);
    }
  };

  deleteSelectedGalleryFile = async (e, type, url, index, catIndex) => {
    e.preventDefault();
    if (["videos", "acsCatVideos", "acsCatAudios"].includes(type)) {
      this.setState((prevState) => {
        return {
          ...prevState,
          showVideos: false,
        };
      });
    }

    let acsCatList = [...this.state.acsCatSubcategories];

    switch (type) {
      case "videos":
        this.state.galleryVideos.splice(index, 1);
        await this.setState((prevState) => {
          return {
            ...prevState,
            galleryVideos: this.state.galleryVideos,
            removedGalleryVideos: [...this.state.removedGalleryVideos, url],
          };
        });
        break;
      case "images":
        break;
      case "documents":
        break;
      case "acsCatVideos":
        acsCatList[catIndex].acsCatVideoGallery.splice(index, 1);
        acsCatList[catIndex].acsCatVideoGalleryTitles.splice(index, 1);
        acsCatList[catIndex].removedAcsCatVideoGallery = [
          ...this.state.acsCatSubcategories[catIndex].removedAcsCatVideoGallery,
          url,
        ];
        break;
      case "acsCatAudios":
        acsCatList[catIndex].acsCatAudioGallery.splice(index, 1);
        acsCatList[catIndex].acsCatAudioGalleryTitles.splice(index, 1);
        acsCatList[catIndex].removedAcsCatAudioGallery = [
          ...this.state.acsCatSubcategories[catIndex].removedAcsCatAudioGallery,
          url,
        ];
        break;
      case "acsCatImages":
        acsCatList[catIndex].acsCatImageGallery.splice(index, 1);
        acsCatList[catIndex].acsCatImageGalleryTitles.splice(index, 1);
        acsCatList[catIndex].removedAcsCatImageGallery = [
          ...this.state.acsCatSubcategories[catIndex].removedAcsCatImageGallery,
          url,
        ];
        break;
      case "acsCatDocuments":
        acsCatList[catIndex].acsCatDocGallery.splice(index, 1);
        acsCatList[catIndex].acsCatDocGalleryTitles.splice(index, 1);
        acsCatList[catIndex].removedAcsCatDocGallery = [
          ...this.state.acsCatSubcategories[catIndex].removedAcsCatDocGallery,
          url,
        ];
        break;
      default:
        return;
    }

    await this.setState({
      acsCatSubcategories: acsCatList,
    });

    if (["videos", "acsCatVideos", "acsCatAudios"].includes(type)) {
      setTimeout(() => {
        this.setState((prevState) => {
          return {
            ...prevState,
            showVideos: true,
          };
        });
      }, 100);
    }
  };

  deleteAllGalleryFiles = (e, type, index) => {
    e.preventDefault();
    let acsCatList = [...this.state.acsCatSubcategories];
    switch (type) {
      case "videos":
        this.setState((prevState) => {
          return {
            ...prevState,
            galleryVideos: [],
            removedGalleryVideos: [...this.state.galleryVideos],
          };
        });
        break;
      case "images":
        break;
      case "documents":
        break;
      case "acsCatVideos":
        acsCatList[index].acsCatVideoGallery = [];
        acsCatList[index].acsCatVideoGalleryTitles = [];
        acsCatList[index].removedAcsCatVideoGallery = [
          ...this.state.acsCatSubcategories[index].acsCatVideoGallery,
        ];
        break;
      case "acsCatAudios":
        acsCatList[index].acsCatAudioGallery = [];
        acsCatList[index].acsCatAudioGalleryTitles = [];
        acsCatList[index].removedAcsCatAudioGallery = [
          ...this.state.acsCatSubcategories[index].acsCatAudioGallery,
        ];
        break;
      case "acsCatImages":
        acsCatList[index].acsCatImageGallery = [];
        acsCatList[index].acsCatImageGalleryTitles = [];
        acsCatList[index].removedAcsCatImageGallery = [
          ...this.state.acsCatSubcategories[index].acsCatImageGallery,
        ];
        break;
      case "acsCatDocuments":
        acsCatList[index].acsCatDocGallery = [];
        acsCatList[index].acsCatDocGalleryTitles = [];
        acsCatList[index].removedAcsCatDocGallery = [
          ...this.state.acsCatSubcategories[index].acsCatDocGallery,
        ];
        break;
      default:
        return;
    }
    this.setState({
      acsCatSubcategories: acsCatList,
    });
    this.props.addFiles(type, []);
  };

  uploadAttributeSelectedImage = async (index, base64) => {
    return new Promise(async (resolve, reject) => {
      const slug = this.slug.split("?");
      const res = await this.props.uploadAttibute(base64, index, slug[0]);
      let tempAttribute = [...this.state.variantCombination];
      tempAttribute[index][1]["image"] = res?.data;
      this.setState({
        variantCombination: [...tempAttribute],
      });
      resolve(res?.data);
    });
  };
  uploadAllSelectedFile = async () => {
    return new Promise(async (resolve, reject) => {
      const errors = [];

      if (this.state.attributeImage && this.state.attributeImage?.length > 0) {
        const imageUrlList = [];
        let finalResult = [];
        for (let i = 0; i < this.state.attributeImage?.length; i++) {
          let imageUrl;
          if (this.state.attributeImage[i]?.includes("base64")) {
            imageUrl = this.uploadAttributeSelectedImage(
              i,
              this.state.attributeImage[i]
            );
          } else {
            imageUrl = this.state.attributeImage[i];
          }
          finalResult.push(imageUrl);
        }
        await Promise.allSettled(finalResult);
        finalResult &&
          finalResult.map((i) => {
            if (i) {
              imageUrlList.push({ url: i });
            } else {
              errors.push("Error Occurred in file :" + i.name);
            }
          });
        this.setState(
          {
            galleryAttributeImages: [...imageUrlList],
          },
          () => {
            this.props.updateAttibute(
              this.state.productId,
              this.state.variantCombination
            );
          }
        );
      }

      if (this.state.videos && this.state.videos.length > 0) {
        const imageUrlList = [];
        for (const [index, value] of this.state.videos.entries()) {
          const imageUrl = await this.uploadSelectedFile(
            "videos",
            "/video_gallery",
            index,
            null
          );
          if (imageUrl) {
            imageUrlList.push(imageUrl);
          } else {
            errors.push("Error Occurred in file :" + value.name);
          }
        }
        this.setState((prevState) => {
          return {
            ...prevState,
            videos: [],
            galleryVideos: [...this.state.galleryVideos, ...imageUrlList],
          };
        });
      }

      let subCatIndex = 0;
      let acsSubcategoryList = this.state.acsCatSubcategories;
      for (let subcategory of this.state.acsCatSubcategories) {
        if (subcategory.acsCatVideos && subcategory.acsCatVideos.length > 0) {
          const imageUrlList = [];
          for (const [index, value] of subcategory.acsCatVideos.entries()) {
            const imageUrl = await this.uploadSelectedFile(
              "acsCatVideos",
              "/access/video",
              index,
              subCatIndex
            );
            if (imageUrl) {
              imageUrlList.push(imageUrl);
            } else {
              errors.push("Error Occurred in file :" + value.name);
            }
          }
          acsSubcategoryList[subCatIndex].acsCatVideos = [];
          acsSubcategoryList[subCatIndex].acsCatVideoGallery = [
            ...this.state.acsCatSubcategories[subCatIndex][
              "acsCatVideoGallery"
            ],
            ...imageUrlList,
          ];
        }

        if (subcategory.acsCatAudios && subcategory.acsCatAudios.length > 0) {
          const imageUrlList = [];
          for (const [index, value] of subcategory.acsCatAudios.entries()) {
            const imageUrl = await this.uploadSelectedFile(
              "acsCatAudios",
              "/access/audio",
              index,
              subCatIndex
            );
            if (imageUrl) {
              imageUrlList.push(imageUrl);
            } else {
              errors.push("Error Occurred in file :" + value.name);
            }
          }
          acsSubcategoryList[subCatIndex].acsCatAudios = [];
          acsSubcategoryList[subCatIndex].acsCatAudioGallery = [
            ...this.state.acsCatSubcategories[subCatIndex][
              "acsCatAudioGallery"
            ],
            ...imageUrlList,
          ];
        }

        if (subcategory.acsCatImages && subcategory.acsCatImages.length > 0) {
          const imageUrlList = [];
          for (const [index, value] of subcategory.acsCatImages.entries()) {
            const imageUrl = await this.uploadSelectedFile(
              "acsCatImages",
              "/access/image",
              index,
              subCatIndex
            );
            if (imageUrl) {
              imageUrlList.push(imageUrl);
            } else {
              errors.push("Error Occurred in file :" + value.name);
            }
          }
          acsSubcategoryList[subCatIndex].acsCatImages = [];
          acsSubcategoryList[subCatIndex].acsCatImageGallery = [
            ...this.state.acsCatSubcategories[subCatIndex][
              "acsCatImageGallery"
            ],
            ...imageUrlList,
          ];
        }
        if (
          subcategory.acsCatDocuments &&
          subcategory.acsCatDocuments.length > 0
        ) {
          const imageUrlList = [];
          for (const [index, value] of subcategory.acsCatDocuments.entries()) {
            const imageUrl = await this.uploadSelectedFile(
              "acsCatDocuments",
              "/access/doc",
              index,
              subCatIndex
            );
            if (imageUrl) {
              imageUrlList.push(imageUrl);
            } else {
              errors.push("Error Occurred in file :" + value.name);
            }
          }
          acsSubcategoryList[subCatIndex].acsCatDocuments = [];
          acsSubcategoryList[subCatIndex].acsCatDocGallery = [
            ...this.state.acsCatSubcategories[subCatIndex]["acsCatDocGallery"],
            ...imageUrlList,
          ];
        }
        ++subCatIndex;
      }
      this.setState({
        acsCatSubcategories: acsSubcategoryList,
      });

      if (errors && errors.length > 0) {
        reject({ isError: true, errorMessages: errors.join("\n") });
      }
      if (
        this.state.fileResponse &&
        this.state.fileResponse?.response?.response?.status === 413
      ) {
        this.setState({
          errorData: {
            ...this.state.errorData,
            largeFileError: "You can not upload more than 20mb file size",
          },
        });
      } else {
        resolve({ isError: false });
      }
    });
  };

  uploadSelectedFile = async (
    type,
    fileUploadPathEndPoint,
    index,
    subCatIndex
  ) => {
    const files =
      subCatIndex === null
        ? this.state.videos
        : this.state.acsCatSubcategories[subCatIndex][type];
    const file = files[index];
    if (file) {
      file["status"] = "Uploading";
      this.props.addFiles(type, files);
      const res = await this.props.uploadFiles(
        "/api/v1/admin/vendors/product/upload",
        null,
        null,
        type,
        index,
        {
          productId: this.state.productId,
          file: file,
          fileUploadPathEndPoint: fileUploadPathEndPoint,
          fileName:
            subCatIndex === null
              ? ""
              : this.state?.acsCatSubcategories[subCatIndex][type + "Titles"] &&
                this.state?.acsCatSubcategories[subCatIndex][type + "Titles"][
                  index
                ].trim(),
        }
      );
      this.setState({
        fileResponse: res,
      });
      if (!res.isError && res.data && res.data.imageUrl) {
        file["status"] = "Uploaded";
        this.props.addFiles(type, files);
        return res.data.imageUrl;
      } else {
        file["status"] = "Error Occurred";
        this.props.addFiles(type, files);
        return false;
      }
    }
  };

  getFileNameFromUrl = (fileUrl) => {
    if (!fileUrl) {
      return "";
    }
    return fileUrl.split("?")[0].substring(fileUrl.lastIndexOf("/") + 1);
  };

  getFileSizeByByteLength = (byteLength) => {
    let size = 0;
    let unit = "Bytes";
    if (Math.round(byteLength) < 1000) {
      size = Math.round(byteLength);
      unit = "Bytes";
    } else if (Math.round(byteLength) > 999 && byteLength < 1000000) {
      size = Math.round(byteLength / 1000);
      unit = "KB";
    } else if (Math.round(byteLength) > 999999 && byteLength < 1000000000) {
      size = Math.round(byteLength / 1000000);
      unit = "MB";
    }
    return size + " " + unit;
  };

  getFileSizeByUrl = (fileUrl) => {
    if (!fileUrl) {
      return "";
    }
    const urlQueries = fileUrl.split("?")[1].split("&");
    const queriesMap = {};
    for (const query of urlQueries) {
      const queryList = query.split("=");
      queriesMap[queryList[0]] = queryList[1];
    }

    return this.getFileSizeByByteLength(queriesMap.size);
  };

  makePrivateUrl = (url) => {
    const azureSasToken = process.env.REACT_APP_AZURE_SAS;
    return url + (url.includes("?") ? "&" : "?") + azureSasToken;
  };

  downloadFileByUrl = async (e, item) => {
    const fileName = this.getFileNameFromUrl(item);

    if (item) {
      // start the fetch
      fetch(this.makePrivateUrl(item), {
        method: "GET",
        headers: {},
      })
        .then(async (response) => {
          // obtain a reader
          const reader = response.body.getReader();

          // get total length
          const contentLength = +response.headers.get("Content-Length");

          // read the data
          let receivedLength = 0; // received that many bytes at the moment
          let chunks = []; // array of received binary chunks (comprises the body)
          while (true) {
            const { done, value } = await reader.read();
            if (done) {
              break;
            }
            chunks.push(value);
            receivedLength += value.length;

            const receivedFileSize =
              this.getFileSizeByByteLength(receivedLength);

            let downloadProcessMap = this.state.downloadProcessMap[item];

            if (!downloadProcessMap) {
              this.state.downloadProcessMap[item] = {};
            }
            this.state.downloadProcessMap[item]["downloadCompletedSize"] =
              receivedFileSize;
            this.state.downloadProcessMap[item]["downloadPercentage"] =
              Math.round((receivedLength / contentLength) * 100);

            this.setState({
              downloadProcessMap: this.state.downloadProcessMap,
            });
          }

          // concatenate chunks into single Uint8Array
          let chunksAll = new Uint8Array(receivedLength);
          let position = 0;
          for (let chunk of chunks) {
            chunksAll.set(chunk, position);
            position += chunk.length;
          }

          // download file with chunksAll
          const url = window.URL.createObjectURL(new Blob([chunksAll]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  addNewAcsSubcategory = () => {
    let newAcsArray = this.state.acsCatSubcategories.concat({
      acsCatTitle: "",
      acsCatVideoGallery: [],
      acsCatVideoGalleryNames: [],
      removedAcsCatVideoGallery: [],
      acsCatVideos: [],
      acsCatVideosTitles: [],
      acsCatAudioGallery: [],
      acsCatAudioGalleryTitles: [],
      removedAcsCatAudioGallery: [],
      acsCatAudios: [],
      acsCatAudiosTitles: [],
      acsCatImageGallery: [],
      acsCatImageGalleryTitles: [],
      removedAcsCatImageGallery: [],
      acsCatImages: [],
      acsCatImagesTitles: [],
      acsCatDocGallery: [],
      acsCatDocGalleryTitles: [],
      removedAcsCatDocGallery: [],
      acsCatDocuments: [],
      acsCatDocumentsTitles: [],
    });

    this.setState({
      acsCatSubcategories: newAcsArray,
      setCategoryAddedMessage: "New subcategory added successfully",
    });
    setTimeout(() => {
      this.setState({
        setCategoryAddedMessage: "",
      });
    }, 5000);
  };

  removeAcsSubcategory = (index) => {
    let newCategory = this.state.acsCatSubcategories;
    newCategory.splice(index, 1);
    this.setState({
      acsCatSubcategories: [...newCategory],
    });
  };

  handleChangeMeetingDetails = (e, key) => {
    let { value } = e.target;
    this.setState({
      [key]: value,
    });
    if (key === "meetingDate") {
      if (
        new Date(e.target.value).setHours(0, 0, 0, 0) <
        new Date().setHours(0, 0, 0, 0)
      ) {
        this.setState({
          errorData: {
            meetingDateError: "Please enter future date.",
          },
        });
      } else {
        this.setState({
          errorData: {
            meetingDate: "",
          },
        });
      }
    }
  };

  modal = () => (
    <Modal
      show={this.state.showHide}
      onHide={this.handleModalShowHide}
      className="calendar-modal"
      centered
    >
      <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
        <p>
          <strong>SET MEETING</strong>
        </p>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <>
            <div className="col-md-12">
              <span className="form-field-error">
                {this.state.errorData.meetingDate}
              </span>
              <div className="form-group">
                <label>
                  <b>DATE</b>
                </label>
                <input
                  type="date"
                  max="2222-12-31"
                  required
                  format="yyyy-mm-dd"
                  value={this.state.meetingDate}
                  onChange={(e) =>
                    this.handleChangeMeetingDetails(e, "meetingDate")
                  }
                  className="form-control"
                />
                <span className="form-field-error">
                  {this.state.errorData.meetingDateError}
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  <b>START TIME</b>
                </label>
                <input
                  type="time"
                  name="start"
                  min="09:00"
                  max="18:00"
                  required
                  value={this.state.meetingStartTime}
                  onChange={(e) =>
                    this.handleChangeMeetingDetails(e, "meetingStartTime")
                  }
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>
                  <b>END TIME</b>
                </label>
                <input
                  type="time"
                  name="end"
                  min="09:00"
                  max="18:00"
                  value={this.state.meetingEndTime}
                  onChange={(e) =>
                    this.handleChangeMeetingDetails(e, "meetingEndTime")
                  }
                  required
                  className="form-control"
                />
              </div>
            </div>
          </>
        </div>
        <div className="button-center">
          <button
            onClick={() => this.handleCreateEvent()}
            className="orange-btn"
          >
            Next
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
  onValidateDateForm = () => {
    let isMeetingFormValid = true;
    let splitStarttime = this.state.meetingStartTime.split(":");
    let splitEndtime = this.state.meetingEndTime.split(":");
    let meetingStartTimeStamp = new Date(this.state.meetingDate).setHours(
      splitStarttime[0],
      splitStarttime[1]
    );
    let meetingEndTimeStamp = new Date(this.state.meetingDate).setHours(
      splitEndtime[0],
      splitEndtime[1]
    );
    if (
      !this.state.meetingDate ||
      this.state.meetingStartTime === "" ||
      this.state.meetingEndTime === ""
    ) {
      isMeetingFormValid = false;
      this.setState({
        errorData: {
          meetingDate: "Please enter meeting time.",
        },
      });
    }
    if (
      this.state.errorData.meetingDateError !== undefined ||
      this.state.errorData.meetingDateError === "Please enter future date."
    ) {
      isMeetingFormValid = false;
    }
    if (meetingStartTimeStamp >= meetingEndTimeStamp) {
      isMeetingFormValid = false;
      this.setState({
        errorData: {
          meetingDate: "Please select the start time and end time correctly.",
        },
      });
    }

    return isMeetingFormValid;
  };

  handleCreateEvent = async () => {
    const valid = this.onValidateDateForm();
    if (valid) {
      if (this.state.isEditMeeting) {
        const {
          meetingDate,
          meetingStartTime,
          meetingEndTime,
          productId,
          meetingId,
        } = this.state;
        this.setState({
          showHide: false,
        });
        await this.props.updateGroupInviteMeeting({
          meetingDate,
          meetingStartTime,
          meetingEndTime,
          productId,
          meetingId,
        });
        this.fetchMeetingList();
        return;
      }

      this.setState({
        hasMore: true,
        errorData: {
          meetingDate: "",
        },
      });

      const activeSubscriptionList =
        await this.props.getActiveSubscriptionCustomerListByProudctId(
          this.state.productId,
          0
        );
      if (activeSubscriptionList && activeSubscriptionList.data?.length > 0) {
        this.setState((prevState) => {
          return {
            ...prevState,
            prevOffset: prevState.offset,
            offset: prevState.offset + 2,
            activeSubscriptionList: prevState.activeSubscriptionList.concat(
              activeSubscriptionList.data
            ),
          };
        });
      } else {
        this.setState({ hasMore: false });
      }
      if (activeSubscriptionList && activeSubscriptionList.data?.length < 2) {
        this.setState({ hasMore: false });
      }
      this.setState({
        [`showActiveListModal`]: true,
      });
      this.setState({
        showHide: false,
      });
    }
  };

  handleSendInvite = async () => {
    const { meetingDate, meetingStartTime, meetingEndTime, productId } =
      this.state;
    let selectedCustomerList = this.state.activeSubscriptionList.filter(
      (item) => item.isChecked === true
    );
    let invitationRequestResponse = await this.props.sendInvitationRequest({
      meetingDate,
      meetingStartTime,
      meetingEndTime,
      seller_id: this.userId,
      productId: productId,
      seller_timezone: this.timezone,
      CustomerList: selectedCustomerList,
      meeting_link: "",
    });
    if (
      invitationRequestResponse &&
      invitationRequestResponse.data.isError === false
    ) {
      this.handleActiveListModalShowHide();
    } else {
      this.setState({
        errorData: {
          setMeetingError: "Meeting is already scheduled",
        },
      });
    }
  };

  handleActiveListModalShowHide = () => {
    this.setState({
      showActiveListModal: false,
      errorData: {
        meetingDate: "",
      },
      meetingStartTime: "",
      meetingDate: moment().format("YYYY-MM-DD"),
      meetingEndTime: "",
      activeSubscriptionList: [],
    });
  };

  onLoadMore = async () => {
    if (this.state.hasMore === true) {
      const activeSubscriptionList =
        await this.props.getActiveSubscriptionCustomerListByProudctId(
          this.state.productId,
          this.state.offset
        );
      if (activeSubscriptionList && activeSubscriptionList.data?.length > 0) {
        this.setState((prevState) => {
          return {
            ...prevState,
            prevOffset: prevState.offset,
            offset: prevState.offset + 2,
            hasMore: true,
            activeSubscriptionList: prevState.activeSubscriptionList.concat(
              activeSubscriptionList.data
            ),
          };
        });
      } else {
        this.setState({ hasMore: false });
      }
      if (activeSubscriptionList && activeSubscriptionList.data?.length < 2) {
        this.setState({ hasMore: false });
      }
    }
  };
  handleSelectCustomer = () => {};
  handleSelectAllCustomers = async (event) => {
    let customerList = this.state.activeSubscriptionList;
    customerList.forEach((item) => {
      item.isChecked = event.target.checked;
    });
    this.setState({ activeSubscriptionList: customerList });
  };

  handleSelectActiveCustomer = (event, customer) => {
    let customerList = this.state.activeSubscriptionList;
    customerList.forEach((item) => {
      if (item.id === customer.id) item.isChecked = event.target.checked;
    });
    this.setState({ activeSubscriptionList: customerList });
  };

  setActiveSubscriptionCustomerListModal = () => (
    <Modal
      show={this.state.showActiveListModal}
      onHide={this.handleActiveListModalShowHide}
      className="calendar-modal"
      centered
    >
      <Modal.Header
        closeButton
        onClick={() => this.handleActiveListModalShowHide()}
      >
        <p>SCHEDULE MEETING</p>
      </Modal.Header>
      <Modal.Body>
        <p className="meeting-p">Meeting Attandees:</p>
        <div className="row">
          <div className="col-md-12">
            <span className="form-field-error">
              {this.state.errorData.setMeetingError}
            </span>
            <div className="schedule-show-b active">
              <div className="schedule-check">
                <input
                  type="checkbox"
                  onClick={(event) =>
                    this.handleSelectAllCustomers(
                      event,
                      this.props.activeSubscriptionMemberList
                    )
                  }
                  value="checkedall"
                />
                <p>Customer Name</p>
              </div>
              <p>Email Address</p>
            </div>
            <div className="scroll-infinite">
              {this.state.activeSubscriptionList.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={this.handleSelectCustomer}
                    className="schedule-show-b"
                  >
                    <div className="schedule-check">
                      <input
                        onClick={(event) =>
                          this.handleSelectActiveCustomer(event, item)
                        }
                        type="checkbox"
                        checked={item.isChecked}
                      />{" "}
                      <p>
                        {item.firstname} {item.lastname}
                      </p>
                    </div>
                    <p>{item.email}</p>
                  </div>
                );
              })}
            </div>
            {this.state.hasMore === true && (
              <div className="text-right">
                <p className="load-button" onClick={() => this.onLoadMore()}>
                  Load more
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="button-center">
          <button
            onClick={() => this.handleSendInvite()}
            className="orange-btn"
          >
            Share Link
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );

  handleModalShowHide = () => {
    this.setState({
      showHide: false,
      errorData: {
        meetingDate: "",
      },
      meetingStartTime: "",
      meetingDate: moment().format("YYYY-MM-DD"),
      meetingEndTime: "",
      activeSubscriptionList: [],
    });
  };

  fetchMeetingList = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const selectedDay = new Date();
    const startOfWeekTime = moment(selectedDay)
      .startOf("week")
      .format("YYYY-MM-DD HH:mm:ss");
    const endOfWeekTime = moment(selectedDay)
      .endOf("week")
      .format("YYYY-MM-DD HH:mm:ss");
    this.props.getMeetingsByProductIdAndSellerId(
      this.userId,
      this.state.productId,
      startOfWeekTime,
      endOfWeekTime,
      timezone
    );
  };

  fetchSearchCategoryList = async (parentIndex) => {
    const responseCats = await this.props.getChildCategories(
      this.state.searchCategoryId.length
        ? this.state.searchCategoryId[parentIndex]
        : 0,
      this.state.isSeeAllChecked,
      this.state.selectedParentProductCategoryId
    );

    if (parentIndex !== undefined) {
      let searchCategoryChildOptions = [
        ...this.state.searchCategoryChildOptions,
      ];
      searchCategoryChildOptions[parentIndex] = responseCats?.data;
      if (responseCats && !responseCats.isError) {
        this.setState({
          searchCategoryChildOptions,
          searchCategoryValue: [],
        });
      }
    }
    let searchCategoryParents = [...this.state.searchCategoryParents];
    searchCategoryParents.map((item, index) => {
      if (item.length === 0 && parentIndex === undefined) {
        let searchCategoryChildOptions = [
          ...this.state.searchCategoryChildOptions,
        ];
        searchCategoryChildOptions[index] = responseCats?.data;
        if (responseCats && !responseCats.isError) {
          this.setState({
            searchCategoryChildOptions,
            searchCategoryValue: [],
          });
        }
      }
    });
  };

  fetchSearchHierarchyList = async () => {
    if (!this.state.searchCategoryId) {
      return null;
    }
    const response = await this.props.hierarchyList([
      this.state.searchCategoryId,
    ]);
    if (response && Object.keys(response.data).length !== 0) {
      let tempArray = [];
      Object.entries(response.data).map((item) => {
        tempArray.push(item[1]);
      });
      this.setState({
        searchCategoryParents: tempArray,
      });
    }
  };

  handleSearchCategoryChange = (e, parentIndex) => {
    let newCatId = [];
    const { value } = e.target;
    const category = this.state.searchCategoryChildOptions[parentIndex].filter(
      (cat) => cat.id === value
    )[0];

    const searchCategoryParents = [...this.state.searchCategoryParents];
    searchCategoryParents[parentIndex].push(category);
    searchCategoryParents.map((item) => {
      newCatId.push(item[item.length - 1]?.id);
    });

    this.setState(
      {
        searchCategoryId: newCatId,
        searchCategoryParents,
      },
      () => this.fetchSearchCategoryList(parentIndex)
    );
  };

  removeSearchCategory = (category, index, parentIndex) => {
    let newCatId = [];
    let searchCategoryParents = [...this.state.searchCategoryParents];
    let searchCategoryChildOptions = [...this.state.searchCategoryChildOptions];

    const deleteParentCount = searchCategoryParents[parentIndex].length - index;
    searchCategoryParents[parentIndex].splice(index, deleteParentCount);

    searchCategoryParents.map((item) => {
      newCatId.push(item[item.length - 1]?.id);
    });
    this.setState(
      {
        searchCategoryParents,
        searchCategoryChildOptions,
        searchCategoryId: newCatId,
      },
      () => this.fetchSearchCategoryList(parentIndex)
    );
  };

  handleDeleteSearchCategory = async (parentIndex) => {
    let newCatId = [];
    const searchCategoryParents = [...this.state.searchCategoryParents];
    let searchCategoryChildOptions = [...this.state.searchCategoryChildOptions];
    searchCategoryParents.splice(parentIndex, 1);
    searchCategoryChildOptions.splice(parentIndex, 1);
    searchCategoryParents.map((item) => {
      newCatId.push(item[item.length - 1]?.id);
    });
    this.setState({
      searchCategoryParents,
      searchCategoryChildOptions,
      searchCategoryId: newCatId,
    });
  };

  download = () => {
    axios({
      url: this.state.dobaMainImage,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.jpg");
      document.body.appendChild(link);
      link.click();
    });
  };

  uploadAttributeImage = (e) => {
    e.preventDefault();
    let index = this.state.uploadAttributeIndex;
    const file = e.target.files[0];
    if (file?.size > 10000000) {
      this.setState({
        errorData: {
          ...this.state.errorData,
          largeFileError: "You can not upload images more than 10mb file size",
        },
      });
      return;
    }
    const fileReader = new FileReader();
    //Implement onLoad function
    fileReader.onloadend = (event) => {
      const variantCombination = this.state.variantCombination;
      variantCombination[index][1] = {
        ...variantCombination[index][1],
      };
      let tempAttributeImage = [...this.state.attributeImage];
      tempAttributeImage[index] = fileReader.result;
      this.setState({
        variantCombination,
        attributeImage: tempAttributeImage,
      });
    };
    //Start reading the file
    fileReader.readAsDataURL(file);
  };

  handleSeeAllSearchCategory = (e) => {
    this.setState(
      {
        isSeeAllChecked: e.target.checked,
      },
      () => {
        this.fetchSearchCategoryList();
      }
    );
  };

  handleIsDigital = (e) => {
    this.setState({
      isDigitalChecked: e.target.checked,
    });
  };

  handleIsOneOnOneMeeting = (e) => {
    this.setState({
      isOneOnOneMeetingChecked: e.target.checked,
      isOneOnOneMeetingDetailsEdited: true,
    });
  };

  handleAddSearchCat = async () => {
    const searchCategoryParents = [...this.state.searchCategoryParents];
    searchCategoryParents.push([]);
    let searchCategoryChildOptions = [...this.state.searchCategoryChildOptions];
    const responseCats = await this.props.getChildCategories(
      0,
      this.state.isSeeAllChecked,
      this.state.selectedParentProductCategoryId
    );
    if (responseCats && !responseCats.isError) {
      searchCategoryChildOptions.push(responseCats.data);
    }
    this.setState({
      searchCategoryParents,
      searchCategoryChildOptions,
    });
  };

  handleDeleteCustomizeQuestion = async (index) => {
    let tempCustomizeQuesArray = [...this.state.customizeQuestions];
    tempCustomizeQuesArray.splice(index, 1);
    this.setState({
      customizeQuestions: [...tempCustomizeQuesArray],
    });
  };

  handleAddCustomizeQuestion = async () => {
    let tempCustomizeQuesArray = [...this.state.customizeQuestions];
    tempCustomizeQuesArray.push("");
    this.setState({
      customizeQuestions: [...tempCustomizeQuesArray],
    });
  };

  handleIsGroupMeeting = (e) => {
    this.setState({
      isGroupMeetingDetailsEdited: true,
    });
    let errorData = { ...this.state.errorData };
    errorData["groupMeetingDetails"] = [];
    if (e.target.value) {
      this.state.groupMeetingDetails.map((item) => {
        errorData["groupMeetingDetails"].push({
          meetingName: [],
          subscriptionCost: [],
          sessionHour: [],
          sessionMinute: [],
          meetingMonthlyStartTime: [],
          meetingMonthlyEndTime: [],
          meetingMonthlyWeekDay: [],
          meetingMonthlyWeekOfMonth: [],
          groupMeetingFrequency: [],
          isWeekDaySelected: [],
          groupMeetingSlots: {
            Sun: [],
            Mon: [],
            Tue: [],
            Wed: [],
            Thu: [],
            Fri: [],
            Sat: [],
          },
        });
      });

      this.setState({
        isGroupMeetingChecked: e.target.checked,
        errorData,
      });
    } else {
      this.setState({
        errorData: {},
        isGroupMeetingChecked: e.target.checked,
      });
    }
  };

  getGroupAndOneOnOneMeetingDetails = async (productId) => {
    const res = await this.props.getGroupAndOneOnOneMeetingDetails(productId);

    let oneOnOneMeetingSlots = { ...this.state.oneOnOneMeetingSlots };
    let oneOnOneSelectedWeekDays = { ...this.state.isWeekDaySelected };
    let selectedFrequency = { ...this.state.selectedFrequency };
    let oneOnOneTrialCost = 0;
    let oneOnOneSubscriptionCost = 0;
    let oneOneOneSessionHour = "00";
    let oneOneOneSessionMinute = "00";
    let id = null;

    let groupMeetingDetails = [];

    if (res?.data?.data?.length) {
      const groupOneOnOneMeetingData = res.data.data;

      let errorData = { ...this.state.errorData };

      groupOneOnOneMeetingData.map((data) => {
        if (data.type === "group") {
          if (!!!errorData["groupMeetingDetails"]) {
            errorData["groupMeetingDetails"] = [
              {
                meetingName: [],
                subscriptionCost: [],
                sessionHour: [],
                sessionMinute: [],
                meetingMonthlyStartTime: [],
                meetingMonthlyEndTime: [],
                meetingMonthlyWeekDay: [],
                meetingMonthlyWeekOfMonth: [],
                groupMeetingFrequency: [],
                isWeekDaySelected: [],
                groupMeetingSlots: [],
              },
            ];
          } else {
            errorData["groupMeetingDetails"].push({
              meetingName: [],
              subscriptionCost: [],
              sessionHour: [],
              sessionMinute: [],
              meetingMonthlyStartTime: [],
              meetingMonthlyEndTime: [],
              meetingMonthlyWeekDay: [],
              meetingMonthlyWeekOfMonth: [],
              groupMeetingFrequency: [],
              isWeekDaySelected: [],
              groupMeetingSlots: [],
            });
          }
        }
      });

      this.setState({
        errorData,
      });

      groupOneOnOneMeetingData.map((item) => {
        if (item.type === "one_on_one") {
          if (item.status === "enable") {
            this.setState({
              isOneOnOneMeetingChecked: true,
            });
          }
          let meetingSlot = item.availability
            ? JSON.parse(item.availability)
            : null;
          let sessionDuration = item.session_duration
            ? JSON.parse(item.session_duration)
            : null;

          if (meetingSlot) {
            oneOnOneMeetingSlots = { ...meetingSlot };
            Object.entries(oneOnOneMeetingSlots).map(([weekDay, timeSlot]) => {
              oneOnOneSelectedWeekDays[weekDay] = true;
            });
          }
          if (sessionDuration) {
            oneOneOneSessionHour = sessionDuration.sessionHour
              ? sessionDuration.sessionHour
              : "";
            oneOneOneSessionMinute = sessionDuration.sessionMinute
              ? sessionDuration.sessionMinute
              : "";
          }

          let oneOnOneFreq = item.frequency.split(",");
          oneOnOneFreq.map((frequency) => {
            selectedFrequency[frequency] = true;
          });

          oneOnOneTrialCost = item.one_time_cost;
          oneOnOneSubscriptionCost = item.subscription_cost;
          id = item.id;
        } else if (item.type === "group") {
          if (item.status === "enable") {
            this.setState({
              isGroupMeetingChecked: true,
            });
          }

          let tempGroupMeetingObj = {
            id: null,
            meetingName: "",
            subscriptionCost: 0,
            sessionHour: "00",
            sessionMinute: "00",
            meetingMonthlyStartTime: "",
            meetingMonthlyEndTime: "",
            meetingMonthlyWeekDay: "",
            meetingMonthlyWeekOfMonth: "",
            groupMeetingFrequency: "",
            isMonthlyGroupFrequencyChecked: false,
            isWeekDaySelected: {
              Sun: false,
              Mon: false,
              Tue: false,
              Wed: false,
              Thu: false,
              Fri: false,
              Sat: false,
            },
            groupMeetingSlots: {},
          };

          let groupSessionDuration = item.session_duration
            ? JSON.parse(item.session_duration)
            : null;
          let groupMeetingSlots = item.availability
            ? JSON.parse(item.availability)
            : null;
          tempGroupMeetingObj["id"] = item.id;
          tempGroupMeetingObj["meetingName"] = item.group_meeting_name;
          tempGroupMeetingObj["subscriptionCost"] = item.subscription_cost;
          tempGroupMeetingObj["sessionHour"] = groupSessionDuration.sessionHour;
          tempGroupMeetingObj["sessionMinute"] =
            groupSessionDuration.sessionMinute;
          tempGroupMeetingObj["groupMeetingFrequency"] = item.frequency;
          tempGroupMeetingObj["groupMeetingSlots"] = { ...groupMeetingSlots };

          if (item.frequency !== "Monthly") {
            tempGroupMeetingObj["isMonthlyGroupFrequencyChecked"] = false;

            Object.entries(groupMeetingSlots).map(([weekDay, timeSlot]) => {
              tempGroupMeetingObj["isWeekDaySelected"][weekDay] = true;
            });
            groupMeetingDetails.push({ ...tempGroupMeetingObj });
          } else {
            tempGroupMeetingObj["meetingMonthlyStartTime"] =
              groupMeetingSlots.startTime;
            tempGroupMeetingObj["meetingMonthlyEndTime"] =
              groupMeetingSlots.endTime;
            tempGroupMeetingObj["meetingMonthlyWeekOfMonth"] =
              groupMeetingSlots.weekOfMonth;
            tempGroupMeetingObj["meetingMonthlyWeekDay"] =
              groupMeetingSlots.weekDay;
            tempGroupMeetingObj["isMonthlyGroupFrequencyChecked"] = true;
            groupMeetingDetails.push({ ...tempGroupMeetingObj });
          }
        }
      });
    }
    this.setState({
      oneOnOneId: id,
      oneOnOneMeetingSlots: oneOnOneMeetingSlots,
      isWeekDaySelected: oneOnOneSelectedWeekDays,
      selectedFrequency: selectedFrequency,
      trialCost: oneOnOneTrialCost,
      subscriptionCost: oneOnOneSubscriptionCost,
      sessionHour: oneOneOneSessionHour,
      sessionMinute: oneOneOneSessionMinute,
      groupMeetingDetails: groupMeetingDetails.length
        ? [...groupMeetingDetails]
        : [...this.state.groupMeetingDetails],
    });
  };

  handleGroupMeetingDetailsChange = (e, key, index) => {
    this.setState({
      isGroupMeetingDetailsEdited: true,
    });
    let tempGroupMeetingDetails = [...this.state.groupMeetingDetails];
    let errorData = { ...this.state.errorData };
    if (key === "meetingMonthlyStartTime" || key === "meetingMonthlyEndTime") {
      tempGroupMeetingDetails[index][key] = e;
      errorData["groupMeetingDetails"][index][key] = [];
      this.setState({
        groupMeetingDetails: [...tempGroupMeetingDetails],
        errorData: { ...errorData },
      });
      return;
    }
    tempGroupMeetingDetails[index][key] = e.target.value;
    errorData["groupMeetingDetails"][index][key] = [];
    this.setState({
      groupMeetingDetails: [...tempGroupMeetingDetails],
      errorData: { ...errorData },
    });
  };

  handleGroupMeetingFrequencyChange = (e, freq, index) => {
    this.setState({
      isGroupMeetingDetailsEdited: true,
    });
    let tempGroupMeetingDetails = [...this.state.groupMeetingDetails];
    let errorData = { ...this.state.errorData };
    errorData["groupMeetingDetails"][index]["groupMeetingFrequency"] = [];

    tempGroupMeetingDetails[index]["groupMeetingFrequency"] = freq;
    tempGroupMeetingDetails[index]["groupMeetingSlots"] = {};
    Object.entries(
      this.state.groupMeetingDetails[index]["isWeekDaySelected"]
    ).map(([weekDay, bool]) => {
      tempGroupMeetingDetails[index]["isWeekDaySelected"][weekDay] = false;
    });
    tempGroupMeetingDetails[index]["isMonthlyGroupFrequencyChecked"] =
      freq === "Monthly" ? true : false;
    this.setState({
      groupMeetingDetails: [...tempGroupMeetingDetails],
      errorData: { ...errorData },
    });
  };

  handleGroupMeetingMonthlyWeekDayAndWeekChange = (e, key, index) => {
    this.setState({
      isGroupMeetingDetailsEdited: true,
    });
    let tempGroupMeetingDetails = [...this.state.groupMeetingDetails];
    this.state.errorData["groupMeetingDetails"][index][key] = [];
    tempGroupMeetingDetails[index][key] = e.target.value;
    this.setState({
      groupMeetingDetails: [...tempGroupMeetingDetails],
    });
  };

  handleGroupWeeklyBiweeklySlotChange = (e, weekDay, index) => {
    this.setState({
      isGroupMeetingDetailsEdited: true,
    });
    let tempArray = [...this.state.groupMeetingDetails];
    let errorData = { ...this.state.errorData };

    errorData["groupMeetingDetails"][index]["selectedWeekDay"] = [];

    tempArray[index]["isWeekDaySelected"][weekDay] = e.target.checked;
    if (e.target.checked) {
      tempArray[index]["groupMeetingSlots"][weekDay] = [[0, 0]];
    } else {
      delete tempArray[index]["groupMeetingSlots"][weekDay];
    }
    this.setState({
      groupMeetingDetails: [...tempArray],
      errorData: { ...errorData },
    });
  };

  handleGroupMeetingTimeChange = (e, weekDay, position, type, index) => {
    this.setState({
      isGroupMeetingDetailsEdited: true,
    });
    let tempGroupMeetingDetails = [...this.state.groupMeetingDetails];
    if (type === "start") {
      tempGroupMeetingDetails[index]["groupMeetingSlots"][weekDay][
        position
      ][0] = e;
    } else {
      tempGroupMeetingDetails[index]["groupMeetingSlots"][weekDay][
        position
      ][1] = e;
    }

    this.state.errorData["groupMeetingDetails"][index]["groupMeetingSlots"] = {
      Sun: [],
      Mon: [],
      Tue: [],
      Wed: [],
      Thu: [],
      Fri: [],
      Sat: [],
    };

    this.setState({
      groupMeetingDetails: [...tempGroupMeetingDetails],
    });
  };

  handleDeleteNewGroupMeeting = (index) => {
    this.setState({
      isGroupMeetingDetailsEdited: true,
    });
    let tempGroupMeetingDetails = [...this.state.groupMeetingDetails];
    let deletedGroupMeetingsIdArray = [
      ...this.state.deletedGroupMeetingsIdArray,
    ];
    if (tempGroupMeetingDetails[index]["id"] !== null) {
      deletedGroupMeetingsIdArray.push(tempGroupMeetingDetails[index]["id"]);
    }
    tempGroupMeetingDetails.splice(index, 1);
    this.state.errorData.groupMeetingDetails.splice(index, 1);
    this.setState({
      groupMeetingDetails: [...tempGroupMeetingDetails],
      deletedGroupMeetingsIdArray,
    });
  };

  handleSessionHourMinuteTrialSubscriptionCostChange = (e, key) => {
    this.state.errorData[key] = [];
    this.setState({
      [key]: e.target.value,
      isOneOnOneMeetingDetailsEdited: true,
    });
  };

  handleOneOnOneMeetingTimeChange = (e, weekDay, index, type) => {
    this.setState({
      isOneOnOneMeetingDetailsEdited: true,
    });
    let tempOneOnOneMeetingSlots = { ...this.state.oneOnOneMeetingSlots };
    this.state.errorData.selectedOneOnOneMeetingSlots = {
      Sun: [],
      Mon: [],
      Tue: [],
      Wed: [],
      Thu: [],
      Fri: [],
      Sat: [],
    };
    if (type === "start") {
      tempOneOnOneMeetingSlots[weekDay][index][0] = e;
    } else {
      tempOneOnOneMeetingSlots[weekDay][index][1] = e;
    }

    this.setState({
      oneOnOneMeetingSlots: { ...tempOneOnOneMeetingSlots },
    });
  };

  render() {
    const { crop, croppedImageUrl, src } = this.state;

    const weightOption = Array.from(Array(70).keys());
    const sizeOption = Array.from(Array(100).keys());
    if (!sizeOption.includes(this.state.productHeight)) {
      sizeOption.push(this.state.productHeight);
    }
    if (!sizeOption.includes(this.state.productHeight)) {
      sizeOption.push(this.state.productHeight);
    }
    if (!sizeOption.includes(this.state.productWidth)) {
      sizeOption.push(this.state.productWidth);
    }
    if (!sizeOption.includes(this.state.productLength)) {
      sizeOption.push(this.state.productLength);
    }
    sizeOption.sort();
    if (!weightOption.includes(this.state.productWeight)) {
      weightOption.push(this.state.productWeight);
      weightOption.sort(function (a, b) {
        return a - b;
      });
    }

    return (
      <div class="admin-card">
        <div class="admin-card-head">
          <h2>edit product</h2>
        </div>
        <div class="admin-card-box">
          <input
            type="file"
            hidden
            multiple
            ref={this.fileUploadVideo}
            accept={this.fileFormats.video.map((item) => "." + item).join(",")}
            onClick={(event) => {
              event.target.value = null;
            }}
            onChange={this.fileSelectHandler}
          />
          <input
            type="file"
            hidden
            multiple
            ref={this.fileUploadAudio}
            accept={this.fileFormats.audio.map((item) => "." + item).join(",")}
            onClick={(event) => {
              event.target.value = null;
            }}
            onChange={this.fileSelectHandler}
          />
          <input
            type="file"
            hidden
            multiple
            ref={this.fileUploadImage}
            accept={this.fileFormats.image.map((item) => "." + item).join(",")}
            onClick={(event) => {
              event.target.value = null;
            }}
            onChange={this.fileSelectHandler}
          />
          <input
            type="file"
            hidden
            multiple
            ref={this.fileUploadDoc}
            accept={this.fileFormats.doc.map((item) => "." + item).join(",")}
            onClick={(event) => {
              event.target.value = null;
            }}
            onChange={this.fileSelectHandler}
          />
          <input
            hidden
            type="file"
            ref={this.fileUploadAttribute}
            accept="image/*"
            onChange={(e) => this.uploadAttributeImage(e)}
            onClick={(event) => {
              event.target.value = null;
            }}
          />
          <input
            hidden
            type="file"
            ref={this.uploadGlobalImage}
            accept="image/*"
            onChange={(e) =>
              this.uploadGlobalAttributes(
                e,
                "globalImage",
                this.state.attibuteGlobalValue,
                this.state.attibuteGlobalIndex
              )
            }
            onClick={(event) => {
              event.target.value = null;
            }}
          />
          {(() => {
            if (
              this.state.successMessage !== "" &&
              this.state.successMessage !== undefined
            ) {
              return (
                <div className="alert alert-success">
                  {this.state.successMessage}
                </div>
              );
            } else if (
              this.state.errorMessage !== "" &&
              this.state.errorMessage !== undefined
            ) {
              return (
                <div className="alert alert-danger">
                  {this.state.errorMessage}
                </div>
              );
            }
          })()}

          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <label className="custom-label-product pb-2">
                  {this.state.isAccessCategoryInCategories
                    ? `company name`
                    : `product title`}
                  <sup>*</sup>
                  <div className="tooltip-custom">
                    <i
                      className="fa fa-info-circle info-icon"
                      aria-hidden="true"
                    ></i>
                    <div className="together-tooltip">
                      <p>
                        {this.state.isAccessCategoryInCategories
                          ? `Enter The Company Name`
                          : `Enter The Product Title`}
                        <span class="arrow-down"></span>
                      </p>
                    </div>
                  </div>
                </label>
                <input
                  type="text"
                  onChange={(e) => this.handleChange(e, "productTitle")}
                  value={this.state.productTitle}
                  className="form-control"
                />
                <span className="form-field-error">
                  {this.state.errorData?.productTitle &&
                    this.state.errorData.productTitle[0]}
                </span>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="form-group slot-line">
                {this.modal()}
                {this.denyModal()}
                {this.setActiveSubscriptionCustomerListModal()}
              </div>
            </div>

            {!this.state.isAccessCategoryInCategories && (
              <div className="col-lg-6 col-md-6">
                <div className="form-group">
                  <label>
                    base price
                    <sup>*</sup>
                  </label>
                  <input
                    type="number"
                    onChange={(e) => this.handleChange(e, "productPrice")}
                    value={this.state.productPrice}
                    className="form-control"
                  />
                  <span className="form-field-error">
                    {this.state.errorData?.productPrice &&
                      this.state.errorData.productPrice[0]}
                  </span>
                </div>
                {this.state.dobaSellingPrice ? (
                  <div className="form-group">
                    <label>
                      Doba selling price : {this.state.dobaSellingPrice}
                    </label>
                  </div>
                ) : null}
              </div>
            )}
          </div>
          {!this.state.isAccessCategoryInCategories && (
            <label class="custom-check mt-2">
              Is Sale
              <input
                type="checkbox"
                checked={this.state.is_sale}
                onChange={(e) => this.handleChangeChk(e, "is_sale")}
                disabled={this.state.productPrice === 0}
              />
              <span class="checkmark"></span>
            </label>
          )}
          {this.state.is_sale === 1 &&
            !this.state.isAccessCategoryInCategories && (
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Price Type</label>
                    <select
                      class="entries-controls form-control"
                      value={this.state.price_type}
                      onChange={(e) => this.handleChange(e, "price_type")}
                    >
                      {/* {(this.state.withAttribute === 0 ||
                        (this.state.isDobaProduct &&
                          this.state.variantCombination === 1)) && (
                          )} */}

                      <option value={"1"}>Fixed</option>
                      <option value={"2"}>Percentage</option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label>
                      sale price
                      <sup>*</sup>
                    </label>
                    <input
                      type="number"
                      min={0}
                      onChange={(e) => this.handleChange(e, "sale_price")}
                      value={this.state.sale_price}
                      class="form-control"
                    />
                    {!isNaN(this.state.productPrice) && !!this.state.sale_price
                      ? this.state.price_type === 2
                        ? "Discounted Price : " +
                          (
                            this.state.productPrice -
                            (this.state.productPrice * this.state.sale_price) /
                              100
                          )
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "Discounted Price : " +
                          (this.state.productPrice - this.state.sale_price)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : null}
                    <span className="form-field-error">
                      {this.state.errorData?.salePrice &&
                        this.state.errorData.salePrice[0]}
                    </span>
                  </div>
                </div>
              </div>
            )}

          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="form-group">
                <div className="row">
                  <div class="col-lg-4 col-md-4">
                    <div className="product-preview-image">
                      {croppedImageUrl && this.state.isCropImage ? (
                        <img alt="Crop" src={croppedImageUrl} />
                      ) : (
                        <img
                          alt=""
                          src={
                            this.state.previewProductImage &&
                            this.state.previewProductImage !== ""
                              ? this.state.previewProductImage
                              : null
                          }
                        />
                      )}
                    </div>

                    <div className="upload-btn mt-4 d-flex align-items-center justify-content-center">
                      {this.state.dobaMainImage && (
                        <button
                          onClick={this.download}
                          type="button"
                          className="mr-2"
                        >
                          Download Doba Image
                        </button>
                      )}
                      <button
                        onClick={this.triggerClick}
                        type="button"
                        className="mr-2"
                      >
                        upload image
                      </button>
                      {src && !this.state.isCropImage && (
                        <button
                          onClick={() =>
                            this.setState({
                              isCropImage: true,
                              showCropper: true,
                            })
                          }
                          type="button"
                        >
                          crop image
                        </button>
                      )}
                    </div>
                    <div className="upload-btn text-center mt-4"></div>
                  </div>

                  <div class="col-lg-5 col-md-5">
                    <div className="crop-image-view">
                      <div style={{ display: "none" }}>
                        <input
                          ref={this.fileUpload}
                          type="file"
                          accept="image/*"
                          onChange={this.onSelectFile}
                        />
                      </div>
                      {src &&
                        this.state.isCropImage &&
                        this.state.showCropper && (
                          <ReactCrop
                            crop={crop}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                          >
                            <img src={src} onLoad={this.onImageLoaded} alt="" />
                          </ReactCrop>
                        )}
                      <div className="mt-3">
                        {this.state.isCropImage && this.state.showCropper && (
                          <>
                            <button
                              onClick={() =>
                                this.setState({
                                  showCropper: false,
                                })
                              }
                              type="button"
                              className="orange-btn mr-2"
                            >
                              Done
                            </button>
                            <button
                              onClick={() =>
                                this.setState({
                                  isCropImage: false,
                                  showCropper: false,
                                })
                              }
                              type="button"
                              className="orange-btn"
                            >
                              Cancel
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <span className="form-field-error">
                  {this.state.errorData?.productImage &&
                    this.state.errorData.productImage[0]}
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="form-group label-radio-inline">
                <label class="mr-4 d-flex align-items-center">
                  Select product time-frame
                  <div className="tooltip-custom">
                    <i
                      className="fa fa-info-circle info-icon"
                      aria-hidden="true"
                    ></i>
                    <div className="together-tooltip">
                      <p>
                        Would you like to make this a recurring option? Select
                        options
                        <span class="arrow-down"></span>
                      </p>
                    </div>
                  </div>
                </label>

                <div class="custom-radio-inline">
                  {!this.state.isAccessCategoryInCategories ? (
                    <>
                      <label class="custon-radio">
                        Weekly
                        <input
                          type="radio"
                          onChange={(e) =>
                            this.handleChange(e, "productTimeFrame")
                          }
                          onClick={() =>
                            this.state.productTimeFrame === "weekly" &&
                            this.setState((prevState) => ({
                              ...prevState,
                              productTimeFrame: "",
                            }))
                          }
                          value="weekly"
                          disabled={this.state.isDobaProduct}
                          checked={this.state.productTimeFrame === "weekly"}
                          name="radio"
                        />
                        <span class="checkmark"></span>
                      </label>
                      <label class="custon-radio">
                        Monthly
                        <input
                          type="radio"
                          onChange={(e) =>
                            this.handleChange(e, "productTimeFrame")
                          }
                          onClick={() =>
                            this.state.productTimeFrame === "monthly" &&
                            this.setState((prevState) => ({
                              ...prevState,
                              productTimeFrame: "",
                            }))
                          }
                          value="monthly"
                          disabled={this.state.isDobaProduct}
                          checked={this.state.productTimeFrame === "monthly"}
                          name="radio"
                        />
                        <span class="checkmark"></span>
                      </label>
                      <label class="custon-radio">
                        Annual
                        <input
                          type="radio"
                          onChange={(e) =>
                            this.handleChange(e, "productTimeFrame")
                          }
                          onClick={() =>
                            this.state.productTimeFrame === "annual" &&
                            this.setState((prevState) => ({
                              ...prevState,
                              productTimeFrame: "",
                            }))
                          }
                          value="annual"
                          disabled={this.state.isDobaProduct}
                          checked={this.state.productTimeFrame === "annual"}
                          name="radio"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </>
                  ) : (
                    <label className="custon-radio">
                      Monthly
                      <input
                        type="radio"
                        onChange={(e) =>
                          this.handleChange(e, "productTimeFrame")
                        }
                        onClick={() =>
                          this.state.productTimeFrame === "monthly" &&
                          this.setState((prevState) => ({
                            ...prevState,
                            productTimeFrame: "",
                          }))
                        }
                        value="monthly"
                        checked={this.state.productTimeFrame === "monthly"}
                        name="radio"
                      />
                      <span className="checkmark"></span>
                    </label>
                  )}
                  <span className="form-field-error">
                    {this.state.errorData?.productTimeFrame &&
                      this.state.errorData.productTimeFrame[0]}
                  </span>
                </div>
                {this.state.selectedParentProductCategoryId.length !== 0 &&
                  (this.state.selectedParentProductCategoryId.includes(101) ||
                    this.state.selectedParentProductCategoryId.includes(119)) &&
                  !this.state.selectedParentProductCategoryId.includes(3) && (
                    <div className="custom-radio-inline">
                      <label className="custon-radio">
                        One Time
                        <input type="radio" checked={true} />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  )}
              </div>
            </div>

            {!this.state.isAccessCategoryInCategories && (
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label>
                    Product Order limit
                    <sup>*</sup>
                  </label>
                  <select
                    name="order_limit"
                    disabled={this.state.isDobaProduct}
                    class="form-control"
                    onChange={(e) => this.handleChange(e, "order_limit")}
                  >
                    <option value="" selected disabled>
                      Select Order Limit
                    </option>
                    {[...Array(999).keys()].map((index) => (
                      <option
                        key={index}
                        selected={index + 1 === this.state.order_limit}
                        value={index + 1}
                      >
                        {index + 1}
                      </option>
                    ))}
                  </select>
                  <span className="form-field-error">
                    {this.state.errorData?.order_limit &&
                      this.state.errorData.order_limit[0]}
                  </span>
                </div>
              </div>
            )}

            {/* Parent category */}
            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <label>
                  Parent Category
                  <sup>*</sup>
                </label>
                <Select
                  value={this.state.defaultParentSelectedOption}
                  name="value"
                  options={this.state.productParentCategoryList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e, value) => {
                    this.handleAlertParentAccessCategory(e, value);
                  }}
                />

                <span className="form-field-error">
                  {this.state.errorData?.selectedParentProductCategoryId &&
                    this.state.errorData.selectedParentProductCategoryId[0]}
                </span>
                {this.state.isCustomizeCategory && (
                  <div className="form-check-inline">
                    <label className="form-check-label d-flex align-items-center">
                      <input
                        type="checkbox"
                        onClick={(e) => this.handleIsDigital(e)}
                        className="form-check-input"
                        checked={this.state.isDigitalChecked}
                      />
                      Is digital?
                    </label>
                  </div>
                )}
              </div>
            </div>

            {this.state.is_registered_on_store !== 0 && (
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label>
                    Store Category
                    <sup>*</sup>
                  </label>
                  <p className="m-0">
                    {this.state.shopifyCategoryList &&
                      this.state.shopifyCategoryList
                        .map((cate) => cate.label)
                        .join(", ")}
                  </p>
                </div>
              </div>
            )}

            {this.state.isCustomizeCategory && (
              <div className="col-md-6">
                <div className="form-group">
                  <label className="mr-4 d-flex align-items-center">
                    Product Creation Time
                    <div className="tooltip-custom">
                      <i
                        className="fa fa-info-circle info-icon"
                        aria-hidden="true"
                      ></i>
                      <div className="together-tooltip">
                        <p>
                          Time To Completion
                          <span class="arrow-down"></span>
                        </p>
                      </div>
                    </div>
                  </label>
                  <input
                    type="text"
                    onChange={(e) => this.handleProductTime(e)}
                    value={this.state.customizedProductTime}
                    className="form-control"
                  />
                </div>
              </div>
            )}

            <div className="col-lg-12 col-md-12">
              {this.state.isFreeSeminarCreated &&
                this.state.formData?.start?.length > 0 && (
                  <div className="form-group">
                    <label>
                      <b>Free one-time event</b>
                    </label>
                    <div className="seminar-info">
                      <ul>
                        <li>
                          <h3>Event Name :</h3>
                          <p>{this.state.formData.name}</p>
                        </li>
                        <li>
                          <h3>Meeting Date :</h3>
                          <p>{this.state.formData.date}</p>
                        </li>
                        <li>
                          <h3>Meeting Time :</h3>
                          <p>
                            {moment(this.state.formData.start, "hh:mm").format(
                              "hh:mm A"
                            )}{" "}
                            to{" "}
                            {moment(this.state.formData.end, "hh:mm").format(
                              "hh:mm A"
                            )}
                          </p>
                        </li>

                        {
                          <li>
                            <h3>Event Type :</h3>
                            <p>
                              {!!this.state.formData?.price ? "Paid" : "Free"}
                            </p>
                          </li>
                        }
                        {this.state?.formData?.price > 0 && (
                          <li>
                            <h3>Event Price :</h3>
                            <p>{this.state?.formData?.price}</p>
                          </li>
                        )}
                        {this.state.formData?.note?.length > 0 && (
                          <li>
                            <h3>Event Description :</h3>
                            <p>{this.state.formData.note}</p>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                )}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="form-group">
                <div className="mb-2">
                  <label className="mr-2">Search Categories</label>
                </div>
                {this.state?.searchCategoryParents.map((item, index) => (
                  <div className="border-seprate">
                    {this.state.searchCategoryParents.length > 0 && (
                      <label className="mt-2">
                        Search Category{" "}
                        {this.state.searchCategoryParents.length > 1
                          ? index + 1
                          : ""}
                      </label>
                    )}
                    <i
                      className="fa fa-trash fa-sm add-section-cursor ml-2"
                      aria-hidden="true"
                      onClick={() => this.handleDeleteSearchCategory(index)}
                    ></i>
                    <ul className="search-pills-category mt-1">
                      {item?.map((parent, pIndex) => (
                        <li key={pIndex}>
                          {parent?.name}
                          <span>
                            <i
                              className="fas fa-times"
                              onClick={() =>
                                this.removeSearchCategory(parent, pIndex, index)
                              }
                            />
                          </span>
                        </li>
                      ))}
                    </ul>
                    <select
                      placeholder="Select search category"
                      className="form-control basic-multi-select border-seprate"
                      classNamePrefix="select"
                      value={this.state.searchCategoryValue}
                      onChange={(e) =>
                        this.handleSearchCategoryChange(e, index)
                      }
                    >
                      <option value="" selected disabled>
                        Select search category
                      </option>
                      {Array.isArray(
                        this.state.searchCategoryChildOptions[index]
                      ) &&
                        this.state.searchCategoryChildOptions[index].map(
                          (category, ind) => (
                            <option value={category.id} key={ind}>
                              {category.name}
                            </option>
                          )
                        )}
                    </select>
                  </div>
                ))}
                <span
                  className="add-section-cursor"
                  onClick={() => this.handleAddSearchCat()}
                >
                  <i className="fa fa-plus-circle mr-2" aria-hidden="true"></i>
                  Add New Category
                </span>
                {this.props.meetingList?.loading && (
                  <span
                    className="spinner-border spinner-border-sm ml-2"
                    style={{ minWidth: "1rem", minHeight: "1rem" }}
                  ></span>
                )}

                {this.state.isCustomizeCategory ? (
                  <div className="form-group">
                    <div className="mb-2">
                      <label className="custom-label-product mr-2">
                        Customize Questions
                        <div className="tooltip-custom">
                          <i
                            className="fa fa-info-circle info-icon"
                            aria-hidden="true"
                          ></i>
                          <div className="together-tooltip">
                            <p>
                              Customize Questions
                              <span class="arrow-down"></span>
                            </p>
                          </div>
                        </div>
                      </label>
                    </div>

                    {this.state.customizeQuestions &&
                    this.state.customizeQuestions?.length
                      ? this.state.customizeQuestions.map((item, index) => {
                          return (
                            <>
                              <div className="border-seprate">
                                <label className="mt-2">
                                  Question{" "}
                                  {this.state.customizeQuestions.length > 1
                                    ? index + 1
                                    : ""}
                                </label>
                                <i
                                  className="fa fa-trash fa-sm add-section-cursor ml-2"
                                  aria-hidden="true"
                                  onClick={() =>
                                    this.handleDeleteCustomizeQuestion(index)
                                  }
                                ></i>
                              </div>
                              <input
                                type="text"
                                required
                                onChange={(e) =>
                                  this.handleChange(
                                    e,
                                    "customizeQuestions",
                                    index
                                  )
                                }
                                value={this.state.customizeQuestions[index]}
                                className="form-control"
                              />
                            </>
                          );
                        })
                      : null}
                    <span
                      className="add-section-cursor"
                      onClick={() => this.handleAddCustomizeQuestion()}
                    >
                      <i
                        className="fa fa-plus-circle mr-2"
                        aria-hidden="true"
                      ></i>
                      Add Question
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {this.state.isAccessCategoryInCategories && this.state.productId ? (
            <>
              <div className="form-group">
                <Calendar
                  title={this.state.productTitle}
                  productId={this.state.productId}
                  seller_id={this.vendorId}
                />
              </div>
            </>
          ) : null}

          {!this.state.isAccessCategoryInCategories &&
            !this.state.isDigitalChecked && (
              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label>
                      product package height (in inches)
                      <sup>*</sup>
                    </label>
                    <select
                      name="productHeight"
                      class="form-control"
                      onChange={(e) => this.handleChange(e, "productHeight")}
                    >
                      <option value="" selected disabled>
                        Select Product Package Height
                      </option>
                      {sizeOption.map((item, index) => {
                        return (
                          <option
                            key={index}
                            selected={item === this.state.productHeight}
                            value={item}
                          >
                            {item}
                          </option>
                        );
                      })}
                    </select>
                    <span className="form-field-error">
                      {this.state.errorData?.productHeight &&
                        this.state.errorData.productHeight[0]}
                    </span>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label>
                      product package width (in inches)
                      <sup>*</sup>
                    </label>
                    <select
                      name="productWidth"
                      class="form-control"
                      onChange={(e) => this.handleChange(e, "productWidth")}
                    >
                      <option value="" selected disabled>
                        Select Product Package Width
                      </option>
                      {sizeOption.map((item, index) => {
                        return (
                          <option
                            key={index}
                            selected={item === this.state.productWidth}
                            value={item}
                          >
                            {item}
                          </option>
                        );
                      })}
                    </select>
                    <span className="form-field-error">
                      {this.state.errorData?.productWidth &&
                        this.state.errorData.productWidth[0]}
                    </span>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label>
                      product Package length (in inches)
                      <sup>*</sup>
                    </label>
                    <select
                      name="productLength"
                      class="form-control"
                      onChange={(e) => this.handleChange(e, "productLength")}
                    >
                      <option value="" selected disabled>
                        Select Product Package Length
                      </option>
                      {sizeOption.map((item, index) => {
                        return (
                          <option
                            key={index}
                            selected={item === this.state.productLength}
                            value={item}
                          >
                            {item}
                          </option>
                        );
                      })}
                    </select>
                    <span className="form-field-error">
                      {this.state.errorData?.productLength &&
                        this.state.errorData.productLength[0]}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>
                      product Package weight (in pounds)
                      <sup>*</sup>
                    </label>
                    <select
                      name="productWeight"
                      className="form-control"
                      onChange={(e) => this.handleChange(e, "productWeight")}
                    >
                      <option value="" selected disabled>
                        Select Product Package weight
                      </option>
                      {weightOption.map((item, index) => {
                        return (
                          <option
                            key={index}
                            selected={item === this.state.productWeight}
                            value={item}
                          >
                            {item}
                          </option>
                        );
                      })}
                    </select>
                    <span className="form-field-error">
                      {this.state.errorData?.productWeight &&
                        this.state.errorData.productWeight[0]}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>
                      Select Couriers
                      <sup>*</sup>
                    </label>

                    <Select
                      value={this.state.defaultSelectedCourierOptions}
                      isMulti={false}
                      name="value"
                      options={this.state.courierList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        this.handleCourierChange(e);
                      }}
                    />
                    <span className="form-field-error">
                      {this.state.errorData?.selectedCourierList &&
                        this.state.errorData.selectedCourierList[0]}
                    </span>

                    <label className="custom-check mt-2">
                      Free shipping
                      <input
                        type="checkbox"
                        checked={this.state.is_shipping_free}
                        onChange={(e) =>
                          this.handleChangeChk(e, "is_shipping_free")
                        }
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            )}

          {!this.state.isAccessCategoryInCategories && <div class="row"></div>}

          {this.state.isAccessCategoryInCategories && (
            <div className="col-lg-6 col-md-6 p-0">
              <div className="form-group">
                <div className="d-flex align-items-center mb-3">
                  <input
                    type="checkbox"
                    disabled={true}
                    checked={this.state.isOneOnOneMeetingChecked}
                    onChange={this.handleIsOneOnOneMeeting}
                    className="mr-1"
                  />
                  <label className="m-0">Create One On One Session</label>
                </div>

                <span className="form-field-error">
                  {this.state.errorData?.o_o_o_meeting_price &&
                    this.state.errorData.o_o_o_meeting_price[0]}
                </span>
              </div>
            </div>
          )}
          {this.state.isOneOnOneMeetingChecked &&
            this.state.isAccessCategoryInCategories && (
              <div className="container-fluid">
                <div className="row">
                  <div className="form-group col-lg-12 col-md-12 border-show-seprate">
                    <h5>Select Frequency</h5>
                    <div className="form-group">
                      <label className="theme-color ">
                        <b>one on one</b>
                      </label>
                      <div className="frequency black-bg-op">
                        <label className="custom-label-product">
                          Select frequency
                          <div className="tooltip-custom">
                            <i
                              className="fa fa-info-circle info-icon"
                              aria-hidden="true"
                            ></i>
                            <div className="together-tooltip">
                              <p>
                                Select the type of one on one sessions you would
                                like to offer
                                <span class="arrow-down"></span>
                              </p>
                            </div>
                          </div>
                        </label>
                        {this.selectOneOnOneFrequency.map((frequency) => {
                          return (
                            <div className="check-outer blur">
                              <label className="outer-label mb-0">
                                <input
                                  type="checkbox"
                                  checked={
                                    this.state.selectedFrequency[frequency]
                                  }
                                  onChange={(e) =>
                                    this.handleChangeChk(
                                      e,
                                      frequency === `All`
                                        ? `allFrequencySelected`
                                        : `selectedFrequency`,
                                      "",
                                      frequency
                                    )
                                  }
                                  disabled={true}
                                />{" "}
                                {frequency}
                                <span class="checkmark" />
                              </label>
                            </div>
                          );
                        })}
                      </div>
                      <span className="form-field-error ml-2">
                        {this.state.errorData?.selectedOneOnOneFrequency &&
                          this.state.errorData.selectedOneOnOneFrequency[0]}
                      </span>
                    </div>
                    <div className="form-group px-2">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group d-flex mb-0">
                            <label className="custom-label-product">
                              Trial or 1-time cost
                              <div className="tooltip-custom">
                                <i
                                  className="fa fa-info-circle info-icon"
                                  aria-hidden="true"
                                ></i>
                                <div className="together-tooltip">
                                  <p>
                                    {" "}
                                    Enter the cost you would like to charge for
                                    a trial or one time meeting
                                    <span class="arrow-down"></span>
                                  </p>
                                </div>
                              </div>
                            </label>
                            <div className="positionRelative">
                              <span className="dollar-set">
                                <img src={DollarIcon} alt="" />
                              </span>
                              <input
                                className="form-control pl25"
                                type="number"
                                value={this.state.trialCost}
                                onChange={(e) =>
                                  this.handleSessionHourMinuteTrialSubscriptionCostChange(
                                    e,
                                    "trialCost"
                                  )
                                }
                                disabled={true}
                              />
                            </div>
                          </div>
                          <p className="form-field-error">
                            {this.state.errorData?.trialCost &&
                              this.state.errorData.trialCost[0]}
                          </p>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group d-flex mb-0">
                            <label className="custom-label-product d-flex">
                              Subscription Cost
                              <div className="tooltip-custom">
                                <i
                                  className="fa fa-info-circle info-icon"
                                  aria-hidden="true"
                                ></i>
                                <div className="together-tooltip">
                                  <p>
                                    {" "}
                                    Enter the cost you would like to charge per
                                    recurring session. For Example: A $20/per
                                    session cost for a weekly option will create
                                    a $80/month subscription offering
                                    <span class="arrow-down"></span>
                                  </p>
                                </div>
                              </div>
                            </label>
                            <div>
                              <input
                                className="form-control "
                                type="number"
                                value={this.state.subscriptionCost}
                                onChange={(e) =>
                                  this.handleSessionHourMinuteTrialSubscriptionCostChange(
                                    e,
                                    "subscriptionCost"
                                  )
                                }
                                disabled={true}
                              />
                            </div>
                          </div>
                          <p className="form-field-error">
                            {this.state.errorData?.subscriptionCost &&
                              this.state.errorData.subscriptionCost[0]}
                          </p>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group d-flex mb-0">
                            <label className="custom-label-product">
                              Session Duration
                              <div className="tooltip-custom">
                                <i
                                  className="fa fa-info-circle info-icon"
                                  aria-hidden="true"
                                ></i>
                                <div className="together-tooltip">
                                  <p>
                                    Enter duration you would like to offer per
                                    session
                                    <span class="arrow-down"></span>
                                  </p>
                                </div>
                              </div>
                            </label>
                            <div className="d-flex">
                              <label className="d-flex align-items-center mr-2 mb-0">
                                Hours
                              </label>
                              <select
                                className="form-control mr-2"
                                onChange={(e) =>
                                  this.handleSessionHourMinuteTrialSubscriptionCostChange(
                                    e,
                                    "sessionHour"
                                  )
                                }
                                value={this.state.sessionHour}
                                disabled={true}
                              >
                                <option value="Hours" selected disabled>
                                  Hours
                                </option>
                                {this.sessionHours.map((hour) => {
                                  return <option value={hour}>{hour}</option>;
                                })}
                              </select>
                              <label className="d-flex align-items-center mr-2 mb-0">
                                Minutes
                              </label>
                              <select
                                className="form-control "
                                onChange={(e) =>
                                  this.handleSessionHourMinuteTrialSubscriptionCostChange(
                                    e,
                                    "sessionMinute"
                                  )
                                }
                                value={this.state.sessionMinute}
                                disabled={true}
                              >
                                <option value="Minutes" selected disabled>
                                  Minutes
                                </option>
                                {this.sessionMinutes.map((minute) => {
                                  return (
                                    <option value={minute}>{minute}</option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <p className="form-field-error">
                            {this.state.errorData?.sessionHour &&
                              this.state.errorData.sessionHour[0]}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="form-group px-2">
                      <div className="frequency black-bg-op">
                        <label className="custom-label-product">
                          Available days
                          <div className="tooltip-custom">
                            <i
                              className="fa fa-info-circle info-icon"
                              aria-hidden="true"
                            ></i>
                            <div className="together-tooltip">
                              <p>
                                Enter Day(s) you would like to offer
                                availability for customers to schedule with you
                                <span class="arrow-down"></span>
                              </p>
                            </div>
                          </div>
                        </label>
                        {Object.entries(this.weekDays).map(
                          ([weekDay, weekDayVal]) => {
                            return (
                              <div className="check-outer blur">
                                <label className="outer-label mb-0">
                                  <input
                                    type="checkbox"
                                    checked={
                                      this.state.isWeekDaySelected[weekDay]
                                    }
                                    onChange={(e) =>
                                      this.handleChangeChk(
                                        e,
                                        "isWeekDaySelected",
                                        weekDay
                                      )
                                    }
                                    disabled={true}
                                  />{" "}
                                  {weekDay}
                                  <span class="checkmark" />
                                </label>
                              </div>
                            );
                          }
                        )}
                      </div>
                      <span className="form-field-error">
                        {this.state.errorData?.selectedWeekDay &&
                          this.state.errorData.selectedWeekDay[0]}
                      </span>
                    </div>
                    <div>
                      <label className="custom-label-product pb-2">
                        AVAILABLE TIME SLOTS
                        <div className="tooltip-custom">
                          <i
                            className="fa fa-info-circle info-icon"
                            aria-hidden="true"
                          ></i>
                          <div className="together-tooltip">
                            <p>
                              Enter availability time frame(s) per day(s)
                              selected above
                              <span className="arrow-down"></span>
                            </p>
                          </div>
                        </div>
                      </label>
                      <div className="recurring">
                        {Object.entries(this.weekDays).map(
                          ([weekDay, weekDayVal]) => {
                            return (
                              <>
                                <div
                                  className={`week-card ${
                                    this.state.isWeekDaySelected[weekDay]
                                      ? ``
                                      : `disabled`
                                  }`}
                                >
                                  <div
                                    className={`day active ${
                                      this.state.isWeekDaySelected[weekDay]
                                        ? ``
                                        : `disabled`
                                    }`}
                                  >
                                    {weekDayVal}
                                  </div>
                                  <div>
                                    {this.state.oneOnOneMeetingSlots[weekDay]
                                      ?.length > 0 &&
                                      this.state.oneOnOneMeetingSlots[
                                        weekDay
                                      ]?.map((timeSlot, index) => {
                                        return (
                                          <>
                                            <div className="week-bottom">
                                              <div className="d-flex align-items-center">
                                                <TimePicker
                                                  value={timeSlot[0]}
                                                  format="hh:mm a"
                                                  name="start"
                                                  disableClock={true}
                                                  onChange={(e) =>
                                                    this.handleOneOnOneMeetingTimeChange(
                                                      e,
                                                      weekDay,
                                                      index,
                                                      "start"
                                                    )
                                                  }
                                                  required={true}
                                                  clearIcon={null}
                                                  className="form-control"
                                                  disabled={true}
                                                />
                                                <p className="mb-0 mx-2">To</p>
                                                <TimePicker
                                                  value={timeSlot[1]}
                                                  format="hh:mm a"
                                                  name="start"
                                                  disableClock={true}
                                                  onChange={(e) =>
                                                    this.handleOneOnOneMeetingTimeChange(
                                                      e,
                                                      weekDay,
                                                      index,
                                                      "end"
                                                    )
                                                  }
                                                  required={true}
                                                  clearIcon={null}
                                                  className="form-control"
                                                  disabled={true}
                                                />
                                              </div>
                                            </div>

                                            <p className="form-field-error">
                                              {!!this.state.errorData
                                                ?.selectedOneOnOneMeetingSlots &&
                                              this.state.errorData
                                                ?.selectedOneOnOneMeetingSlots[
                                                weekDay
                                              ] &&
                                              this.state.errorData
                                                .selectedOneOnOneMeetingSlots[
                                                weekDay
                                              ][index]?.length
                                                ? this.state.errorData
                                                    .selectedOneOnOneMeetingSlots[
                                                    weekDay
                                                  ][index][0]
                                                : null}
                                            </p>
                                          </>
                                        );
                                      })}
                                  </div>
                                </div>
                              </>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          {this.state.isAccessCategoryInCategories && (
            <div className="col-lg-12 col-md-12 p-0 mt-4 bd-t pt-3">
              <div className="form-group">
                <div className="d-flex align-items-center mb-1">
                  <input
                    type="checkbox"
                    disabled={true}
                    checked={this.state.isGroupMeetingChecked}
                    onChange={this.handleIsGroupMeeting}
                    className="mr-1"
                  />
                  <label className="m-0">Group meeting</label>
                </div>
                <span className="form-field-error">
                  {this.state.errorData?.group_meeting &&
                    this.state.errorData.group_meeting[0]}
                </span>
              </div>
            </div>
          )}

          {this.state.isGroupMeetingChecked &&
            this.state.isAccessCategoryInCategories && (
              <div className="container-fluid">
                <div className="row white-bg p-0">
                  <div className="col-lg-12 col-md-12 border-show-seprate">
                    {this.state.groupMeetingDetails.map(
                      (groupMeetingDetails, index) => {
                        return (
                          <>
                            <h5>Select Frequency</h5>
                            <div className="form-group">
                              <label class="theme-color ">
                                <b>Group</b>
                              </label>
                            </div>
                            <div className="black-bg-op p-3">
                              <div className="form-group d-flex m-0">
                                <label className="custom-label-product">
                                  Meeting Name
                                  <div className="tooltip-custom">
                                    <i
                                      className="fa fa-info-circle info-icon"
                                      aria-hidden="true"
                                    ></i>
                                    <div className="together-tooltip">
                                      <p>
                                        {" "}
                                        Enter the name of the group session in
                                        which will be shown to customers.
                                        <span class="arrow-down"></span>
                                      </p>
                                    </div>
                                  </div>
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={groupMeetingDetails["meetingName"]}
                                  onChange={(e) =>
                                    this.handleGroupMeetingDetailsChange(
                                      e,
                                      "meetingName",
                                      index
                                    )
                                  }
                                  disabled={true}
                                />
                              </div>
                            </div>

                            <div className="form-group mb-0 p-3">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group mb-0 d-flex">
                                    <label className="custom-label-product">
                                      Subscription Cost
                                      <div className="tooltip-custom">
                                        <i
                                          className="fa fa-info-circle info-icon"
                                          aria-hidden="true"
                                        ></i>
                                        <div className="together-tooltip">
                                          <p>
                                            {" "}
                                            Enter the cost you would like to
                                            charge per recurring session
                                            grouping. For Example: A $50
                                            subscription cost for a weekly
                                            Monday/Wednesday/Friday option will
                                            create a $200/month subscription
                                            offering
                                            <span class="arrow-down"></span>
                                          </p>
                                        </div>
                                      </div>
                                    </label>
                                    <input
                                      className="form-control"
                                      type="number"
                                      value={
                                        groupMeetingDetails["subscriptionCost"]
                                      }
                                      onChange={(e) =>
                                        this.handleGroupMeetingDetailsChange(
                                          e,
                                          "subscriptionCost",
                                          index
                                        )
                                      }
                                      disabled={true}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="form-group mb-0 d-flex">
                                    <label className="custom-label-product">
                                      Session Duration
                                      <div className="tooltip-custom">
                                        <i
                                          className="fa fa-info-circle info-icon"
                                          aria-hidden="true"
                                        ></i>
                                        <div className="together-tooltip">
                                          <p>
                                            Enter duration you would like to
                                            offer per session
                                            <span class="arrow-down"></span>
                                          </p>
                                        </div>
                                      </div>
                                    </label>
                                    <div className="d-flex">
                                      <label className="d-flex align-items-center mr-2 mb-0">
                                        Hours
                                      </label>
                                      <select
                                        className="form-control mr-2"
                                        onChange={(e) =>
                                          this.handleGroupMeetingDetailsChange(
                                            e,
                                            "sessionHour",
                                            index
                                          )
                                        }
                                        value={
                                          groupMeetingDetails["sessionHour"]
                                        }
                                        disabled={true}
                                      >
                                        <option value="Hours" selected disabled>
                                          Hours
                                        </option>
                                        {this.sessionHours.map((hour) => {
                                          return (
                                            <option value={hour}>{hour}</option>
                                          );
                                        })}
                                      </select>
                                      <label className="d-flex align-items-center mr-2 mb-0">
                                        Minutes
                                      </label>
                                      <select
                                        className="form-control"
                                        onChange={(e) =>
                                          this.handleGroupMeetingDetailsChange(
                                            e,
                                            "sessionMinute",
                                            index
                                          )
                                        }
                                        value={
                                          groupMeetingDetails["sessionMinute"]
                                        }
                                        disabled={true}
                                      >
                                        <option
                                          value="Minutes"
                                          selected
                                          disabled
                                        >
                                          Minutes
                                        </option>
                                        {this.sessionMinutes.map((minute) => {
                                          return (
                                            <option value={minute}>
                                              {minute}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="form-group black-bg-op p-3 m-0">
                              <div className="frequency p-0">
                                <label className="custom-label-product">
                                  Select frequency
                                  <div className="tooltip-custom">
                                    <i
                                      className="fa fa-info-circle info-icon"
                                      aria-hidden="true"
                                    ></i>
                                    <div className="together-tooltip">
                                      <p>
                                        {" "}
                                        Select the type of one on one sessions
                                        you would like to offer
                                        <span class="arrow-down"></span>
                                      </p>
                                    </div>
                                  </div>
                                </label>

                                {this.selectGroupFrequency.map((frequency) => {
                                  return (
                                    <div class="check-outer blur">
                                      <label className="outer-label mb-0">
                                        <input
                                          type="radio"
                                          value={frequency}
                                          id={frequency}
                                          checked={
                                            groupMeetingDetails[
                                              "groupMeetingFrequency"
                                            ] === frequency
                                          }
                                          onChange={(e) =>
                                            this.handleGroupMeetingFrequencyChange(
                                              e,
                                              frequency,
                                              index
                                            )
                                          }
                                          disabled={true}
                                        />{" "}
                                        {frequency}
                                        <span class="checkmark"></span>
                                      </label>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>

                            <div className="form-group mb-0">
                              {groupMeetingDetails[
                                "isMonthlyGroupFrequencyChecked"
                              ] && (
                                <>
                                  <div className="row white-bg">
                                    <div className="col-md-4">
                                      <div className="form-group mb-0 d-flex">
                                        <label className="custom-label-product">
                                          Week
                                          <div className="tooltip-custom">
                                            <i
                                              className="fa fa-info-circle info-icon"
                                              aria-hidden="true"
                                            ></i>
                                            <div className="together-tooltip">
                                              <p>
                                                Select the week number
                                                <span class="arrow-down"></span>
                                              </p>
                                            </div>
                                          </div>
                                        </label>
                                        <div className="w-100">
                                          <select
                                            className="form-control"
                                            value={
                                              groupMeetingDetails[
                                                "meetingMonthlyWeekOfMonth"
                                              ]
                                            }
                                            onChange={(e) =>
                                              this.handleGroupMeetingMonthlyWeekDayAndWeekChange(
                                                e,
                                                "meetingMonthlyWeekOfMonth",
                                                index
                                              )
                                            }
                                            disabled={true}
                                          >
                                            <option value="" selected disabled>
                                              --Select--
                                            </option>
                                            {this.weekOfMonth.map((week) => {
                                              return (
                                                <option value={week}>
                                                  {week}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group d-flex m-0">
                                        <label className="custom-label-product">
                                          Week Day
                                          <div className="tooltip-custom">
                                            <i
                                              className="fa fa-info-circle info-icon"
                                              aria-hidden="true"
                                            ></i>
                                            <div className="together-tooltip">
                                              <p>
                                                Select the day of the week
                                                <span class="arrow-down"></span>
                                              </p>
                                            </div>
                                          </div>
                                        </label>
                                        <div className="w-100">
                                          <select
                                            className="form-control"
                                            value={
                                              groupMeetingDetails[
                                                "meetingMonthlyWeekDay"
                                              ]
                                            }
                                            onChange={(e) =>
                                              this.handleGroupMeetingMonthlyWeekDayAndWeekChange(
                                                e,
                                                "meetingMonthlyWeekDay",
                                                index
                                              )
                                            }
                                            disabled={true}
                                          >
                                            <option value="" selected disabled>
                                              --Select--
                                            </option>
                                            {Object.entries(this.weekDays).map(
                                              ([key, weekDayVal]) => {
                                                return (
                                                  <option value={weekDayVal}>
                                                    {weekDayVal}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group d-flex m-0">
                                        <label className="custom-label-product">
                                          Time
                                          <div className="tooltip-custom">
                                            <i
                                              className="fa fa-info-circle info-icon"
                                              aria-hidden="true"
                                            ></i>
                                            <div className="together-tooltip">
                                              <p>
                                                Enter The Time You Would Like To
                                                Offer
                                                <span class="arrow-down"></span>
                                              </p>
                                            </div>
                                          </div>
                                        </label>
                                        <div className="d-flex align-items-center">
                                          <div className="mr-2">
                                            <TimePicker
                                              value={
                                                groupMeetingDetails[
                                                  "meetingMonthlyStartTime"
                                                ]
                                              }
                                              format="hh:mm a"
                                              name="start"
                                              disableClock={true}
                                              onChange={(e) =>
                                                this.handleGroupMeetingDetailsChange(
                                                  e,
                                                  "meetingMonthlyStartTime",
                                                  index
                                                )
                                              }
                                              required={true}
                                              clearIcon={null}
                                              className="form-control"
                                              disabled={true}
                                            />
                                          </div>
                                          <p className="mb-0 mx-2">To</p>
                                          <div className="ml-2">
                                            <TimePicker
                                              value={
                                                groupMeetingDetails[
                                                  "meetingMonthlyEndTime"
                                                ]
                                              }
                                              format="hh:mm a"
                                              name="start"
                                              disableClock={true}
                                              onChange={(e) =>
                                                this.handleGroupMeetingDetailsChange(
                                                  e,
                                                  "meetingMonthlyEndTime",
                                                  index
                                                )
                                              }
                                              required={true}
                                              clearIcon={null}
                                              className="form-control"
                                              disabled={true}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {!!!groupMeetingDetails[
                                "isMonthlyGroupFrequencyChecked"
                              ] && (
                                <>
                                  <div className="form-group mb-0 p-3">
                                    <div className="frequency p-0">
                                      <label className="custom-label-product">
                                        Available days
                                        <div className="tooltip-custom">
                                          <i
                                            className="fa fa-info-circle info-icon"
                                            aria-hidden="true"
                                          ></i>
                                          <div className="together-tooltip">
                                            <p>
                                              {" "}
                                              Select Day(s) you would like to
                                              offer for this specific group
                                              session. Example: Select
                                              Monday/Wednesday/Friday to offer
                                              weekly classes on
                                              Monday/Wednesday/Friday
                                              <span class="arrow-down"></span>
                                            </p>
                                          </div>
                                        </div>
                                      </label>
                                      {Object.entries(this.weekDays).map(
                                        ([weekDay, weekDayVal]) => {
                                          return (
                                            <div className="check-outer blur">
                                              <label className="outer-label mb-0">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    groupMeetingDetails[
                                                      "isWeekDaySelected"
                                                    ][weekDay]
                                                  }
                                                  onChange={(e) =>
                                                    this.handleGroupWeeklyBiweeklySlotChange(
                                                      e,
                                                      weekDay,
                                                      index
                                                    )
                                                  }
                                                  disabled={true}
                                                />{" "}
                                                {weekDay}
                                                <span class="checkmark"></span>
                                              </label>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>

                                  <div>
                                    <label className="custom-label-product pb-2">
                                      available time slots
                                      <div className="tooltip-custom">
                                        <i
                                          className="fa fa-info-circle info-icon"
                                          aria-hidden="true"
                                        ></i>
                                        <div className="together-tooltip">
                                          <p>
                                            Enter time slot per day from chosen
                                            days above
                                            <span className="arrow-down"></span>
                                          </p>
                                        </div>
                                      </div>
                                    </label>
                                    <div className="recurring">
                                      {Object.entries(this.weekDays).map(
                                        ([weekDay, weekDayVal]) => {
                                          return (
                                            <>
                                              <div
                                                className={`week-card ${
                                                  groupMeetingDetails[
                                                    "isWeekDaySelected"
                                                  ][weekDay]
                                                    ? ``
                                                    : `disabled`
                                                }`}
                                              >
                                                <div
                                                  className={`day active ${
                                                    groupMeetingDetails[
                                                      "isWeekDaySelected"
                                                    ][weekDay]
                                                      ? ``
                                                      : `disabled`
                                                  }`}
                                                >
                                                  {weekDayVal}
                                                </div>
                                                <div>
                                                  {groupMeetingDetails[
                                                    "groupMeetingSlots"
                                                  ][weekDay]?.length > 0 &&
                                                    groupMeetingDetails[
                                                      "groupMeetingSlots"
                                                    ][weekDay]?.map(
                                                      (timeSlot, position) => {
                                                        return (
                                                          <>
                                                            <div className="week-bottom">
                                                              <div className="d-flex align-items-center">
                                                                <TimePicker
                                                                  value={
                                                                    timeSlot[0]
                                                                  }
                                                                  format="hh:mm a"
                                                                  name="start"
                                                                  disableClock={
                                                                    true
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    this.handleGroupMeetingTimeChange(
                                                                      e,
                                                                      weekDay,
                                                                      position,
                                                                      "start",
                                                                      index
                                                                    )
                                                                  }
                                                                  required={
                                                                    true
                                                                  }
                                                                  clearIcon={
                                                                    null
                                                                  }
                                                                  className="form-control"
                                                                  disabled={
                                                                    true
                                                                  }
                                                                />
                                                                <p className="mb-0 mx-2">
                                                                  To
                                                                </p>
                                                                <TimePicker
                                                                  value={
                                                                    timeSlot[1]
                                                                  }
                                                                  format="hh:mm a"
                                                                  name="start"
                                                                  disableClock={
                                                                    true
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    this.handleGroupMeetingTimeChange(
                                                                      e,
                                                                      weekDay,
                                                                      position,
                                                                      "end",
                                                                      index
                                                                    )
                                                                  }
                                                                  required={
                                                                    true
                                                                  }
                                                                  clearIcon={
                                                                    null
                                                                  }
                                                                  className="form-control"
                                                                  disabled={
                                                                    true
                                                                  }
                                                                />
                                                              </div>
                                                            </div>
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            )}

          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="form-group">
                <label>product Description</label>
                <textarea
                  onChange={(e) => this.handleChange(e, "productDescription")}
                  value={this.state.productDescription}
                  class="form-control form-textarea"
                  placeholder="enter your text here..."
                ></textarea>
                <span className="form-field-error">
                  {this.state.errorData?.productDescription &&
                    this.state.errorData.productDescription[0]}
                </span>
              </div>
            </div>
          </div>

          {this.state.isAccessCategoryInCategories ? (
            <>
              <div className="row border-add-select">
                <div className="select-head-inline">
                  <h5 className="custom-label-product">
                    Access Subscription Content
                    <div className="tooltip-custom">
                      <i
                        className="fa fa-info-circle info-icon"
                        aria-hidden="true"
                      ></i>
                      <div className="together-tooltip">
                        <p>
                          Content only shown to active subscribers
                          <span class="arrow-down"></span>
                        </p>
                      </div>
                    </div>
                  </h5>

                  <button
                    className="orange-btn"
                    onClick={(e) => this.addNewAcsSubcategory()}
                  >
                    <span>
                      <i class="fas fa-plus-circle"></i>
                    </span>
                    Add
                  </button>
                </div>

                {this.state.setCategoryAddedMessage && (
                  <div className="alert-fixed alert-success">
                    <p> {this.state.setCategoryAddedMessage}</p>
                  </div>
                )}
                {this.state.acsCatSubcategories.map((subCategory, catIndex) => (
                  <div className="border-add-new">
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <div className="d-flex justify-content-between mb-2">
                          <label>Title</label>
                          {
                            <button
                              className="orange-outline-btn"
                              onClick={() =>
                                this.removeAcsSubcategory(catIndex)
                              }
                            >
                              remove category
                            </button>
                          }
                        </div>
                        <div className="form-group">
                          <input
                            className="form-control"
                            value={subCategory.acsCatTitle}
                            onChange={(e) =>
                              this.handleChange(e, "acsCatTitle", catIndex)
                            }
                          />
                          {/* <span className="form-field-error">
                            {this.state.errorData?.acsCatTitle &&
                              this.state.errorData.acsCatTitle[catIndex]}
                          </span> */}
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Video Gallery</label>
                        <div className="App">
                          <div className="upload__image-wrapper">
                            <div className="text-left mb-4">
                              <button
                                className="orange-outline-btn"
                                onClick={(e) =>
                                  this.triggerFileUploadClick(
                                    e,
                                    "acsCatVideos",
                                    catIndex
                                  )
                                }
                              >
                                Click here to upload
                              </button>
                              &nbsp;
                              <button
                                className="orange-outline-btn"
                                onClick={(e) =>
                                  this.deleteAllGalleryFiles(
                                    e,
                                    "acsCatVideos",
                                    catIndex
                                  )
                                }
                              >
                                Remove all videos
                              </button>
                            </div>
                          </div>
                          {subCategory.acsCatVideoGallery &&
                          subCategory.acsCatVideoGallery.length > 0 ? (
                            <>
                              {subCategory.acsCatVideoGallery.map(
                                (item, index) => (
                                  <div key={index} className="image-item">
                                    {this.state.showVideos ? (
                                      <video width="200" height="150" controls>
                                        <source
                                          src={this.makePrivateUrl(item)}
                                          type="video/mp4"
                                        />
                                        <source
                                          src={this.makePrivateUrl(item)}
                                          type="video/webm"
                                        />
                                        <source
                                          src={this.makePrivateUrl(item)}
                                          type="video/ogg"
                                        />
                                        Your browser does not support the video
                                        tag.
                                      </video>
                                    ) : null}
                                    <div>
                                      <p className="mb-3">
                                        <b>{this.getFileNameFromUrl(item)}</b>
                                      </p>
                                      <div className="image-item__btn-wrapper">
                                        <button
                                          className="orange-outline-btn"
                                          onClick={(e) =>
                                            this.deleteSelectedGalleryFile(
                                              e,
                                              "acsCatVideos",
                                              item,
                                              index,
                                              catIndex
                                            )
                                          }
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </>
                          ) : null}
                          {subCategory.acsCatVideos &&
                          subCategory.acsCatVideos.length > 0 ? (
                            <>
                              {subCategory.acsCatVideos.map((item, index) => (
                                <div key={index} className="image-item">
                                  {this.state.showVideos ? (
                                    <video width="200" height="150" controls>
                                      <source
                                        src={item.stream}
                                        type={item.type}
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  ) : null}
                                  <div>
                                    <input
                                      placeholder="Enter File Name"
                                      className="mb-3"
                                      value={
                                        subCategory.acsCatVideosTitles &&
                                        subCategory?.acsCatVideosTitles[index]
                                      }
                                      onChange={(e) =>
                                        this.handleChange(
                                          e,
                                          "acsCatVideosTitles",
                                          catIndex,
                                          index
                                        )
                                      }
                                    />
                                    <div className="image-item__btn-wrapper">
                                      {item.status || ""}
                                      {!item.status ? (
                                        <button
                                          className="orange-outline-btn"
                                          onClick={(e) =>
                                            this.deleteSelectedFile(
                                              e,
                                              "acsCatVideos",
                                              index,
                                              catIndex
                                            )
                                          }
                                        >
                                          Remove
                                        </button>
                                      ) : null}
                                      {item.uploadPercentage &&
                                      item.uploadPercentage < 100 ? (
                                        <progress
                                          id="file"
                                          value={item.uploadPercentage}
                                          max="100"
                                        >
                                          {" "}
                                          {item.uploadPercentage}%{" "}
                                        </progress>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : null}
                          <p className="files-error">
                            {this.state.errorData.acsCatSubcategories &&
                              this.state.errorData?.acsCatSubcategories[
                                catIndex
                              ] &&
                              this.state.errorData?.acsCatSubcategories[
                                catIndex
                              ]?.acsCatVideos[0]}
                          </p>
                          {this.props.errorAcsCatVideos &&
                          this.props.errorAcsCatVideos.length > 0 ? (
                            <>
                              {this.props.errorAcsCatVideos.map(
                                (item, index) => (
                                  <p className="files-error" key={index}>
                                    {item}
                                  </p>
                                )
                              )}
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group space-add-elite">
                        <label>Audio Gallery</label>
                        <div className="App">
                          <div className="upload__image-wrapper">
                            <div className="text-left mb-4">
                              <button
                                className="orange-outline-btn"
                                onClick={(e) =>
                                  this.triggerFileUploadClick(
                                    e,
                                    "acsCatAudios",
                                    catIndex
                                  )
                                }
                              >
                                Click here to upload
                              </button>
                              &nbsp;
                              <button
                                className="orange-outline-btn"
                                onClick={(e) =>
                                  this.deleteAllGalleryFiles(
                                    e,
                                    "acsCatAudios",
                                    catIndex
                                  )
                                }
                              >
                                Remove all audios
                              </button>
                            </div>
                          </div>
                          {subCategory.acsCatAudioGallery &&
                          subCategory.acsCatAudioGallery.length > 0 ? (
                            <>
                              {subCategory.acsCatAudioGallery.map(
                                (item, index) => (
                                  <div key={index} className="image-item">
                                    {this.state.showVideos ? (
                                      <audio controls>
                                        <source
                                          src={this.makePrivateUrl(item)}
                                          type="audio/ogg"
                                        />
                                        <source
                                          src={this.makePrivateUrl(item)}
                                          type="audio/mpeg"
                                        />
                                        <source
                                          src={this.makePrivateUrl(item)}
                                          type="audio/wav"
                                        />
                                        Your browser does not support the audio
                                        element.
                                      </audio>
                                    ) : null}
                                    <div>
                                      <p className="mb-3">
                                        <b>{this.getFileNameFromUrl(item)}</b>
                                      </p>

                                      <div className="image-item__btn-wrapper">
                                        <button
                                          className="orange-outline-btn"
                                          onClick={(e) =>
                                            this.deleteSelectedGalleryFile(
                                              e,
                                              "acsCatAudios",
                                              item,
                                              index,
                                              catIndex
                                            )
                                          }
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </>
                          ) : null}
                          {subCategory.acsCatAudios &&
                          subCategory.acsCatAudios.length > 0 ? (
                            <>
                              {subCategory.acsCatAudios.map((item, index) => (
                                <div key={index} className="image-item">
                                  {this.state.showVideos ? (
                                    <audio controls>
                                      <source
                                        src={item.stream}
                                        type={item.type}
                                      />
                                      Your browser does not support the audio
                                      element.
                                    </audio>
                                  ) : null}
                                  <div>
                                    <input
                                      className="mb-3"
                                      placeholder="Enter File Name"
                                      value={
                                        subCategory.acsCatAudiosTitles &&
                                        subCategory.acsCatAudiosTitles[index]
                                      }
                                      onChange={(e) =>
                                        this.handleChange(
                                          e,
                                          "acsCatAudiosTitles",
                                          catIndex,
                                          index
                                        )
                                      }
                                    />
                                    <div className="image-item__btn-wrapper">
                                      {item.status || ""}
                                      {!item.status ? (
                                        <button
                                          className="orange-outline-btn"
                                          onClick={(e) =>
                                            this.deleteSelectedFile(
                                              e,
                                              "acsCatAudios",
                                              index,
                                              catIndex
                                            )
                                          }
                                        >
                                          Remove
                                        </button>
                                      ) : null}
                                      {item.uploadPercentage &&
                                      item.uploadPercentage < 100 ? (
                                        <progress
                                          id="file"
                                          value={item.uploadPercentage}
                                          max="100"
                                        >
                                          {" "}
                                          {item.uploadPercentage}%{" "}
                                        </progress>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : null}
                          <p className="files-error">
                            {this.state.errorData.acsCatSubcategories &&
                              this.state.errorData.acsCatSubcategories[
                                catIndex
                              ] &&
                              this.state.errorData.acsCatSubcategories[catIndex]
                                ?.acsCatAudios[0]}
                          </p>
                          {this.props.errorAcsCatAudios &&
                          this.props.errorAcsCatAudios.length > 0 ? (
                            <>
                              {this.props.errorAcsCatAudios.map(
                                (item, index) => (
                                  <p className="files-error" key={index}>
                                    {item}
                                  </p>
                                )
                              )}
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group space-add-elite">
                        <label>Image Gallery</label>
                        <div className="App">
                          <div className="upload__image-wrapper">
                            <div className="text-left mb-4">
                              <button
                                className="orange-outline-btn"
                                onClick={(e) =>
                                  this.triggerFileUploadClick(
                                    e,
                                    "acsCatImages",
                                    catIndex
                                  )
                                }
                              >
                                Click here to upload
                              </button>
                              &nbsp;
                              <button
                                className="orange-outline-btn"
                                onClick={(e) =>
                                  this.deleteAllGalleryFiles(
                                    e,
                                    "acsCatImages",
                                    catIndex
                                  )
                                }
                              >
                                Remove all images
                              </button>
                            </div>
                          </div>
                          {subCategory.acsCatImageGallery &&
                          subCategory.acsCatImageGallery.length > 0 ? (
                            <>
                              {subCategory.acsCatImageGallery.map(
                                (item, index) => (
                                  <div key={index} className="image-item">
                                    <img
                                      src={this.makePrivateUrl(item)}
                                      alt=""
                                      width="100"
                                    />
                                    <div>
                                      <p className="mb-3">
                                        <b>{this.getFileNameFromUrl(item)}</b>
                                      </p>
                                      <div className="image-item__btn-wrapper">
                                        <button
                                          className="orange-outline-btn"
                                          onClick={(e) =>
                                            this.deleteSelectedGalleryFile(
                                              e,
                                              "acsCatImages",
                                              item,
                                              index,
                                              catIndex
                                            )
                                          }
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </>
                          ) : null}
                          {subCategory.acsCatImages &&
                          subCategory.acsCatImages.length > 0 ? (
                            <>
                              {subCategory.acsCatImages.map((item, index) => (
                                <div key={index} className="image-item">
                                  <img src={item.stream} alt="" width="100" />
                                  <div>
                                    <input
                                      className="mb-3"
                                      placeholder="Enter File Name"
                                      value={
                                        subCategory.acsCatImagesTitles &&
                                        subCategory.acsCatImagesTitles[index]
                                      }
                                      onChange={(e) =>
                                        this.handleChange(
                                          e,
                                          "acsCatImagesTitles",
                                          catIndex,
                                          index
                                        )
                                      }
                                    />
                                    <div className="image-item__btn-wrapper">
                                      {item.status || ""}
                                      {!item.status ? (
                                        <button
                                          className="orange-outline-btn"
                                          onClick={(e) =>
                                            this.deleteSelectedFile(
                                              e,
                                              "acsCatImages",
                                              index,
                                              catIndex
                                            )
                                          }
                                        >
                                          Remove
                                        </button>
                                      ) : null}
                                      {item.uploadPercentage &&
                                      item.uploadPercentage < 100 ? (
                                        <progress
                                          id="file"
                                          value={item.uploadPercentage}
                                          max="100"
                                        >
                                          {" "}
                                          {item.uploadPercentage}%{" "}
                                        </progress>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : null}
                          <p className="files-error">
                            {this.state.errorData.acsCatSubcategories &&
                              this.state.errorData.acsCatSubcategories[
                                catIndex
                              ] &&
                              this.state.errorData.acsCatSubcategories[catIndex]
                                ?.acsCatImages[0]}
                          </p>
                          {this.props.errorAcsCatImages &&
                          this.props.errorAcsCatImages.length > 0 ? (
                            <>
                              {this.props.errorAcsCatImages.map(
                                (item, index) => (
                                  <p className="files-error" key={index}>
                                    {item}
                                  </p>
                                )
                              )}
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group space-add-elite">
                        <label>File Gallery</label>
                        <div className="App">
                          <div className="upload__image-wrapper">
                            <div className="text-left mb-4">
                              <button
                                className="orange-outline-btn"
                                onClick={(e) =>
                                  this.triggerFileUploadClick(
                                    e,
                                    "acsCatDocuments",
                                    catIndex
                                  )
                                }
                              >
                                Click here to upload
                              </button>
                              &nbsp;
                              <button
                                className="orange-outline-btn"
                                onClick={(e) =>
                                  this.deleteAllGalleryFiles(
                                    e,
                                    "acsCatDocuments",
                                    catIndex
                                  )
                                }
                              >
                                Remove all files
                              </button>
                            </div>
                          </div>
                          {subCategory.acsCatDocGallery &&
                          subCategory.acsCatDocGallery.length > 0 ? (
                            <>
                              {subCategory.acsCatDocGallery.map(
                                (item, index) => (
                                  <div key={index} className="image-item">
                                    <div
                                      className="file-box-select"
                                      onClick={(e) => {
                                        if (
                                          !this.state.downloadProcessMap[item]
                                            ?.downloadPercentage
                                        ) {
                                          this.downloadFileByUrl(e, item);
                                        }
                                      }}
                                    >
                                      <p>
                                        <b>{this.getFileNameFromUrl(item)}</b>
                                      </p>
                                      <span>{this.getFileSizeByUrl(item)}</span>
                                      <p className="file-icon-tuff">
                                        <i className="far fa-file-alt"></i>
                                      </p>
                                    </div>
                                    <div className="image-item__btn-wrapper">
                                      <button
                                        className="orange-outline-btn"
                                        onClick={(e) =>
                                          this.deleteSelectedGalleryFile(
                                            e,
                                            "acsCatDocuments",
                                            item,
                                            index,
                                            catIndex
                                          )
                                        }
                                      >
                                        Remove
                                      </button>
                                      {this.state.downloadProcessMap[item] &&
                                      this.state.downloadProcessMap[item]
                                        ?.downloadPercentage < 100 ? (
                                        <progress
                                          id="file"
                                          value={
                                            this.state.downloadProcessMap[item]
                                              .downloadPercentage
                                          }
                                          max="100"
                                        >
                                          {" "}
                                          {
                                            this.state.downloadProcessMap[item]
                                              .downloadPercentage
                                          }
                                          %{" "}
                                        </progress>
                                      ) : null}
                                    </div>
                                  </div>
                                )
                              )}
                            </>
                          ) : null}
                          {subCategory.acsCatDocuments &&
                          subCategory.acsCatDocuments.length > 0 ? (
                            <>
                              {subCategory.acsCatDocuments.map(
                                (item, index) => (
                                  <div key={index} className="image-item">
                                    <div className="file-box-select">
                                      <input
                                        value={
                                          subCategory.acsCatDocumentsTitles &&
                                          subCategory.acsCatDocumentsTitles[
                                            index
                                          ]
                                        }
                                        placeholder="Enter File Name"
                                        onChange={(e) =>
                                          this.handleChange(
                                            e,
                                            "acsCatDocumentsTitles",
                                            catIndex,
                                            index
                                          )
                                        }
                                      />
                                      <span>
                                        {this.getFileSizeByByteLength(
                                          item.file.size
                                        )}
                                      </span>
                                      <p className="file-icon-tuff">
                                        <i className="far fa-file-alt"></i>
                                      </p>
                                    </div>
                                    <div className="image-item__btn-wrapper">
                                      {item.status || ""}
                                      {!item.status ? (
                                        <button
                                          className="orange-outline-btn"
                                          onClick={(e) =>
                                            this.deleteSelectedFile(
                                              e,
                                              "acsCatDocuments",
                                              index,
                                              catIndex
                                            )
                                          }
                                        >
                                          Remove
                                        </button>
                                      ) : null}
                                      {item.uploadPercentage &&
                                      item.uploadPercentage < 100 ? (
                                        <progress
                                          id="file"
                                          value={item.uploadPercentage}
                                          max="100"
                                        >
                                          {" "}
                                          {item.uploadPercentage}%{" "}
                                        </progress>
                                      ) : null}
                                    </div>
                                  </div>
                                )
                              )}
                            </>
                          ) : null}
                          <p className="files-error">
                            {this.state.errorData.acsCatSubcategories &&
                              this.state.errorData?.acsCatSubcategories[
                                catIndex
                              ] &&
                              this.state.errorData.acsCatSubcategories[catIndex]
                                ?.acsCatDocuments[0]}
                          </p>
                          {this.props.errorAcsCatDocuments &&
                          this.props.errorAcsCatDocuments.length > 0 ? (
                            <>
                              {this.props.errorAcsCatDocuments.map(
                                (item, index) => (
                                  <p className="files-error" key={index}>
                                    {item}
                                  </p>
                                )
                              )}
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : null}

          <div class="row">
            <div className="col-lg-12 col-md-12">
              <div className="form-group">
                <label>Want to get it featured?</label>
                <div className="custom-radio-input">
                  <p className="container-sub mr-2">
                    No
                    <span>
                      <input
                        type="radio"
                        value="0"
                        onChange={(event) =>
                          this.handleChange(event, "isFeatured")
                        }
                        checked={+this.state.isFeatured === 0}
                        name="isFeatureRadio"
                      />
                    </span>
                    <span class="checkmark"></span>
                  </p>

                  <p className="container-sub mr-2">
                    Yes
                    <span>
                      <input
                        type="radio"
                        value="1"
                        onChange={(event) =>
                          this.confirmProductIsFeatureAlert(event, "isFeatured")
                        }
                        checked={+this.state.isFeatured === 1}
                        name="isFeatureRadio"
                      />
                    </span>
                    <span class="checkmark"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          {this.state.updateForApprove === true ? (
            <div class="row">
              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <label>Approve Product ?</label>
                  <div className="custom-radio-input">
                    <p className="container-sub mr-2">
                      No
                      <span>
                        <input
                          type="radio"
                          value="pending"
                          onChange={(event) =>
                            this.handleChange(event, "isApprove")
                          }
                          checked={this.state.isApprove === "pending"}
                          name="isApproveRadio"
                        />
                      </span>
                      <span class="checkmark"></span>
                    </p>

                    <p className="container-sub mr-2">
                      Yes
                      <span>
                        <input
                          type="radio"
                          value="approved"
                          onChange={(event) =>
                            this.handleChange(event, "isApprove")
                          }
                          checked={this.state.isApprove === "approved"}
                          name="isApproveRadio"
                        />
                      </span>
                      <span class="checkmark"></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="form-group">
                {!this.state.isAccessCategoryInCategories && (
                  <label>Do you want to set attributes for this product?</label>
                )}
                {!this.state.isAccessCategoryInCategories && (
                  <div className="custom-radio-input">
                    <p className="container-sub mr-2">
                      No
                      <span>
                        <input
                          type="radio"
                          value="0"
                          onChange={(event) =>
                            this.handleWithAttributeChange(
                              event,
                              "withAttribute"
                            )
                          }
                          checked={
                            this.state.isDobaProduct
                              ? this.state.variantCombination?.length === 1
                              : +this.state.withAttribute === 0
                          }
                          name="withAttributeRadio"
                          className={
                            (this.state
                              .isDisabledAttributesForWoocommerceShopify ||
                              this.state.isDobaProduct) &&
                            "disable-btn"
                          }
                          disabled={
                            this.state
                              .isDisabledAttributesForWoocommerceShopify ||
                            this.state.isDobaProduct
                          }
                        />
                      </span>
                      <span class="checkmark"></span>
                    </p>

                    <p className="container-sub mr-2">
                      Yes
                      <span>
                        <input
                          type="radio"
                          value="1"
                          onChange={(event) =>
                            this.handleWithAttributeChange(
                              event,
                              "withAttribute"
                            )
                          }
                          checked={
                            this.state.isDobaProduct
                              ? this.state.variantCombination?.length > 1
                              : +this.state.withAttribute === 1
                          }
                          name="withAttributeRadio"
                          className={
                            (this.state
                              .isDisabledAttributesForWoocommerceShopify ||
                              this.state.isDobaProduct) &&
                            "disable-btn"
                          }
                          disabled={
                            this.state
                              .isDisabledAttributesForWoocommerceShopify ||
                            this.state.isDobaProduct
                          }
                        />
                      </span>
                      <span class="checkmark"></span>
                    </p>
                  </div>
                )}
                {this.state.isDisabledAttributesForWoocommerceShopify ||
                this.state.isDobaProduct ? (
                  <span className="note-ecom">
                    <i className="fas fa-exclamation-circle"></i>
                    <strong>
                      Note<sup>*</sup>
                    </strong>
                    You cannot update attributes for Shopify/Woocommerce/Doba
                    products from Subsciety!
                  </span>
                ) : null}
                {!this.state.isAccessCategoryInCategories &&
                !this.state.isDisabledAttributesForWoocommerceShopify &&
                this.state.withAttribute ? (
                  <span className="note-ecom">
                    <i className="fas fa-exclamation-circle"></i>
                    <strong>
                      Note<sup>*</sup>
                    </strong>
                    If you will remove/change the product attribute, then you
                    need to create combination again and all previous data will
                    be remove.
                  </span>
                ) : null}
              </div>
            </div>
          </div>

          {(this.state.isDobaProduct
            ? this.state.variantCombination?.length > 1
            : this.state.withAttribute === 1) &&
            !this.state.isAccessCategoryInCategories && (
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>
                      Product Attributes
                      <sup>*</sup>
                    </label>
                    <Select
                      value={this.state.attributesDefaultSelectOptions}
                      isMulti
                      name="value"
                      options={this.state.attributesSelectOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        this.handleProductAttributeChange(e);
                      }}
                      isDisabled={
                        this.state.isDisabledAttributesForWoocommerceShopify ||
                        this.state.isDobaProduct
                      }
                    />
                  </div>
                  {this.state.attributesDefaultSelectOptions.length > 0 && (
                    <div className="form-group">
                      <button
                        onClick={this.getSelectedAttributeVariants}
                        className={`orange-outline-btn ${
                          (this.state
                            .isDisabledAttributesForWoocommerceShopify ||
                            this.state.isDobaProduct) &&
                          "disable-btn"
                        }`}
                        disabled={
                          this.state
                            .isDisabledAttributesForWoocommerceShopify ||
                          this.state.isDobaProduct
                        }
                      >
                        Get variants
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}

          {!this.state.isAccessCategoryInCategories &&
          !this.state.isDisabledAttributesForWoocommerceShopify &&
          this.state.variantCombination.length &&
          this.state.withAttribute ? (
            <span className="note-ecom note-ecom-margin">
              <i className="fas fa-exclamation-circle"></i>
              <strong>
                Note<sup>*</sup>
              </strong>
              You can create only one primary at a time.
            </span>
          ) : null}

          {(this.state.isDobaProduct
            ? this.state.variantCombination?.length > 1
            : this.state.selectedAttributeVariants?.length > 0) &&
            !this.state.isAccessCategoryInCategories &&
            this.state.selectedAttributeVariants.map((variant, index) => {
              return (
                <div key={index} className="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <div className="d-flex align-item-center justify-content-between">
                        <label>
                          {variant.attributeName
                            ? variant.attributeName.replace("_", " ")
                            : ""}
                          <sup>*</sup>
                        </label>
                      </div>
                      <Select
                        value={variant.attributeDefaultSelectedOptions}
                        isMulti
                        name="value"
                        options={variant.attributeSelectOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => {
                          this.handleVariantChange(e, index);
                        }}
                        isDisabled={
                          this.state
                            .isDisabledAttributesForWoocommerceShopify ||
                          this.state.isDobaProduct
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="add-product-button-align">
                      <button
                        className={`orange-outline-btn ${
                          (this.state
                            .isDisabledAttributesForWoocommerceShopify ||
                            this.state.isDobaProduct) &&
                          "disable-btn"
                        }`}
                        onClick={() =>
                          this.handleVariantChange(
                            variant.attributeSelectOptions,
                            index
                          )
                        }
                        disabled={
                          this.state
                            .isDisabledAttributesForWoocommerceShopify ||
                          this.state.isDobaProduct
                        }
                      >
                        Select all variants
                      </button>
                      <div className="switch-main">
                        <p>Make Primary</p>
                        <label class="switch">
                          <input
                            type="checkbox"
                            disabled={
                              index !== this.state.primaryIndex &&
                              this.state.isPrimary
                            }
                            onChange={(e) => this.makePrimary(e, index)}
                            checked={index === this.state.primaryIndex}
                          />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

          {!this.state.isDobaProduct &&
          this.state.primaryIndex == null &&
          !this.state.isAccessCategoryInCategories &&
          this.state.withAttribute &&
          this.state.selectedAttributeVariants?.length > 0 &&
          this.state.variantCombination ? (
            <div className="make-global-attribute">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-5">
                    <div className="make-global-sub">
                      <h5>GLOBAL IMAGE</h5>
                      <div className="product-global-img">
                        <img
                          src={
                            this.state.attributeGlobalImage
                              ? this.state.attributeGlobalImage
                              : ProfileImage
                          }
                          alt="product-img"
                        />
                        <div className="hover-global-show">
                          <input
                            type="image"
                            alt=""
                            className={`orange-outline-btn ${
                              this.state
                                .isDisabledAttributesForWoocommerceShopify &&
                              "disable-btn"
                            }`}
                            onClick={() => {
                              this.uploadGlobalImage.current.click();
                            }}
                            disabled={
                              this.state
                                .isDisabledAttributesForWoocommerceShopify
                            }
                          />
                          <svg
                            id="edit-image"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24.037"
                            height="24.015"
                            viewBox="0 0 24.037 24.015"
                          >
                            <path
                              id="Path_321"
                              data-name="Path 321"
                              d="M26.49,12.94a3.261,3.261,0,0,0-4.631,0L13.11,21.688a1.875,1.875,0,0,0-.257.429l-.858,4.288a.692.692,0,0,0,.257.772,1.2,1.2,0,0,0,.772.257l4.288-.858a.472.472,0,0,0,.429-.257l8.748-8.748a3.261,3.261,0,0,0,0-4.631ZM16.712,24.947l-2.745.515.515-2.745,6.947-6.947L23.659,18Zm8.577-8.577-.429.429-2.23-2.23.429-.429a1.577,1.577,0,1,1,2.23,2.23Z"
                              transform="translate(-3.418 -3.42)"
                              fill="#fff"
                            />
                            <path
                              id="Path_322"
                              data-name="Path 322"
                              d="M15.98,12.893a.849.849,0,1,0,1.2-1.2L15.466,9.976a.829.829,0,0,0-1.2,0L9.719,14.522,7.746,12.55a.829.829,0,0,0-1.2,0l-2.83,2.83V4.573a.81.81,0,0,1,.858-.858H20.011a.81.81,0,0,1,.858.858V8a.858.858,0,0,0,1.715,0V4.573A2.527,2.527,0,0,0,20.011,2H4.573A2.527,2.527,0,0,0,2,4.573V20.011a2.527,2.527,0,0,0,2.573,2.573H8.861a.858.858,0,0,0,0-1.715H4.573a.81.81,0,0,1-.858-.858v-2.23l3.431-3.431,1.973,1.973a.829.829,0,0,0,1.2,0l4.546-4.546Z"
                              transform="translate(-2 -2)"
                              fill="#fff"
                            />
                            <path
                              id="Path_323"
                              data-name="Path 323"
                              d="M8,8.573a2.573,2.573,0,1,0,5.146,0A2.573,2.573,0,1,0,8,8.573Zm3.431,0a.858.858,0,0,1-1.715,0,.858.858,0,1,1,1.715,0Z"
                              transform="translate(-2.854 -2.569)"
                              fill="#fff"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <p
                      style={{
                        color: "#ff0000",
                        fontSize: "14px",
                        marginTop: "15px",
                      }}
                    >
                      {this.state.errorData.attributeFileError}
                    </p>
                  </div>

                  <div className="col-md-7">
                    <div className="make-global-sub border-0">
                      <h5>GLOBAL PRICE</h5>
                      <div className="form-group m-0">
                        <input
                          type="number"
                          onChange={(e) => {
                            this.uploadGlobalAttributes(e, "globalPrice");
                          }}
                          value={this.state.attributeGlobalPrice}
                          className="form-control"
                          min="1"
                          disabled={
                            this.state.isDisabledAttributesForWoocommerceShopify
                          }
                        />
                      </div>
                      <button
                        className="orange-btn"
                        onClick={() =>
                          this.applyGlobalAttribute(
                            this.state.attributeGlobalPrice
                          )
                        }
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {!this.state.isAccessCategoryInCategories &&
            this.state.primaryIndex != null &&
            this.state.variantCombination &&
            this.state.selectedAttributeVariants[
              this.state.primaryIndex
            ]?.attributeDefaultSelectedOptions.map((item, index) => {
              return (
                <div className="make-global-attribute">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="make-global-sub">
                          <h5>{item.label}</h5>
                          <h5>COMMON IMAGE </h5>
                          <div className="product-global-img">
                            {this.state.specificAttributeGlobalImage.map(
                              (items, index) => {
                                if (Object.keys(items)[0] === item.label) {
                                  return (
                                    <img
                                      key={index}
                                      src={
                                        Object.values(items)[0]
                                          ? Object.values(items)[0]
                                          : ProfileImage
                                      }
                                      alt="product-img"
                                    />
                                  );
                                }
                                return null;
                              }
                            )}

                            <div className="hover-global-show">
                              <input
                                type="image"
                                alt=""
                                className={`orange-outline-btn ${
                                  this.state
                                    .isDisabledAttributesForWoocommerceShopify &&
                                  "disable-btn"
                                }`}
                                onClick={() => {
                                  this.uploadGlobalImage.current.click();
                                  this.setState({
                                    attibuteGlobalValue: item.label,
                                    attibuteGlobalIndex: index,
                                  });
                                }}
                                disabled={
                                  this.state
                                    .isDisabledAttributesForWoocommerceShopify
                                }
                              />
                              <svg
                                id="edit-image"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24.037"
                                height="24.015"
                                viewBox="0 0 24.037 24.015"
                              >
                                <path
                                  id="Path_321"
                                  data-name="Path 321"
                                  d="M26.49,12.94a3.261,3.261,0,0,0-4.631,0L13.11,21.688a1.875,1.875,0,0,0-.257.429l-.858,4.288a.692.692,0,0,0,.257.772,1.2,1.2,0,0,0,.772.257l4.288-.858a.472.472,0,0,0,.429-.257l8.748-8.748a3.261,3.261,0,0,0,0-4.631ZM16.712,24.947l-2.745.515.515-2.745,6.947-6.947L23.659,18Zm8.577-8.577-.429.429-2.23-2.23.429-.429a1.577,1.577,0,1,1,2.23,2.23Z"
                                  transform="translate(-3.418 -3.42)"
                                  fill="#fff"
                                />
                                <path
                                  id="Path_322"
                                  data-name="Path 322"
                                  d="M15.98,12.893a.849.849,0,1,0,1.2-1.2L15.466,9.976a.829.829,0,0,0-1.2,0L9.719,14.522,7.746,12.55a.829.829,0,0,0-1.2,0l-2.83,2.83V4.573a.81.81,0,0,1,.858-.858H20.011a.81.81,0,0,1,.858.858V8a.858.858,0,0,0,1.715,0V4.573A2.527,2.527,0,0,0,20.011,2H4.573A2.527,2.527,0,0,0,2,4.573V20.011a2.527,2.527,0,0,0,2.573,2.573H8.861a.858.858,0,0,0,0-1.715H4.573a.81.81,0,0,1-.858-.858v-2.23l3.431-3.431,1.973,1.973a.829.829,0,0,0,1.2,0l4.546-4.546Z"
                                  transform="translate(-2 -2)"
                                  fill="#fff"
                                />
                                <path
                                  id="Path_323"
                                  data-name="Path 323"
                                  d="M8,8.573a2.573,2.573,0,1,0,5.146,0A2.573,2.573,0,1,0,8,8.573Zm3.431,0a.858.858,0,0,1-1.715,0,.858.858,0,1,1,1.715,0Z"
                                  transform="translate(-2.854 -2.569)"
                                  fill="#fff"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-7">
                        <div className="make-global-sub border-0">
                          <h5>COMMON PRICE</h5>
                          <div className="form-group m-0">
                            <input
                              type="number"
                              onChange={(e) => {
                                this.uploadGlobalAttributes(
                                  e,
                                  "globalPrice",
                                  item.label,
                                  index
                                );
                              }}
                              className="form-control"
                              min="1"
                              disabled={
                                this.state
                                  .isDisabledAttributesForWoocommerceShopify
                              }
                            />
                          </div>
                          <button
                            className="orange-btn"
                            onClick={() =>
                              this.applyGlobalAttribute(
                                this.state.specificAttributeGlobalPrice,
                                item.label,
                                index
                              )
                            }
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

          {(this.state.isDobaProduct
            ? this.state.variantCombination?.length > 1
            : this.state.selectedAttributeVariants?.length > 0) &&
            !this.state.isAccessCategoryInCategories && (
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <button
                      onClick={this.createVariantCombination}
                      className={`orange-outline-btn ${
                        (this.state.isDisabledAttributesForWoocommerceShopify ||
                          this.state.isDobaProduct) &&
                        "disable-btn"
                      }`}
                      disabled={
                        this.state.isDisabledAttributesForWoocommerceShopify ||
                        this.state.isDobaProduct
                      }
                    >
                      Create variant combination
                    </button>
                    <br />
                    <span className="form-field-error">
                      {this.state.errorData?.variantCombination &&
                        this.state.errorData.variantCombination[0]}
                    </span>
                    <span className="form-field-error">
                      {this.state.errorData?.getVarientCombination &&
                        this.state.errorData.getVarientCombination[0]}
                    </span>
                  </div>
                </div>
              </div>
            )}
          {this.state.primaryIndex !== null && (
            <p
              style={{ color: "#ff0000", fontSize: "14px", marginTop: "15px" }}
            >
              {this.state.errorData.attributeFileError}
            </p>
          )}
          <p style={{ color: "#ff0000", fontSize: "14px", marginTop: "0px" }}>
            {this.state.errorData.specificAttributeGlobalImageError}
          </p>
          {!this.state.isAccessCategoryInCategories &&
          this.state.withAttribute &&
          this.state.selectedAttributeVariants?.length > 0 &&
          this.state.variantCombination?.length > 0 ? (
            <div className="table-responsive add-product-table">
              <table className="table">
                <thead>
                  <tr>
                    <th>IMAGE</th>
                    <th>VARIANT</th>
                    <th>PRICE</th>
                    {this.state.is_sale === 1 ? <th>SALE PRICE</th> : null}
                    <th>ACTION</th>
                  </tr>
                </thead>

                {this.state.variantCombination &&
                  this.state.variantCombination.map((combination, index) => {
                    return (
                      <tbody key={index}>
                        <tr>
                          <td>
                            <div className="product-global-img global-img-sm">
                              <img
                                src={
                                  this.state.attributeImage[index]
                                    ? this.state.attributeImage[index]
                                    : ProfileImage
                                }
                                alt="product-img"
                              />
                              <div className="hover-global-show">
                                <input
                                  type="image"
                                  alt=""
                                  className={`orange-outline-btn ${
                                    this.state
                                      .isDisabledAttributesForWoocommerceShopify &&
                                    "disable-btn"
                                  }`}
                                  onClick={() => {
                                    this.fileUploadAttribute.current.click();
                                    this.setState({
                                      uploadAttributeIndex: index,
                                    });
                                  }}
                                  disabled={
                                    this.state
                                      .isDisabledAttributesForWoocommerceShopify
                                  }
                                />
                                <svg
                                  id="edit-image"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24.037"
                                  height="24.015"
                                  viewBox="0 0 24.037 24.015"
                                >
                                  <path
                                    id="Path_321"
                                    data-name="Path 321"
                                    d="M26.49,12.94a3.261,3.261,0,0,0-4.631,0L13.11,21.688a1.875,1.875,0,0,0-.257.429l-.858,4.288a.692.692,0,0,0,.257.772,1.2,1.2,0,0,0,.772.257l4.288-.858a.472.472,0,0,0,.429-.257l8.748-8.748a3.261,3.261,0,0,0,0-4.631ZM16.712,24.947l-2.745.515.515-2.745,6.947-6.947L23.659,18Zm8.577-8.577-.429.429-2.23-2.23.429-.429a1.577,1.577,0,1,1,2.23,2.23Z"
                                    transform="translate(-3.418 -3.42)"
                                    fill="#fff"
                                  />
                                  <path
                                    id="Path_322"
                                    data-name="Path 322"
                                    d="M15.98,12.893a.849.849,0,1,0,1.2-1.2L15.466,9.976a.829.829,0,0,0-1.2,0L9.719,14.522,7.746,12.55a.829.829,0,0,0-1.2,0l-2.83,2.83V4.573a.81.81,0,0,1,.858-.858H20.011a.81.81,0,0,1,.858.858V8a.858.858,0,0,0,1.715,0V4.573A2.527,2.527,0,0,0,20.011,2H4.573A2.527,2.527,0,0,0,2,4.573V20.011a2.527,2.527,0,0,0,2.573,2.573H8.861a.858.858,0,0,0,0-1.715H4.573a.81.81,0,0,1-.858-.858v-2.23l3.431-3.431,1.973,1.973a.829.829,0,0,0,1.2,0l4.546-4.546Z"
                                    transform="translate(-2 -2)"
                                    fill="#fff"
                                  />
                                  <path
                                    id="Path_323"
                                    data-name="Path 323"
                                    d="M8,8.573a2.573,2.573,0,1,0,5.146,0A2.573,2.573,0,1,0,8,8.573Zm3.431,0a.858.858,0,0,1-1.715,0,.858.858,0,1,1,1.715,0Z"
                                    transform="translate(-2.854 -2.569)"
                                    fill="#fff"
                                  />
                                </svg>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="variant-label">
                              <p> {Object.values(combination[0]).join(", ")}</p>
                            </div>
                          </td>
                          <td>
                            <div className="form-group m-0">
                              <input
                                type="number"
                                onChange={(e) => {
                                  if (
                                    /^\d*\.?\d+$/.test(e.target.value) ||
                                    e.target.value === ""
                                  ) {
                                    this.handleVariantCombinationPriceChange(
                                      e,
                                      index
                                    );
                                  }
                                }}
                                value={
                                  combination[1] ? combination[1].price : ""
                                }
                                className="form-control"
                                min="1"
                                disabled={
                                  this.state
                                    .isDisabledAttributesForWoocommerceShopify
                                }
                              />
                            </div>
                          </td>
                          {this.state.is_sale === 1 ? (
                            <td>
                              <div className="variant-label">
                                {this.state.is_sale === 1 &&
                                  combination[1] &&
                                  combination[1].price && (
                                    <div>
                                      {this.state.price_type === 2
                                        ? ` ${parseFloat(
                                            (
                                              combination[1].price *
                                              (1 - this.state.sale_price / 100)
                                            ).toFixed(2)
                                          )}`
                                        : combination[1].price -
                                          this.state.sale_price}
                                    </div>
                                  )}
                              </div>
                            </td>
                          ) : null}

                          <td>
                            <button
                              className={`orange-outline-btn ${
                                this.state
                                  .isDisabledAttributesForWoocommerceShopify &&
                                "disable-btn"
                              }`}
                              onClick={() =>
                                this.onRemoveSpecificCombination(index)
                              }
                              disabled={
                                this.state
                                  .isDisabledAttributesForWoocommerceShopify
                              }
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
              </table>
            </div>
          ) : null}

          {/* multiple image upload */}
          <h5>Public Facing Marketing Content</h5>
          <div className="border-show-seprate p-3">
            <div class="row">
              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <label>Gallery Images</label>
                  <div className="App">
                    <ImageUploading
                      multiple
                      value={this.state.galleryImages}
                      onChange={this.handleGalleryImages}
                      maxNumber={10}
                      acceptType={["jpg", "jpeg"]}
                      dataURLKey="data_url"
                    >
                      {({
                        galleryImages,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        <div className="upload__image-wrapper">
                          <div className="text-left mb-4">
                            <button
                              style={isDragging ? { color: "red" } : null}
                              className={`orange-outline-btn ${
                                this.state
                                  .isDisabledAttributesForWoocommerceShopify &&
                                "disable-btn"
                              }`}
                              disabled={
                                this.state
                                  .isDisabledAttributesForWoocommerceShopify
                              }
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              Click or Drop here
                            </button>
                            &nbsp;
                            <button
                              onClick={onImageRemoveAll}
                              className={`orange-outline-btn ${
                                this.state
                                  .isDisabledAttributesForWoocommerceShopify &&
                                "disable-btn"
                              }`}
                              disabled={
                                this.state
                                  .isDisabledAttributesForWoocommerceShopify
                              }
                            >
                              Remove all images
                            </button>
                          </div>
                          {this.state.galleryImages.map((image, index) => (
                            <div key={index} className="image-item">
                              <img src={image.data_url} alt="" width="100" />
                              <div className="image-item__btn-wrapper">
                                <button
                                  onClick={() => onImageUpdate(index)}
                                  className={`orange-outline-btn mb-2 ${
                                    this.state
                                      .isDisabledAttributesForWoocommerceShopify &&
                                    "disable-btn"
                                  }`}
                                  disabled={
                                    this.state
                                      .isDisabledAttributesForWoocommerceShopify
                                  }
                                >
                                  Update
                                </button>
                                <button
                                  onClick={() => onImageRemove(index)}
                                  className={`orange-outline-btn ${
                                    this.state
                                      .isDisabledAttributesForWoocommerceShopify &&
                                    "disable-btn"
                                  }`}
                                  disabled={
                                    this.state
                                      .isDisabledAttributesForWoocommerceShopify
                                  }
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                </div>
              </div>
            </div>

            {/* multiple video upload */}
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <label>Gallery Videos</label>
                  <div className="App">
                    <div className="upload__image-wrapper">
                      <div className="text-left mb-4">
                        <button
                          className={`orange-outline-btn mb-2 ${
                            this.state
                              .isDisabledAttributesForWoocommerceShopify &&
                            "disable-btn"
                          }`}
                          disabled={
                            this.state.isDisabledAttributesForWoocommerceShopify
                          }
                          onClick={(e) =>
                            this.triggerFileUploadClick(e, "videos")
                          }
                        >
                          Click here to upload
                        </button>
                        &nbsp;
                        <button
                          className={`orange-outline-btn ${
                            this.state
                              .isDisabledAttributesForWoocommerceShopify &&
                            "disable-btn"
                          }`}
                          disabled={
                            this.state.isDisabledAttributesForWoocommerceShopify
                          }
                          onClick={(e) =>
                            this.deleteAllGalleryFiles(e, "videos")
                          }
                        >
                          Remove all videos
                        </button>
                      </div>
                    </div>
                    {this.state.galleryVideos &&
                    this.state.galleryVideos.length > 0 ? (
                      <>
                        {this.state.galleryVideos.map((item, index) => (
                          <div key={index} className="image-item">
                            {this.state.showVideos ? (
                              <video width="200" height="150" controls>
                                <source src={item} type="video/mp4" />
                                <source src={item} type="video/webm" />
                                <source src={item} type="video/ogg" />
                                Your browser does not support the video tag.
                              </video>
                            ) : null}
                            <div className="image-item__btn-wrapper">
                              <button
                                className="orange-outline-btn"
                                onClick={(e) =>
                                  this.deleteSelectedGalleryFile(
                                    e,
                                    "videos",
                                    item,
                                    index
                                  )
                                }
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : null}
                    {this.state.videos && this.state.videos.length > 0 ? (
                      <>
                        {this.state.videos.map((item, index) => (
                          <div key={index} className="image-item">
                            {this.state.showVideos ? (
                              <video width="200" height="150" controls>
                                <source src={item.stream} type={item.type} />
                                Your browser does not support the video tag.
                              </video>
                            ) : null}
                            <div className="image-item__btn-wrapper">
                              {item.status || ""}
                              {!item.status ? (
                                <button
                                  className="orange-outline-btn"
                                  onClick={(e) =>
                                    this.deleteSelectedFile(e, "videos", index)
                                  }
                                >
                                  Remove
                                </button>
                              ) : null}
                              {item.uploadPercentage &&
                              item.uploadPercentage < 100 ? (
                                <progress
                                  id="file"
                                  value={item.uploadPercentage}
                                  max="100"
                                >
                                  {" "}
                                  {item.uploadPercentage}%{" "}
                                </progress>
                              ) : null}
                            </div>
                          </div>
                        ))}
                      </>
                    ) : null}
                    {this.props.errorVideos &&
                    this.props.errorVideos.length > 0 ? (
                      <>
                        {this.props.errorVideos.map((item, index) => (
                          <p className="files-error" key={index}>
                            {item}
                          </p>
                        ))}
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="form-group">
                <label>Activate Offering ?</label>
                <div className="custom-radio-input">
                  <p className="container-sub mr-2">
                    Inactive
                    <span>
                      <input
                        type="radio"
                        value="2"
                        onChange={(event) =>
                          this.confirmProductInactiveAlert(event, "isActive")
                        }
                        checked={+this.state.isActive === 2}
                        name="isActiveRadio"
                      />
                    </span>
                    <span class="checkmark"></span>
                  </p>

                  <p className="container-sub mr-2">
                    Active
                    <span>
                      <input
                        type="radio"
                        value="1"
                        onChange={(event) =>
                          this.handleChange(event, "isActive")
                        }
                        checked={+this.state.isActive === 1}
                        name="isActiveRadio"
                      />
                    </span>
                    <span class="checkmark"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="form-btn text-center mt-3">
                <button
                  type="button"
                  onClick={this.handleSubmit}
                  class="orange-outline-btn mr-2"
                >
                  submit
                  {(() => {
                    if (this.state?.showLoader) {
                      return (
                        <span class="spinner-border spinner-border-sm ml-1"></span>
                      );
                    }
                  })()}
                </button>
                {this.state.updateForApprove === true && (
                  <button
                    type="button"
                    onClick={() => this.handleAlertForDenyApproval(this.slug)}
                    class="orange-outline-btn"
                  >
                    deny
                  </button>
                )}
                <div>
                  <p style={{ color: "red" }}>
                    {this.state.bottomErrorMessage}
                  </p>
                  <p
                    style={{
                      color: "#ff0000",
                      fontSize: "14px",
                      marginTop: "10px",
                    }}
                  >
                    {this.state.errorData.largeFileError}
                  </p>
                </div>
                <AlertModal
                  data={this.state.alertModalData}
                  closeAlertModal={this.closeAlertModal}
                  handleAlertResponse={(type, id, data) =>
                    this.handleAlertResponse(type, id, data)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    videos: state.fileUploadReducer.videos,
    errorVideos: state.fileUploadReducer.errorVideos,
    acsCatVideos: state.fileUploadReducer.acsCatVideos,
    errorAcsCatVideos: state.fileUploadReducer.errorAcsCatVideos,
    acsCatAudios: state.fileUploadReducer.acsCatAudios,
    errorAcsCatAudios: state.fileUploadReducer.errorAcsCatAudios,
    acsCatImages: state.fileUploadReducer.acsCatImages,
    errorAcsCatImages: state.fileUploadReducer.errorAcsCatImages,
    acsCatDocuments: state.fileUploadReducer.acsCatDocuments,
    errorAcsCatDocuments: state.fileUploadReducer.errorAcsCatDocuments,
    activeSubscriptionMemberList:
      state.productReducer.activeSubscriptionMemberList,
    meetingList: state.meetingReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getVendorProductDetail,
      updateVendorProductDetail,
      updateProductFiles,
      removeProductFiles,
      addFiles,
      updateAttibute,
      uploadAttibute,
      addFilesError,
      addMoreFiles,
      uploadFiles,
      getProductListCountForApproval,
      getAttributeList,
      getActiveSubscriptionCustomerListByProudctId,
      sendInvitationRequest,
      getMeetingsByProductIdAndSellerId,
      hierarchyList,
      getChildCategories,
      denyProductApproval,
      getGroupAndOneOnOneMeetingDetails,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigate(Vendor_Product_Edit));
